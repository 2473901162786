import React, { ReactNode } from "react";
import { createStyles } from "src/styles";

const styles = createStyles(({ spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    flexGrow: 1,
    display: "flex",
  },
  nav: {
    display: ["none", "none", "block"],
  },
  content: {
    flexGrow: 1,
    height: `calc(100vh - ${spacing[8]}px)`, // full height minus header height
    overflow: "auto",
  },
  contentWrapper: {
    minHeight: "100%",
    alignSelf: "flex-end",
    display: "flex",
    flexDirection: "column",
  },
}));

const DashboardShell = ({
  header,
  sidebar,
  footer,
  children,
  onClick,
}: {
  header: ReactNode;
  sidebar?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  onClick?: () => void;
}) => {
  return (
    <div className="bg-white" onClick={onClick} css={styles.container}>
      {header}
      <div css={styles.main}>
        {sidebar && <div css={styles.nav}>{sidebar}</div>}
        <div css={styles.content}>
          <div css={styles.contentWrapper}>
            {children}
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DashboardShell);
