import { NavItems } from "src/apps/BrokerDashboard/components/NavItems";
import { SideBar } from "src/components";

const BrokerSidebar = () => {
  return (
    <SideBar divider>
      <NavItems />
    </SideBar>
  );
};

export default BrokerSidebar;
