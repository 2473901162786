import _ from "lodash";
import { ReactElement, useMemo } from "react";
import fraction, { GroupingLtvOverTimeData, GroupingPropertyValueOverTimeData } from "src/api/fraction";
import usePortfolioDashStore from "src/apps/PortfolioDashboard/store";
import { useTabs } from "src/hooks";
import { useQuery } from "src/lib";

import { formatters, parsers } from "@fraction/shared";

export const usePortfolioTypes = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const {
    productTypeFilter,
    termLengthFilter,
    portfolioFilters,
    startDateFilter,
    endDateFilter,
    countryFilter,
    loanStatusFilter,
    endLoanActivityDateFilter,
    startLoanActivityDateFilter,
  } = usePortfolioDashStore();

  return useQuery({
    queryKey: [
      "portfolioTypes",
      portfolioFilters,
      ...(startDateFilter && endDateFilter ? [startDateFilter, endDateFilter] : []),
      countryFilter,
      productTypeFilter,
      termLengthFilter,
      loanStatusFilter,
      endLoanActivityDateFilter,
      startLoanActivityDateFilter,
    ],
    enabled,
    queryFn: async () =>
      fraction.getPortfolioTypes({
        status: loanStatusFilter,
        country: countryFilter,
        portfolios: portfolioFilters?.length === 0 ? undefined : portfolioFilters,
        productType: productTypeFilter,
        termLength: termLengthFilter,
        startDate: startDateFilter,
        endDate: endDateFilter,
        endLoanActivityDate: endLoanActivityDateFilter,
        startLoanActivityDate: startLoanActivityDateFilter,
      }),
  });
};

export const usePortfolioOverview = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.portfolioOverview ? parsers.portfolio.overview.parse(data?.portfolioOverview) : undefined,
  };
};

export const usePayoutsOverTimeData = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.payouts,
  };
};

export const useLoanToValueData = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.loanToValue,
  };
};

export const useLoanFundingsData = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.fundings,
  };
};

export const useLoanToValueOverTimeData = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.loanToValueOverTime,
  };
};

export const usePropertyValueOverTimeData = (
  { enabled = true }: { enabled?: boolean } = { enabled: true }
) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.propertyValueOverTime,
  };
};

export const useApplicantAges = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: {
      ...data?.applicantAges,
      data: data?.applicantAges?.data?.filter((item) => item.count > 0) || [],
    },
  };
};

export const useWeightedAverageCoupon = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.weightedAverageCoupon,
  };
};

export const useWeightedAverageMaturity = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.weightedAverageMaturity,
  };
};

export const useProjectedMaturities = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.projectedMaturities,
  };
};

export const useTdsAndGds = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.tdsAndGds,
  };
};

export const useApplicantCreditScores = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.creditScores,
  };
};

export const usePortfolioSize = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  const formattedData = useMemo(() => {
    if (!data?.portfolioSize) {
      return [];
    }
    const total = _.keyBy(data?.portfolioSize?.total, "quarter");
    const fundings = _.keyBy(data?.portfolioSize?.fundings, "quarter");
    const payoffs = _.keyBy(data?.portfolioSize?.payoffs, "quarter");

    return Object.keys(total).map((quarter) => {
      return {
        quarter,
        totalCount: total[quarter]?.count || 0,
        totalSum: total[quarter]?.sum || 0,
        fundingsCount: fundings[quarter]?.count || 0,
        fundingsSum: fundings[quarter]?.sum || 0,
        payoffsCount: payoffs[quarter]?.count || 0,
        payoffsSum: payoffs[quarter]?.sum || 0,
      };
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading]);

  return {
    ...rest,
    isLoading,
    data: formattedData,
  };
};

export const useLoanSizes = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.loanSizes,
  };
};

export const useUseOfFunds = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.useOfFunds,
  };
};

export const useBrokerVsDirect = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.brokerVsDirect,
  };
};

export const useCollateralGroupings = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.collateralGroupings,
  };
};

export const usePayoutGroupings = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  return {
    ...rest,
    isLoading,
    data: data?.payoutGroupings,
  };
};

export const useIncomeSources = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  const mapped = useMemo(() => {
    return _.reverse(
      _.sortBy(
        data?.incomeSources?.map((item) => ({
          id: formatters.enums.INCOME_TYPE_MAP[item.type],
          value: item.count,
          percentage: formatters.number.getPercentageFromDecimal(item.percentage),
          label: (
            <>
              <p>
                <b>{item.count}</b> instances of income from:{" "}
                <b>{formatters.enums.INCOME_TYPE_MAP[item.type]?.toLowerCase()}</b>
              </p>
              <p>
                <b>{formatters.number.getPercentageFromDecimal(item.percentage)}</b> of portfolio
              </p>
            </>
          ),
        })),
        "value"
      )
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading]);

  return {
    ...rest,
    isLoading,
    data: mapped,
  };
};

export const usePropertyTypes = ({ enabled = true }: { enabled?: boolean } = { enabled: true }) => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });
  const modifiedData = useMemo(
    () =>
      data?.propertyTypes?.map((propertyType) => {
        return {
          ...propertyType,
          type: formatters.enums.FULL_PROPERTY_TYPE_MAP[propertyType.type],
        };
      }),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [isLoading]
  ) as (Omit<Record<string, number>, "type"> & { type: string })[] | undefined;

  return {
    ...rest,
    isLoading,
    data: modifiedData,
  };
};

export interface LabeledPropertyMsa {
  id: string;
  value: number;
  percent: string;
  administrativeArea: string;
  label: ReactElement;
}

export const usePropertyMSAs = (
  { enabled = true }: { enabled?: boolean } = { enabled: true }
): { data?: LabeledPropertyMsa[]; isLoading: boolean } => {
  const { data, isLoading, ...rest } = usePortfolioTypes({ enabled });

  const mapped = useMemo(() => {
    return data?.propertyMSAs?.map((propertyMSA) => ({
      id: propertyMSA.msa,
      value: propertyMSA.count,
      percent: formatters.number.getPercentageFromDecimal(propertyMSA.percentage),
      administrativeArea: propertyMSA.administrativeArea,
      label: (
        <>
          <p>
            <b>{propertyMSA.count}</b> properties in <b>{propertyMSA.msa}</b>
          </p>
          <p>
            <b>{formatters.number.getPercentageFromDecimal(propertyMSA.percentage)}</b> of portfolio
          </p>
        </>
      ),
    }));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading]);

  return {
    ...rest,
    isLoading,
    data: mapped,
  };
};

export const useGroupingTabs = () =>
  useTabs({
    options: [
      { value: "administrativeArea", label: "Province/State" },
      { value: "fsa", label: "Top 10 FSAs" },
      { value: "creditScore", label: "Credit scores" },
      { value: "yearCohort", label: "Vintage" },
      { value: "ungrouped", label: "Ungrouped" },
    ],
    defaultValue: "administrativeArea",
  });

const START_DATE = new Date("2022-06");

export const useMemoizedGroupingOverTime = ({
  data,
  activeTab,
  mapping,
  isLoading,
}: {
  data?: GroupingLtvOverTimeData | GroupingPropertyValueOverTimeData;
  isLoading?: boolean;
  activeTab: GroupingOverTimeTabs;
  mapping: (activeTab: GroupingOverTimeTabs) => ([id, adminAreaData]: [
    string,
    (parsers.portfolio.LoanToValueOverTimeData | parsers.portfolio.PropertyValueOverTimeData)[]
  ]) => {
    id: string;
    data: {
      x: Date;
      y: number | undefined | null;
      count: number;
      activeTab: GroupingOverTimeTabs;
    }[];
  };
}) =>
  useMemo(() => {
    // filter out anything from before the start date
    // @ts-ignore
    const dataAfterStartDate = data?.[activeTab]?.filter(
      (item: parsers.portfolio.LoanToValueOverTimeData | parsers.portfolio.PropertyValueOverTimeData) =>
        new Date(item.date) > START_DATE
    );

    const groupedByDate = _.groupBy(dataAfterStartDate, "date");
    const dateGroups = Object.values(groupedByDate);
    const dataWithAllData = dateGroups.flat();
    const groupedByGroup = _.groupBy(dataWithAllData, "group");

    const entries = Object.entries(groupedByGroup);
    if (!["administrativeArea", "fsa"].includes(activeTab)) {
      return entries.map(mapping(activeTab));
    }

    const adminAreas = _.uniq(
      dataAfterStartDate?.map((item: { administrativeAreas?: string[] }) => item.administrativeAreas).flat()
    );

    const sortedByCount = _.reverse(
      _.sortBy(entries, ([, group]) => Math.max(...group.map((item) => item.count)))
    );

    // return only the top 10/provinces.length for each province
    const top10 = adminAreas
      .map((administrativeArea) =>
        sortedByCount
          .filter(([, group]) =>
            group.some((item: any) => item.administrativeAreas.includes(administrativeArea))
          )
          .slice(0, Math.floor(10 / adminAreas.length))
      )
      .flat();

    return top10.map(mapping(activeTab));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, activeTab, mapping]);

export const groupingOverTimeTabNameMap = {
  creditScore: "Score",
  administrativeArea: "Province",
  fsa: "FSA",
  yearCohort: "Vintage",
  ungrouped: "Group",
} as const;

export type GroupingOverTimeTabs = keyof typeof groupingOverTimeTabNameMap;
