import { differenceInDays, endOfMonth, format, formatDistanceToNow } from "date-fns";
import { AlertTriangle, CalendarIcon, Clock, CoinsIcon, LandmarkIcon } from "lucide-react";
import { ReactNode, memo, useMemo } from "react";
import { OldModalViews } from "src/apps/PostFundedDashboard/components/ModalViews";
import { PaymentModals } from "src/apps/PostFundedDashboard/components/PaymentModals";
import { DashboardPage, Skeleton, Text, Tooltip } from "src/components";
import { MaskedBankAccount } from "src/components/MaskedBankAccount";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { Button } from "src/components/ui/button";
import { useToggle } from "src/hooks";
import { Toggle } from "src/hooks/useToggle";
import { AutoDeposit, TooltipSolid } from "src/icons";
import moneyJar from "src/images/money-jar-flatline.png";
import receiveFunds from "src/images/receive-funds.png";
import { Logger } from "src/log";
import { createStyles } from "src/styles";

import { calculators, enums, formatters, notUndefinedOrNull, parsers, selectors } from "@fraction/shared";
import { cn } from "src/utilities/shadcnUtils";

import { SectionHeader } from "src/components/SectionHeader";
import TransactionHistory, { TransactionStatus, TransactionType } from "../../components/TransactionHistory";
import { useLoanQuery, usePaymentSubscriptionQuery, useTransactionsQuery } from "../../queries";

const LOGGER = new Logger("PostFundedDashboard.pages.PaymentsAndDraws");

const styles = createStyles(({ borderRadius, theme, colors }) => ({
  toolTipIcon: {
    color: theme.icon.neutral.base,
    width: 16,
    height: 16,
  },
  tooltipText: {
    color: colors.grey200,
    whiteSpace: "normal",
    minWidth: 500,
    width: 500,
  },
}));

const CardHeading = ({
  heading,
  tooltipText,
  className,
  isLoading,
}: {
  heading: string | ReactNode;
  tooltipText: string;
  className?: string;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return <Skeleton className={cn("mb-3", className)} height={24} width={100} />;
  }
  return (
    <div className={cn("flex", className)}>
      <h4 className="text-gray-600 mb-4 text-nowrap">{heading}</h4>

      <div className="ml-2">
        <Tooltip
          text={
            <Text size="xs" style={styles.tooltipText}>
              {tooltipText}
            </Text>
          }
        >
          <TooltipSolid css={styles.toolTipIcon} />
        </Tooltip>
      </div>
    </div>
  );
};

const AmountDisplay = ({
  amountCents,
  isLoading,
  className,
}: { amountCents?: number; isLoading?: boolean; className?: string }) => {
  // take an integer and return an array of two strings in the format of [$dollars, cents] e.g 8251523 becomes [$82515, 23]
  const formattedDollarsAndCents =
    (amountCents !== undefined && formatters.number.formatDollarsAndCentsSeparated(amountCents)) || [];

  if (isLoading) {
    return <Skeleton className={className} height={36} width={120} />;
  }

  return (
    <p className={cn("font-serif-deck text-[2.5rem] m-0 leading-10 font-black", className)}>
      {formattedDollarsAndCents[0] ?? "N/A"}
      <span className="text-2xl">.{formattedDollarsAndCents[1] ?? "N/A"}</span>
    </p>
  );
};

const PaymentCard = ({
  heading,
  tooltipText,
  amountCents,
  isLoading,
  buttonText,
  button,
  onClick,
  className,
  children,
  imgSrc,
  imgClassName,
}: {
  heading: string | ReactNode;
  tooltipText: string;
  amountCents?: number;
  isLoading?: boolean;
  buttonText?: string | ReactNode;
  button?: ReactNode;
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
  imgSrc?: any;
  imgClassName?: string;
}) => {
  return (
    <div className={cn("w-full flex bg-green-50 rounded-lg p-12 pr-0 items-center gap-8 flex-1", className)}>
      <div className="flex flex-col justify-center gap-4">
        <div>
          <CardHeading isLoading={isLoading} heading={heading} tooltipText={tooltipText} />
          <AmountDisplay amountCents={amountCents} isLoading={isLoading} />
        </div>
        {buttonText ? (
          <Button variant="green" onClick={onClick} disabled={isLoading}>
            {buttonText}
          </Button>
        ) : (
          <div />
        )}
        {button}
        {children}
      </div>
      {imgSrc ? (
        <img
          className={cn(
            "overflow-x-hidden overflow-y-hidden object-left hidden sm:block object-cover",
            imgClassName
          )}
          alt="Money"
          src={imgSrc}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

const PaymentsAndDraws = () => {
  const { data: loan, isLoading } = useLoanQuery();
  const transactionsQuery = useTransactionsQuery(loan?.id || "");
  const prepaymentToggle = useToggle();
  const loanDrawToggle = useToggle();
  const recurringPaymentToggle = useToggle();
  const bankAccountToggle = useToggle();
  const paymentSubscriptionCreateToggle = useToggle();

  useDocumentTitle("Loan overview | Fraction");

  const availableToDraw = useMemo(() => {
    if (notUndefinedOrNull(loan)) {
      try {
        const parsedLoan = parsers.loan.parseAvailableToDrawLoan(loan);
        return calculators.loan.getAvailableDrawAmount(parsedLoan);
      } catch (err) {
        LOGGER.exception(err, `Error calculating amount available to draw for loan ${loan.id}`);
      }
    }
  }, [loan]);

  const canMakePayments = selectors.loan.canMakePayments(loan);
  const canMakeDraws = selectors.loan.canMakeDraws(loan);
  const canMakeAutomatedPayments = selectors.loan.canMakeAutomatedPayments(loan);
  const canMakeAutomatedDraws = selectors.loan.canMakeAutomatedDraws(loan);

  return (
    <DashboardPage
      className="gap-2 gap-y-4"
      heading="Overview"
      subHeading="View your loan details and access transaction records."
    >
      {canMakeAutomatedPayments ? (
        <PaymentModals
          bankAccountToggle={bankAccountToggle}
          recurringPaymentToggle={recurringPaymentToggle}
          loanDrawToggle={loanDrawToggle}
          prepaymentToggle={prepaymentToggle}
          paymentSubscriptionCreateToggle={paymentSubscriptionCreateToggle}
        />
      ) : (
        <OldModalViews loanDrawToggle={loanDrawToggle} prepaymentToggle={prepaymentToggle} />
      )}
      {loan?.overdueBalance ? (
        <div className="text-sm text-red-600 bg-red-50 border px-4 py-3 rounded-md flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-3">
            <AlertTriangle className="inline-block w-5" />
            <p>
              You have an overdue balance of{" "}
              <b>{formatters.number.getCurrencyFromNumber(loan.overdueBalance)}</b>.
            </p>
          </div>
          <Button
            onClick={prepaymentToggle.setOn}
            className="text-red-600 hover:bg-red-150"
            variant="ghost:red"
          >
            Make a payment
          </Button>
        </div>
      ) : null}

      <div className="flex flex-row flex-wrap gap-2">
        <PaymentCard
          className="xl:max-w-xl min-w-[400px] w-full"
          heading="Loan balance"
          tooltipText="Your current balance, including pending draws and payments."
          amountCents={loan?.balance}
          isLoading={isLoading}
          buttonText={
            canMakeAutomatedDraws || canMakeAutomatedPayments ? (
              <>
                <LandmarkIcon className="w-3 mr-1" />
                Manage bank accounts
              </>
            ) : null
          }
          onClick={bankAccountToggle.setOn}
          imgSrc={receiveFunds}
          imgClassName="h-[200px]"
        />
        {canMakePayments || isLoading ? (
          <PaymentCard
            className="xl:max-w-xl lg:min-w-[400px] min-w-[300px] w-full"
            heading={
              <>
                <p>Paid towards loan</p>
              </>
            }
            tooltipText="The total amount you have paid towards your loan. Includes pending payments."
            amountCents={loan?.amountPaid}
            isLoading={isLoading}
            buttonText={
              <>
                <CoinsIcon className="w-3 mr-1" />
                Make a loan payment
              </>
            }
            onClick={prepaymentToggle.setOn}
            imgClassName="h-[200px] ml-12"
            imgSrc={moneyJar}
          />
        ) : null}
        {(canMakeDraws || isLoading) && loan ? (
          <div className="w-full bg-green-50 rounded-md flex flex-row justify-between items-center p-8 flex-wrap gap-2">
            <div>
              <CardHeading
                isLoading={isLoading}
                heading="Equity available for draw"
                tooltipText={`This is calculated as the difference between your limit (${formatters.number.getCurrencyFromNumber(
                  loan.limit || 0
                )}) and your current balance.`}
              />
              <AmountDisplay amountCents={availableToDraw} isLoading={isLoading} />
            </div>
            <div className="flex flex-row items-center gap-2 text-gray-500">
              <div className="flex flex-row items-center gap-2 mr-8">
                <Clock />
                <p className="font-normal text-sm">
                  {differenceInDays(calculators.loan.getDrawPeriodEnd(loan), new Date())} days remain
                </p>
              </div>

              {availableToDraw ? (
                <Button variant="green" onClick={loanDrawToggle.setOn}>
                  Access your equity
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      {canMakePayments && loan?.nextPaymentDate && loan?.nextPaymentAmount && loan?.paymentAmount ? (
        <div className="text-sm text-gray-700 bg-gray-100 border border-gray px-4 py-4 rounded flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-3">
            <CalendarIcon className="inline-block w-4" />
            <p>
              Next payment of <b>{formatters.number.getCurrencyFromNumber(loan.nextPaymentAmount)}</b>
              {loan.nextPaymentAmount !== loan.paymentAmount ? (
                <>
                  {" "}
                  (out of <b>{formatters.number.getCurrencyFromNumber(loan.paymentAmount)})</b>
                </>
              ) : (
                ""
              )}{" "}
              is due {formatDistanceToNow(loan.nextPaymentDate, { addSuffix: true })} on{" "}
              <b>{format(loan.nextPaymentDate, "MMMM do")}</b>.
            </p>
          </div>
        </div>
      ) : null}
      {canMakePayments ? (
        <AutoPaymentBannerSection
          className="mt-2"
          loanId={loan?.id}
          recurringPaymentToggle={recurringPaymentToggle}
          paymentSubscriptionCreateToggle={paymentSubscriptionCreateToggle}
        />
      ) : null}
      <TransactionHistory
        data={transactionsQuery.data || []}
        loading={transactionsQuery.isLoading || isLoading}
      />
    </DashboardPage>
  );
};

const AutoPaymentBannerSection = ({
  recurringPaymentToggle,
  paymentSubscriptionCreateToggle,
  loanId,
  className,
}: {
  recurringPaymentToggle: Toggle;
  paymentSubscriptionCreateToggle: Toggle;
  loanId?: string;
  className?: string;
}) => {
  const { data, isLoading } = usePaymentSubscriptionQuery(loanId || "");

  return (
    <div className={className}>
      <SectionHeader
        header="Loan auto-payments"
        Icon={AutoDeposit}
        loading={isLoading}
        buttonText={data?.length ? "Disable" : "Enable"}
        onClick={data?.length ? recurringPaymentToggle.setOn : paymentSubscriptionCreateToggle.setOn}
      />
      {data?.length ? (
        <TransactionRow
          loading={isLoading}
          amount={-data?.[0]?.amount}
          status="SCHEDULED"
          date={endOfMonth(new Date())}
          title="Monthly recurring payment"
          subtitle={<MaskedBankAccount account={data?.[0]?.bankAccount} />}
        />
      ) : null}
    </div>
  );
};

const TransactionRow = ({
  amount,
  title,
  subtitle,
  status,
  date,
  loading,
}: {
  amount: number;
  title: string;
  subtitle: string | ReactNode;
  status: enums.TransactionStatus | "SCHEDULED";
  date: Date;
  loading?: boolean;
}) => {
  if (loading) {
    return <Skeleton className="h-20" />;
  }
  return (
    <div className="bg-white items-center flex flex-row justify-between gap-2 border border-gray-300 rounded-md overflow-x-scroll">
      <TransactionType className="flex-[1.50]  px-8 py-4" title={title} subtitle={subtitle} />
      <p className="font-light text-gray flex-[0.9] px-8 py-4">
        {formatters.number.getCurrencyFromNumber(amount, { explicitSign: true })}
      </p>
      <TransactionStatus className="flex-[1.1]  px-8 py-4" status={status} date={date} />
    </div>
  );
};

export default memo(PaymentsAndDraws);
