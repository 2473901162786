import { Ref, forwardRef } from "react";
import { TextInput, Touchable } from "src/components";
import { TextInputProps } from "src/components/TextInput";
import { useToggle } from "src/hooks";
import { Eye, VisibilityOff } from "src/icons";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

const styles = createStyles({
  adornmentContainer: {
    marginRight: 18,
    pointerEvents: "auto",
  },
  icon: {
    color: colors.palette.GREY,
    width: 22,
  },
});

const PasswordInput = forwardRef(
  (
    {
      variant,
      defaultValue,
      disabled,
      onChange,
      error,
      style,
      autoComplete = "password",
      name = "password",
      label = "Password",
      hasVisibilityToggle = false,
    }: TextInputProps & {
      hasVisibilityToggle?: boolean;
    },
    ref: Ref<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { on: passwordVisible, toggle } = useToggle();

    const visibilityButton = (
      <Touchable css={styles.adornmentContainer} onClick={toggle}>
        {passwordVisible ? <VisibilityOff css={styles.icon} /> : <Eye css={styles.icon} />}
      </Touchable>
    );

    return (
      <TextInput
        variant={variant}
        name={name}
        disabled={disabled}
        label={label}
        type={passwordVisible ? "text" : "password"}
        autoComplete={autoComplete}
        ref={ref}
        defaultValue={defaultValue}
        onChange={onChange}
        error={error}
        style={style}
        adornment={hasVisibilityToggle && visibilityButton}
      />
    );
  }
);

export default PasswordInput;
