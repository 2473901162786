import {
  ComplexApplicationFieldChecker,
  calculators,
  documents,
  enums,
  formatters,
  types,
} from "@fraction/shared";
import _ from "lodash";
import { HelpCircleIcon } from "lucide-react";
import { ReactNode } from "react";
import { ChecklistApp } from "src/api/fraction";
import { getCheckName, getCheckValue } from "src/apps/LOS/utils";
import { Badge } from "src/components/ui/badge";
import { useAccountViewPreference } from "src/hooks/useAccountViewPreference";
import { cn } from "src/utilities/shadcnUtils";

const tooltipMap: Record<string, string> = {
  [ComplexApplicationFieldChecker.VERIFIED_DEBTS_PAYOFF]:
    "Have either 'Order to Pay' or 'TLS from ILR' docs approved",
};

export const Checklist = ({
  app,
  status,
  className,
  header,
}: { app: ChecklistApp; status: enums.ApplicationStatus; className?: string; header?: ReactNode }) => {
  const checklist = app?.checklists?.[status];
  const debts = app ? calculators.application.selectAllDebts(app) : [];
  const incomes = app ? calculators.application.selectAllIncomes(app) : [];
  const { preference } = useAccountViewPreference();
  const documentsToShow = preference ? documents.documentTypesForAccessType[preference] : [];

  const filteredChecklist =
    preference === "employee"
      ? checklist
      : checklist?.filter(
          (item) =>
            types.isDocumentChecklistItem(item) &&
            item.status !== enums.ApplicationTaskStatus.NOT_APPLICABLE &&
            item.document &&
            (documentsToShow === "all" || documentsToShow?.includes(item.document))
        );

  return (
    <div className={cn("w-full", className)}>
      {header}
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left pl-3">Type</th>
            <th className="text-left">Status</th>
            <th className="text-left px-4 w-[200px]">Name</th>
            <th className="text-left px-4">Applicant</th>
            <th className="text-left px-4">Debt/Income</th>
            <th className="text-right pr-4">Value</th>
          </tr>
        </thead>
        <tbody className="[&>*:nth-child(odd)]:bg-gray-200 [&>*:nth-child(even)]:bg-white hover:[&>*]:bg-gray-400">
          {_.sortBy(filteredChecklist, "ok")?.map((check) => {
            const debt = check.debtId ? debts?.find((d) => d.id === check.debtId) : null;
            const income = check.incomeId ? incomes?.find((d) => d.id === check.incomeId) : null;
            const name = getCheckName(check);
            const tooltip = name ? tooltipMap[name] : undefined;

            return (
              <tr key={`${getCheckName(check)}-${check.debtId}-${check.incomeId}-${check.applicantId}`}>
                <td className="align-middle py-1.5 pl-3">
                  <Badge variant={check.ok ? "success" : "destructive"} className="rounded-full">
                    {_.startCase(check.type)?.split(" ")?.[0]}
                  </Badge>
                </td>
                <td className="align-middle py-1.5 px-4">
                  <Badge variant={check.ok ? "success" : "destructive"} className="rounded-full">
                    {types.isDocumentChecklistItem(check) && check.approval
                      ? check.status === enums.ApplicationTaskStatus.COMPLETE
                        ? formatters.enums.APPROVAL_STATUS_MAP[check.approval]
                        : formatters.enums.APPLICATION_TASK_STATUS_MAP[check.status]
                      : formatters.enums.APPLICATION_TASK_STATUS_MAP[check.status]}
                  </Badge>
                </td>
                <td title={tooltip} className="py-1.5 px-4 w-[200px] flex flex-row items-center">
                  <p className="text-sm">{_.startCase(getCheckName(check))}</p>
                  {tooltip ? <HelpCircleIcon size={14} className="ml-1" /> : null}
                </td>
                <td className="py-1.5 px-4 w-[450px]">
                  <Badge className="rounded-full text-ellipsis">
                    {check.applicantName ? check.applicantName : "All"}
                  </Badge>
                </td>
                <td className="py-1.5 px-4 w-[450px]">
                  {check.debtDescription || check.debtWithWho || check.debtType || debt?.currentlyOwing ? (
                    <Badge className="rounded-full text-ellipsis">
                      {[
                        check.debtDescription,
                        check.debtWithWho,
                        check.debtType
                          ? formatters.enums.DEBT_TYPE_MAP[check.debtType as enums.DebtType]
                          : undefined,
                        debt?.currentlyOwing
                          ? formatters.number.getCurrencyWords(debt.currentlyOwing)
                          : undefined,
                      ]
                        .filter(Boolean)
                        .join(" | ")}
                    </Badge>
                  ) : null}
                  {check.incomeDescription ||
                  check.incomeJobTitle ||
                  check.incomeType ||
                  income?.payment?.amount ? (
                    <Badge className="rounded-full text-ellipsis">
                      {[
                        check.incomeDescription,
                        check.incomeJobTitle,
                        check.incomeType
                          ? formatters.enums.INCOME_TYPE_MAP[check.incomeType as enums.IncomeType] ||
                            _.startCase(check.incomeType)
                          : undefined,
                        income?.payment?.amount
                          ? formatters.number.getCurrencyWords(income.payment.amount)
                          : undefined,
                      ]
                        .filter(Boolean)
                        .join(" | ")}
                    </Badge>
                  ) : null}
                </td>
                <td className="py-1.5 pr-4">
                  {types.isSimpleFieldChecklistItem(check) && check.field ? (
                    <p className="text-right text-sm">{getCheckValue(check, app)}</p>
                  ) : (
                    <div />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
