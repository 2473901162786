import { MouseEvent, useCallback } from "react";
import { toast } from "react-toastify";
import { Badge, BadgeProps } from "src/components/ui/badge";
import { cn } from "src/utilities/shadcnUtils";

const TOAST_OPTS = {
  autoClose: 1000,
  hideProgressBar: true,
  style: {
    backgroundColor: "rgba(212, 212, 216, 1)",
    borderRadius: "9999px",
    width: "fit-content",
    maxWidth: "fit-content",
  },
  bodyStyle: {
    color: "rgba(63, 63, 70, 1)",
    fontSize: 12,
    textAlign: "center" as const,
    fontWeight: 500,
    textWrap: "nowrap",
  },
  closeButton: false,
};

export const ClipboardBadge = (props: Omit<BadgeProps, "children"> & { children: string }) => {
  const handleClick = useCallback(
    (evt: MouseEvent) => {
      evt.stopPropagation();
      navigator.clipboard.writeText(props.children);
      toast(
        `Copied "${props.children.slice(0, 40)}${props.children.length > 40 ? "..." : ""}" to clipboard`,
        TOAST_OPTS
      );
    },
    [props.children]
  );

  return (
    <button onClick={handleClick}>
      <Badge className={cn("rounded-full", props.className)} {...props} />
    </button>
  );
};
