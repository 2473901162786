import { Link, NavLink } from "react-router-dom";
import { navLinkStyle } from "src/components/ui/navigation-menu";
import { Logo } from "src/images";

import { colors } from "@fraction/shared";
import { AccountDropdown } from "src/components/AccountDropdown";
import { createStyles } from "src/styles";

const styles = createStyles({
  logo: {
    width: 118,
    height: 20,
    "@media(max-width: 600px)": {
      width: 94.4,
      height: 16,
    },
  },
  container: {
    maxWidth: "100vw",
  },
});

export const LOSHeader = () => {
  return (
    <div css={styles.container} className="flex items-center pl-6 pt-4 pb-4 pr-6 border-b-2 border-solid">
      <Link to="/">
        <Logo css={styles.logo} width={118} height={20} color={colors.palette.BLACK} />
      </Link>
      <div className="pt-1 ml-6 overflow-x-scroll">
        <NavLink className={navLinkStyle} to="/">
          Pipeline
        </NavLink>
        <NavLink className={navLinkStyle} to="/mail">
          Mail
        </NavLink>
      </div>
      <div className="flex ml-auto">
        <AccountDropdown />
      </div>
    </div>
  );
};
