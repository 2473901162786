import fraction from "src/api/fraction";
import { useAuth } from "src/auth";
import { useAccountViewPreference } from "src/hooks/useAccountViewPreference";
import { useCachedQuery } from "src/hooks/useCache";

export function useBrokerage() {
  const { user } = useAuth();
  const { preference } = useAccountViewPreference();

  return useCachedQuery({
    enabled: preference === "broker",
    queryKey: ["brokerage", user?.id],
    queryFn: fraction.getUserBrokerage,
  });
}
