import { SerializedStyles } from "@emotion/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { memo, useRef } from "react";
import Skeleton from "react-loading-skeleton";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";
import { cn } from "src/utilities/shadcnUtils";

export interface VirtualTableProps<T = any> {
  columns: Array<ColumnDef<any>>;
  data: T[];
  onClickRow?: (row: T) => void;
  loading?: boolean | number;
  cellStyle?: SerializedStyles;
  rowStyle?: SerializedStyles;
  getRowStyle?: (row: T) => SerializedStyles | undefined;
  className?: string;
  headerClassName?: string;
  rowClassName?: string;
  tableClassName?: string;
  tableContainerClassName?: string;
  estimateRowHeight: number;
}

const styles = createStyles({
  tableContainer: {
    position: "relative",
    borderRadius: 6,
    overflow: "auto",
    display: "flex",
    flex: 1,
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  touchable: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: colors.HIGHLIGHTED,
    },
  },
  header: {
    padding: 11,
    paddingLeft: 32,
    textAlign: "left",
    fontFamily: "Hanken Grotesk",
    fontWeight: 400,
    fontSize: 12,
    color: colors.table.HEADER,
  },
  row: {
    paddingLeft: 50,
    borderBottomWidth: 0.5,
    borderBottomColor: colors.table.LINES,
    borderBottomStyle: "solid",
  },
  cell: {
    padding: 32,
    paddingTop: 16,
    paddingBottom: 16,
    textAlign: "left",
    fontFamily: "Hanken Grotesk",
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  seeMoreContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    minHeight: 48,
    // backgroundColor: colors.palette.GREY_200,
    "&:hover": {
      backgroundColor: colors.palette.GREY_100,
    },
  },
  seeMoreText: {
    fontFamily: "Hanken Grotesk",
    fontWeight: 600,
    fontSize: 14,
    color: colors.palette.GREY_600,
  },
  icon: {
    color: colors.ICON,
    height: 12,
    width: 12,
    marginLeft: 5,
  },
});

const VirtualTable = ({
  columns = [],
  data = [],
  onClickRow,
  loading,
  cellStyle,
  rowStyle,
  getRowStyle,
  className,
  headerClassName,
  rowClassName,
  tableClassName,
  tableContainerClassName,
  estimateRowHeight,
}: VirtualTableProps) => {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => estimateRowHeight, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" && navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  return (
    <div className={className}>
      <div
        ref={tableContainerRef}
        className={cn("h-[80svh]", tableContainerClassName)}
        css={styles.tableContainer}
      >
        <table className={tableClassName} css={styles.table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} css={[styles.row, rowStyle, { display: "flex", width: "100%" }]}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={headerClassName}
                    css={[
                      styles.header,
                      cellStyle,
                      {
                        display: "flex",
                        width: header.getSize(),
                      },
                    ]}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            css={{
              display: "grid",
              height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              position: "relative", //needed for absolute positioning of rows
            }}
          >
            {loading &&
              (typeof loading === "boolean" ? [1, 2] : Array.from(new Array(loading))).map((count) => (
                <tr key={count}>
                  {table.getHeaderGroups()[0].headers.map((header, idx) => (
                    <td css={[styles.cell, cellStyle]} key={idx}>
                      <Skeleton height={15} style={{ minWidth: 150, width: "60%" }} />
                    </td>
                  ))}
                </tr>
              ))}
            {!loading &&
              rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index];

                return (
                  <tr
                    key={row.id}
                    data-index={virtualRow.index}
                    ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                    className="hover:bg-gray-100"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      return onClickRow?.(row);
                    }}
                    css={[
                      styles.row,
                      onClickRow && styles.touchable,
                      rowStyle,
                      getRowStyle?.(row.original),
                      {
                        display: "flex",
                        position: "absolute",
                        transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                        width: "100%",
                      },
                    ]}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        className={cn("text-md text-gray-900", rowClassName)}
                        // @ts-ignore
                        css={[
                          styles.cell,
                          cellStyle,
                          // @ts-ignore
                          cell?.column?.style,
                          // @ts-ignore
                          cell?.column?.getStyle?.(row.original),
                          { display: "flex", width: cell.column.getSize(), height: "100%" },
                        ]}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(VirtualTable);
