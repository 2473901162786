import { entities, formatters } from "@fraction/shared";
import Avatar from "src/components/Avatar";
import Skeleton from "src/components/Skeleton";
import { Button } from "src/components/ui/button";
import { cn } from "src/utilities/shadcnUtils";

export default function EmployeeCard({
  contact,
  loading,
  className,
}: { contact?: Partial<entities.InternalEmployee>; loading?: boolean; className?: string }) {
  if (loading) {
    return <Skeleton height={122} width="100%" />;
  }
  if (!contact) {
    return null;
  }
  return (
    <div className={cn("gap-y-2 w-fit", className)}>
      <div className="flex flex-row gap-x-4 items-center">
        <Avatar className="rounded-full border border-black h-14 w-14" src={contact.photoLink}>
          {formatters.user.userName(contact.user)}
        </Avatar>
        <div>
          <div className="flex flex-row items-center justify-between gap-x-4">
            <p className="text-lg leading-5 font-bold">
              {contact.user?.firstName} {contact.user?.lastName}
            </p>
          </div>
          <div>
            <p>
              <a href={`mailto:${contact.user?.email}`} className="text-sm font-medium">
                {contact.user?.email}
              </a>
            </p>
            <p>
              <a href={`tel:${contact.user?.phone}`} className="text-sm font-medium">
                {formatters.phone.formatPhoneNumber(contact.user?.phone)}
              </a>
            </p>
          </div>
        </div>
      </div>
      {contact.calendarLink ? (
        <a href={contact.calendarLink}>
          <Button className="mt-2 self-start" variant="green">
            Book a call with {contact.user?.firstName}
          </Button>
        </a>
      ) : null}
    </div>
  );
}
