import * as React from "react";
import { renderToStaticMarkup } from "react-dom/server";

export interface TooltipProps {
  children: React.ReactNode;
  text: string | React.ReactNode;
  className?: string;
}

const Tooltip = ({ children, className, text, ...otherProps }: TooltipProps) => {
  const props: any =
    typeof text === "string"
      ? { "data-tooltip-content": text, ...otherProps }
      : // @ts-ignore
        { "data-tooltip-html": renderToStaticMarkup(text), ...otherProps };

  if (className) {
    props.className = className;
  }

  return (
    <>
      {children ? (
        typeof children === "string" ? (
          // @ts-ignore
          <p {...props} data-tooltip-id="tooltip">
            {children}
          </p>
        ) : (
          // @ts-ignore
          React.cloneElement(children, {
            "data-tooltip-id": "tooltip",
            ...props,
          })
        )
      ) : null}
    </>
  );
};

export default React.memo(Tooltip);
