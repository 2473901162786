import { ReactNode } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { AppOverview } from "src/apps/BrokerDashboard/pages/AppOverview";
import { BrokerDocsAndResourcesPage } from "src/apps/BrokerDashboard/pages/BrokerDocsAndResources";
import { PropertyReport } from "src/apps/BrokerDashboard/pages/Marketing/PropertyReport";
import Header from "src/apps/PostFundedDashboard/components/Header";
import NavDrawer from "src/apps/PostFundedDashboard/components/NavDrawer";
import { HeaderBreadcrumb } from "src/apps/PreFundedDashboard/components/HeaderBreadcrumb";
import { DashboardShell } from "src/components";
import { NavItems } from "./components/NavItems";
import BrokerSidebar from "./components/Sidebar";
import dashboardConfig from "./config";
import { BrokerMarketing } from "./pages/Marketing";
import { BrokerDashboardOverview } from "./pages/Overview";

const BrokerDashboardHeader = () => {
  return <Header Breadcrumbs={<HeaderBreadcrumb config={dashboardConfig} />} className="pl-6" />;
};

const BrokerDashboardLayout = ({ footer }: { footer?: ReactNode }) => {
  return (
    <>
      <DashboardShell footer={footer} header={<BrokerDashboardHeader />} sidebar={<BrokerSidebar />}>
        <Outlet />
      </DashboardShell>
      <NavDrawer NavItems={NavItems} />
    </>
  );
};

export function BrokerDashboardRoutes() {
  return (
    <Routes>
      <Route path="/" element={<BrokerDashboardLayout />}>
        <Route index path="/" element={<BrokerDashboardOverview accountType="broker" status="active" />} />
        <Route path="/closed" element={<BrokerDashboardOverview accountType="broker" status="closed" />} />
        <Route path="/marketing" element={<BrokerMarketing />} />
        <Route path="/marketing/property-report" element={<PropertyReport />} />
        <Route path="/broker-docs" element={<BrokerDocsAndResourcesPage />} />
        <Route path="/app/:id" element={<AppOverview />} />
      </Route>
    </Routes>
  );
}
