import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import { BigButton } from "src/components";
import { Variant } from "src/components/BigButton";
import { MediumText } from "src/components/v1";
import { createStyles } from "src/styles";

export interface ToggleableBigButtonProps<T extends string = string> {
  size?: number;

  checked?: boolean;
  onChange?: () => void;
  style?: SerializedStyles;
  label: string;
  // Value is used by parent RadioGroup
  value: T;
  // if true, when they click, animate it and call the onChange once that's done
  animatedSelect?: boolean;
}

const styles = createStyles(({ colors }) => ({
  buttonTextContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonText: {
    color: colors.grey700,
    textAlign: "left",
  },
}));

const ToggleableBigButton = <T extends string = string>({
  checked,
  onChange,
  style,
  label,
  animatedSelect,
}: ToggleableBigButtonProps<T>) => {
  return (
    <BigButton
      animatedSelect={animatedSelect}
      style={style}
      variant={checked ? Variant.GREEN : Variant.BASE}
      onClick={onChange}
    >
      <div css={styles.buttonTextContainer}>
        <MediumText style={styles.buttonText}>{label}</MediumText>
      </div>
    </BigButton>
  );
};

export default memo(ToggleableBigButton);
