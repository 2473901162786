import { Global, css } from "@emotion/react";
import { Children, ReactNode, cloneElement, isValidElement } from "react";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

import Popup, { PopupProps } from "../Popup";
import XButton from "../XButton";

export interface ModalBoxProps extends Omit<PopupProps, "children"> {
  width?: number | string;
  children: Array<ReactNode | boolean> | ReactNode | boolean;
  closeButton?: boolean | ReactNode;
  closeOnClickBackdrop?: boolean;
  closeOnEscape?: boolean;
}

const styles = createStyles({
  closeButton: {
    position: "absolute",
    top: 18,
    right: 18,
    backgroundColor: "transparent",
    zIndex: 10,
  },
});

const GLOBAL_STYLES = css`
  .popup-content {
    border-radius: 8px;
    max-width: 90%;
    width: 500px;
    min-height: 100px;
    padding: 0;
  }

  .popup-overlay {
    overflow-y: auto;
  }
`;

const ModalBox = ({
  children,
  width,
  closeButton = true,
  closeOnClickBackdrop = !!closeButton,
  closeOnEscape = !!closeButton,
  ...props
}: ModalBoxProps) => (
  <>
    <Global styles={GLOBAL_STYLES} />
    <Popup
      nested
      {...(width ? { contentStyle: { width } } : {})}
      {...props}
      closeOnDocumentClick={closeOnClickBackdrop}
      closeOnEscape={closeOnEscape}
    >
      {/*// @ts-ignore */}
      {(close: () => void) => (
        <>
          {Children.map(
            children,
            (child) => isValidElement(child) && cloneElement(child, { onClose: close } as any)
          )}
          {closeButton === true ? (
            <XButton onClick={close} color={colors.palette.GREY_400} style={styles.closeButton} />
          ) : closeButton === false ? null : (
            closeButton
          )}
        </>
      )}
    </Popup>
  </>
);

export default ModalBox;
