import {
  ApplicationTaskType,
  DocumentType,
  UI_APPLICATION_STATUS_ORDER,
  entities,
  enums,
  formatters,
  getNextApplicationStatus,
  selectors,
} from "@fraction/shared";
import { ReactNode } from "react";
import { useChecklist } from "src/apps/PreFundedDashboard/hooks/checklist";
import { Skeleton, Tooltip } from "src/components/index";
import { Badge } from "src/components/ui/badge";
import { useApplicationAuthed } from "src/hooks/useApplication";
import kycIcon from "src/images/kyc-icon.png";
import personalLoanIcon from "src/images/personal-loan-icon.png";
import quizIcon from "src/images/quiz-icon.png";
import { cn } from "src/utilities/shadcnUtils";

const TASKS_TO_SHOW = [
  ApplicationTaskType.KYC_GOVERNMENT_ID_VERIFICATION_COMPLETE,
  ApplicationTaskType.PRODUCT_REVIEW_COMPLETED,
  ApplicationTaskType.FOLLOWED_UP_WITH_FAILED_PRODUCT_REVIEW,
  DocumentType.KYC,
] as const;

interface TaskRecord {
  title: string;
  description: ReactNode;
  href?: (applicant: entities.ApplicantT) => string;
  showEmail?: boolean;
  showSms?: boolean;
  iconSource: any;
}

const TASK_MAP: Partial<Record<ApplicationTaskType | DocumentType, TaskRecord>> &
  Record<TaskToShow, TaskRecord> = {
  [ApplicationTaskType.KYC_GOVERNMENT_ID_VERIFICATION_COMPLETE]: {
    title: "KYC verification",
    description:
      "Fraction requires all applicants to complete a Know-Your-Customer (KYC) verification before funding. This is a simple process that helps us verify your identity that you can complete in a few minutes.",
    href: (applicant) => `http://go.fraction.com/kyc?reference-id=${applicant.id}`,
    showEmail: true,
    showSms: true,
    iconSource: kycIcon,
  },
  [DocumentType.KYC]: {
    title: "KYC verification",
    description:
      "Fraction requires all applicants to complete a Know-Your-Customer (KYC) verification before funding. This is a simple process that helps us verify your identity that you can complete in a few minutes.",
    href: (applicant) => `http://go.fraction.com/kyc?reference-id=${applicant.id}`,
    showEmail: true,
    showSms: true,
    iconSource: kycIcon,
  },
  [ApplicationTaskType.PRODUCT_REVIEW_COMPLETED]: {
    title: "Product review",
    description: `The product review is a quiz that Fraction requires all applicants to complete before funding. It helpfully explains the product—and don't worry, you can't fail it!`,
    href: (applicant) =>
      `https://fractionmortgagequiz.typeform.com/to/aHuQkcbh#name=${formatters.user.userName(
        applicant.user
      )}&email=${applicant.user?.email}&reference_id=${applicant.eid}`,
    showEmail: true,
    showSms: true,
    iconSource: personalLoanIcon,
  },
  [ApplicationTaskType.FOLLOWED_UP_WITH_FAILED_PRODUCT_REVIEW]: {
    title: "Product review follow-up",
    description:
      "You didn't get every question correct on the product review, so one of Fraction's team members will follow up with you to ensure you fully understand the product.",
    iconSource: quizIcon,
  },
};

type TaskToShow = typeof TASKS_TO_SHOW[number];
const isTaskToShow = (task?: ApplicationTaskType | DocumentType): task is TaskToShow =>
  !!task && TASKS_TO_SHOW.includes(task as TaskToShow);

export function TaskChecklist({ applicationId, className }: { applicationId?: string; className?: string }) {
  const { data: app, isLoading: loading } = useApplicationAuthed({
    id: applicationId,
  });

  const nextStatus = app?.status
    ? getNextApplicationStatus(app.status, UI_APPLICATION_STATUS_ORDER) || undefined
    : undefined;

  const {
    initiateTask,
    pendingTask,
    data: checklist,
  } = useChecklist({
    id: app?.id,
    status: nextStatus,
    initialData: nextStatus ? app?.checklists?.[nextStatus] : undefined,
  });

  const taskChecklist = checklist
    ?.filter(
      (item) =>
        ("task" in item && item.task && isTaskToShow(item.task)) ||
        ("document" in item && item.document && isTaskToShow(item.document))
    )
    ?.filter(
      (item) =>
        !(
          item.status === enums.ApplicationTaskStatus.NOT_APPLICABLE &&
          "task" in item &&
          item.task === ApplicationTaskType.FOLLOWED_UP_WITH_FAILED_PRODUCT_REVIEW
        )
    )
    ?.map((item) => {
      if (item.status === enums.ApplicationTaskStatus.NOT_APPLICABLE) {
        item.status = enums.ApplicationTaskStatus.TODO;
      }
      return item;
    });

  if (loading) {
    return <Skeleton className="max-w-3xl w-full h-[250px]" />;
  }

  if (!taskChecklist?.length) {
    return null;
  }

  return (
    <div className={cn("border p-6 border-gray-400 rounded max-w-3xl", className)}>
      <p className="text-2xl mb-3 font-bold">Task checklist</p>
      <p className="text-md mb-3">
        We have a few tasks that need to be completed before your application can be approved. Feel free to
        complete them now, otherwise, you can wait for someone to reach out to you to complete them later.
      </p>
      <div className="flex flex-col [&>*:nth-child(odd)]:bg-gray-200 [&>*:nth-child(even)]:bg-white hover:[&>*]:bg-gray-400">
        {taskChecklist?.map((item, idx) => {
          const field = "task" in item ? item?.task : "document" in item ? item?.document : undefined;
          if (!field) {
            return null;
          }
          const taskType = (
            field === DocumentType.KYC ? ApplicationTaskType.KYC_GOVERNMENT_ID_VERIFICATION_COMPLETE : field
          ) as ApplicationTaskType;
          const status = "rawStatus" in item ? item?.rawStatus : item?.status;
          const mapped = TASK_MAP[field];
          const loading = pendingTask?.taskType === field;
          const applicant = app?.applicants?.find((applicant) => applicant.id === item?.applicantId);
          const href = applicant ? mapped?.href?.(applicant) : undefined;
          const statusBadge = (
            <Badge
              variant={
                status === enums.ApplicationTaskStatus.COMPLETE
                  ? "success"
                  : status === enums.ApplicationTaskStatus.TODO
                  ? "secondary"
                  : "lightgreen"
              }
            >
              {status === enums.ApplicationTaskStatus.TODO && href
                ? "Get started"
                : formatters.enums.APPLICATION_TASK_STATUS_MAP[status]}
            </Badge>
          );

          const handleSendSms = () => {
            if (!applicant?.id || !field) {
              return;
            }

            initiateTask?.({
              applicantId: applicant.id,
              taskType: taskType,
              channels: ["sms"],
            });
          };

          const handleSendEmail = () => {
            if (!applicant?.id || !field) {
              return;
            }

            initiateTask?.({
              applicantId: applicant.id,
              taskType: taskType,
              channels: ["email"],
            });
          };

          const applicantPhone = applicant?.user ? selectors.user.selectUserPhone(applicant.user) : undefined;
          const applicantEmail = applicant?.user ? selectors.user.selectUserEmail(applicant.user) : undefined;

          return (
            <Tooltip key={`${applicant?.id}:${field}`} text={mapped?.description}>
              <div
                key={`${applicant?.id}:${field}`}
                className="flex flex-row flex-wrap gap-2 items-center justify-between p-3 rounded"
              >
                <div className="flex flex-row items-center">
                  {mapped?.iconSource && (
                    <img src={mapped?.iconSource} alt={mapped?.title} className="w-8 h-8 mr-3" />
                  )}
                  <div>
                    <p>{mapped?.title}</p>
                    {item?.applicantName ? (
                      <p className="text-sm font-normal text-gray-600">For {item?.applicantName}</p>
                    ) : null}
                  </div>
                </div>
                {status !== enums.ApplicationTaskStatus.COMPLETE ? (
                  <div className="flex flex-col gap-x-1 items-center flex-wrap justify-end">
                    {href ? (
                      <a target="_blank" href={href}>
                        {statusBadge}
                      </a>
                    ) : (
                      statusBadge
                    )}
                    {applicantPhone && mapped?.showSms ? (
                      <button onClick={handleSendSms}>
                        <Badge loading={loading && pendingTask?.channel === "sms"} variant="default">
                          Send SMS
                        </Badge>
                      </button>
                    ) : null}
                    {applicantEmail && mapped?.showEmail ? (
                      <button onClick={handleSendEmail}>
                        <Badge loading={loading && pendingTask?.channel === "email"} variant="blue">
                          Send email
                        </Badge>
                      </button>
                    ) : null}
                  </div>
                ) : (
                  statusBadge
                )}
              </div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
}
