import { SerializedStyles } from "@emotion/react";
import { MouseEventHandler } from "react";
import { Touchable } from "src/components";
import { X } from "src/icons";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";
import { cn } from "src/utilities/shadcnUtils";

interface XButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  style?: SerializedStyles;
  color?: string;
  className?: string;
}

const styles = createStyles({
  buttonContainer: {
    height: 52,
    width: 52,
    borderRadius: "50%",
  },
});

const XButton = ({ onClick, color, style, className }: XButtonProps) => (
  <Touchable className={cn("bg-gray-300", className)} onClick={onClick} css={[styles.buttonContainer, style]}>
    <X strokeWidth="3" height={20} width={20} color={color || colors.FADED_TEXT} />
  </Touchable>
);

export default XButton;
