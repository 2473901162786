import { ReactNode } from "react";
import ModalBox from "src/components/ModalBox";
import { Button, RegularText } from "src/components/v1";
import { ButtonType } from "src/components/v1/Button";
import { useMutation } from "src/lib";
import { createStyles } from "src/styles";

import { PopupProps } from "../Popup";

export interface ModalAction {
  text: string;
  type?: ButtonType;
  action?: () => void | Promise<void>;
  submit?: boolean;
  secondary?: boolean;
  close?: boolean;
  onClose?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

export interface ModalProps extends Omit<PopupProps, "children"> {
  message: ReactNode;
  actions?: ModalAction[];
  onClose?: () => void;
  children?: ReactNode;
}

const styles = createStyles({
  container: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 24,
    paddingBottom: 24,
  },
  text: {
    fontSize: 18,
  },
  button: {
    marginLeft: 16,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 24,
  },
});

const ActionButton = ({ text, action, type, onClose, submit, disabled, loading }: ModalAction) => {
  const handleClickAction = useMutation({
    mutationFn: async (e: any) => {
      onClose?.();
      await action?.();
    },
  });

  return (
    <Button
      style={styles.button}
      onClick={handleClickAction.mutate}
      type={type}
      submit={submit}
      loading={loading || handleClickAction.isPending}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export const ModalInnards = ({ onClose, message, actions, children, className }: ModalProps) => (
  <div css={styles.container} className={className}>
    <RegularText style={styles.text} paragraph>
      {message}
    </RegularText>
    {children}
    <div css={styles.buttonContainer}>
      {actions?.map((action, index) => (
        <ActionButton
          key={index}
          action={action.action}
          text={action.text}
          type={action.type}
          onClose={action.close === false ? undefined : onClose}
          disabled={action.disabled}
          loading={action.loading}
        />
      ))}
      {!actions && <ActionButton text="Okay" onClose={onClose} />}
    </div>
  </div>
);

const Modal = ({ message, actions, ...props }: ModalProps) => (
  <ModalBox closeButton={false} {...props}>
    <ModalInnards message={message} actions={actions} />
  </ModalBox>
);

export default Modal;
