import { Document, Image, Page, Styles, Text, View } from "@react-pdf/renderer";
import PdfLogo from "src/apps/BrokerDashboard/pages/Logo";
import { computeCostCalculatorRows } from "src/calculators/cost-calculator";
import { DropdownSelector, LocationInput, Skeleton, TextInput } from "src/components";
import { BackButton } from "src/components/BackButton";
import Dropzone, { FileWithEnumType } from "src/components/Dropzone";
import { CURRENCY_MASK, PERCENTAGE_MASK } from "src/components/TextInput";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { Label } from "src/components/ui/label";
import { useCurrentRate } from "src/hooks";
import { useBrokerage } from "src/hooks/useBrokerage";
import useToggler from "src/hooks/useToggler";

import { entities, formatters, types } from "@fraction/shared";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import fraction from "src/api/fraction";
import { tw } from "src/documents/styles";

import { memo, useState } from "react";
import finance from "src/api/finance";
import { useAuth } from "src/auth";
import { PDFViewer } from "src/components/PDFViewer";
import { useEventualState } from "src/hooks/useEventualState";

type Style = Styles[keyof Styles];

export const PropertyReport = () => {
  useDocumentTitle("Marketing | Property Report");

  const [address, setAddress] = useState<types.ExtractedPropertyAddress>();
  const [termLength, setTermLength] = useState<"5" | "4">("4");

  const propertyAddress = formatters.property.formattedAddress(address);

  const { user, isLoadingUser } = useAuth();
  const { data: brokerage } = useBrokerage();

  const report = useQuery({
    enabled: !!address,
    queryKey: ["property", propertyAddress],
    queryFn: () => fraction.getPropertyReport(formatters.property.shortFormattedAddress(address)),
  });

  const propertyReport = report.data;
  const { rate: minRate, isLoading: rateIsLoading } = useCurrentRate("Ontario", {
    termLength: Number(termLength) as 5 | 4,
  });

  const isLoading = report.isLoading || rateIsLoading || isLoadingUser;

  const [propertyValue, setPropertyValue] = useEventualState(
    propertyReport?.value ? (propertyReport?.value / 100).toString() : undefined
  );

  const { Toggle: ShowImageToggle, on: showImage } = useToggler({
    defaultValue: true,
    id: "show-image",
    label: "Show image",
    direction: "left",
  });
  const { Toggle: RoundImageToggle, on: roundImage } = useToggler({
    defaultValue: true,
    id: "round-image",
    label: "Rounded",
    direction: "left",
  });

  const [years, setYears] = useState("25");
  const [appreciation, setAppreciation] = useState("6");
  const [existingMortgageBalance, setExistingMortgageBalance] = useState("0");
  const [paymentType] = useState<"amortizing" | "interestOnly" | "reverse">("reverse");
  const [mortgageType, setMortgageType] = useState<"purchase" | "refinance">("refinance");
  const [paymentFrequency, setPaymentFrequency] = useState<"monthly" | "biweekly" | "weekly">("monthly");
  const [rate, setRate] = useEventualState(minRate ? (minRate?.minimumRate * 100)?.toString() : undefined);
  const [downPayment, setDownPayment] = useEventualState(
    propertyValue ? (formatters.number.getNumberFromString(propertyValue) * 0.2).toString() : undefined
  );

  const [principal, setPrincipal] = useEventualState(
    propertyValue && minRate?.maximumLTV
      ? (formatters.number.getNumberFromString(propertyValue) * minRate.maximumLTV).toString()
      : undefined
  );
  const paymentsPerYear = paymentFrequency === "monthly" ? 12 : paymentFrequency === "biweekly" ? 26 : 52;
  const monthlyAmortizationPayment =
    principal && rate
      ? finance.AM(
          formatters.number.getNumberFromString(principal),
          formatters.number.getNumberFromString(rate),
          paymentsPerYear * Number(years),
          1
        )
      : undefined;
  const [name, setName] = useEventualState(
    user?.fullName || user?.firstName ? `${user?.firstName} ${user?.lastName || ""}` : undefined
  );
  const [brokerageName, setBrokerageName] = useEventualState(
    brokerage?.brokerGroup?.tradeName || brokerage?.brokerGroup?.name
  );

  const amortizationPaymentForFrequency = monthlyAmortizationPayment
    ? paymentFrequency === "monthly"
      ? monthlyAmortizationPayment
      : paymentFrequency === "biweekly"
      ? monthlyAmortizationPayment / 2
      : monthlyAmortizationPayment / 4.3333
    : undefined;
  const monthlyInterestOnlyPayment =
    principal && rate
      ? (formatters.number.getNumberFromString(principal) * formatters.number.getNumberFromString(rate)) /
        100 /
        12
      : undefined;

  const interestOnlyPaymentForFrequency = monthlyInterestOnlyPayment
    ? paymentFrequency === "monthly"
      ? monthlyInterestOnlyPayment
      : paymentFrequency === "biweekly"
      ? monthlyInterestOnlyPayment / 2
      : monthlyInterestOnlyPayment / 4.3333
    : undefined;

  const [agentImage, setAgentImage] = useState<FileWithEnumType | null>(null);
  const buffer = useQuery({
    queryKey: ["agentImage", agentImage?.name],
    enabled: !!agentImage,
    queryFn: async () => {
      if (!agentImage) {
        return;
      }
      return URL.createObjectURL(agentImage);
    },
  });

  const numPrincipal = principal ? formatters.number.getNumberFromString(principal) : 0;
  const numPropertyValue = propertyValue ? formatters.number.getNumberFromString(propertyValue) : 0;
  const cappedLoanAmount = Math.min(numPrincipal, numPropertyValue * (minRate?.maximumLTV ?? 0.44));

  const rows = computeCostCalculatorRows({
    rate: rate ? formatters.number.getNumberFromString(rate) / 100 : 0,
    loanAmount: cappedLoanAmount,
    homeValue: numPropertyValue,
    appreciationRate: Number(appreciation) / 100,
    termLength: Number(termLength),
  });

  return (
    <div className="p-6">
      <BackButton to="/marketing" className="mb-4" label="Marketing Materials - Property report" />
      <div className="flex flex-row flex-wrap mt-6 gap-x-8">
        <div className="flex flex-col w-full flex-1 lg:max-w-[400px]">
          <ShowImageToggle className="mb-4" />
          <LocationInput
            name="Address"
            label="Property address"
            value={address ? formatters.property.formattedAddress(address, { premise: true }) : undefined}
            onSelect={setAddress}
          />
          <DropdownSelector
            defaultValue={mortgageType}
            value={mortgageType}
            label="Mortgage type"
            onChange={setMortgageType}
            options={[
              { value: "purchase", label: "Purchase" },
              { value: "refinance", label: "Refinance" },
            ]}
          />
          {/*
          <DropdownSelector
            defaultValue={paymentType}
            value={paymentType}
            label="Payment type"
            onChange={setPaymentType}
            options={[
              { value: "amortizing", label: "Amortizing" },
              { value: "interestOnly", label: "Interest Only" },
              { value: "reverse", label: "Reverse / No Payments" }
            ]}
          />
          */}

          <TextInput
            maskOptions={CURRENCY_MASK}
            label="Property value"
            value={propertyValue}
            onChange={setPropertyValue}
          />
          {mortgageType === "refinance" ? (
            <TextInput
              maskOptions={CURRENCY_MASK}
              label={`Existing mortgage balance ${
                existingMortgageBalance && propertyValue
                  ? `(${formatters.number.getPercentageFromDecimal(
                      formatters.number.getNumberFromString(existingMortgageBalance) /
                        formatters.number.getNumberFromString(propertyValue),
                      { digits: 0 }
                    )} of property value)`
                  : ""
              }`}
              value={existingMortgageBalance}
              onChange={setExistingMortgageBalance}
            />
          ) : null}
          {mortgageType === "purchase" ? (
            <TextInput
              maskOptions={CURRENCY_MASK}
              label={`Downpayment ${
                downPayment && propertyValue
                  ? `(${formatters.number.getPercentageFromDecimal(
                      formatters.number.getNumberFromString(downPayment) /
                        formatters.number.getNumberFromString(propertyValue),
                      { digits: 0 }
                    )} of property value)`
                  : ""
              }`}
              value={downPayment}
              onChange={setDownPayment}
            />
          ) : null}
          <TextInput
            errorSpacing={false}
            maskOptions={CURRENCY_MASK}
            label={`Principal ${
              principal && propertyValue
                ? `(${formatters.number.getPercentageFromDecimal(
                    formatters.number.getNumberFromString(principal) /
                      formatters.number.getNumberFromString(propertyValue),
                    { digits: 0 }
                  )} of property value)`
                : ""
            }`}
            value={principal}
            onChange={setPrincipal}
          />
          <p className="font-normal text-xs text-gray-600 mb-4 pr-4 pl-0.5 mt-1 rounded-lg">
            Note: Fraction only lends up to a maximum of 44% LTV with a four-year term.
          </p>
          <TextInput
            errorSpacing={false}
            maskOptions={PERCENTAGE_MASK}
            label="Rate"
            value={rate}
            onChange={setRate}
          />
          <p className="font-normal text-xs text-gray-600 mb-4 pr-4 pl-0.5 mt-1 rounded-lg">
            {minRate?.minimumRate ? formatters.number.getPercentageFromDecimal(minRate?.minimumRate) : "This"}{" "}
            is Fraction's currently posted rate for the {termLength} year term. These rates do change from
            time to time.
          </p>
          {paymentType !== "reverse" ? (
            <DropdownSelector
              defaultValue={paymentFrequency}
              value={paymentFrequency}
              label="Payment frequency"
              onChange={setPaymentFrequency}
              options={[
                { value: "monthly", label: "Monthly" },
                { value: "biweekly", label: "Bi-Weekly" },
                { value: "weekly", label: "Weekly" },
              ]}
            />
          ) : null}
          {paymentType === "amortizing" ? (
            <TextInput type="number" label="Amortization period (years)" value={years} onChange={setYears} />
          ) : null}
          <TextInput className="mt-1" label="Your name" value={name} onChange={setName} />
          <TextInput label="Your brokerage" value={brokerageName} onChange={setBrokerageName} />
          <div className="flex flex-row justify-between mb-2">
            <Label>Your picture/logo</Label>
            <RoundImageToggle />
          </div>
          <div className="border border-1 border-gray-400 rounded p-4">
            <Dropzone className="w-full" multi={false} onDrop={setAgentImage} />
            <img className="w-6 rounded-full" src={buffer?.data} />
          </div>
          <div className="mt-4">
            <DropdownSelector
              value={termLength}
              label="Fraction term length"
              onChange={setTermLength}
              options={[
                { value: "5", label: "Five years" },
                { value: "4", label: "Four years" },
                { value: "3", label: "Three years" },
              ]}
            />
          </div>
          <TextInput
            maskOptions={PERCENTAGE_MASK}
            label="Property appreciation"
            value={appreciation}
            onChange={setAppreciation}
          />
        </div>
        <div className="h-[85svh] w-full flex-1 lg:min-w-[400px] lg:max-w-[700px] rounded-md border border-gray-300">
          {isLoading ? (
            <Skeleton className="h-[85svh] w-[95%]" />
          ) : (
            <div className="relative h-full w-full">
              <PDFViewer filename="Fraction Property Report" download className="h-full w-full">
                <PropertyReportDocument
                  name={name}
                  brokerageName={brokerageName}
                  roundImage={roundImage}
                  bufferData={buffer?.data}
                  address={address}
                  showImage={showImage}
                  propertyValue={propertyValue}
                  propertyReport={propertyReport}
                  principal={principal}
                  mortgageType={mortgageType}
                  existingMortgageBalance={existingMortgageBalance}
                  rate={rate}
                  paymentType={paymentType}
                  paymentFrequency={paymentFrequency}
                  cappedLoanAmount={cappedLoanAmount}
                  minRate={minRate}
                  termLength={termLength}
                  interestOnlyPaymentForFrequency={interestOnlyPaymentForFrequency}
                  numPrincipal={numPrincipal}
                  rows={rows}
                  appreciation={appreciation}
                  amortizationPaymentForFrequency={amortizationPaymentForFrequency}
                  propertyAddress={propertyAddress}
                />
              </PDFViewer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PropertyReportDocument = memo(
  ({
    name,
    brokerageName,
    roundImage,
    bufferData,
    address,
    showImage,
    propertyValue,
    propertyReport,
    principal,
    mortgageType,
    existingMortgageBalance,
    rate,
    paymentType,
    paymentFrequency,
    cappedLoanAmount,
    minRate,
    termLength,
    interestOnlyPaymentForFrequency,
    numPrincipal,
    rows,
    appreciation,
    amortizationPaymentForFrequency,
    propertyAddress,
  }: {
    name?: string;
    brokerageName?: string;
    roundImage?: boolean;
    bufferData?: Buffer;
    address?: entities.Property;
    showImage?: boolean;
    propertyValue?: number;
    // i am sorry for my sins but i am lazy and there are SOOO many props
  } & any) => {
    const Footer = () => (
      <View style={tw("mt-auto flex flex-row items-center justify-between")}>
        <View>
          <PdfLogo />
          <Text style={tw("text-sm font-semibold mt-2 text-[#82c882]")}>www.fraction.com</Text>
        </View>
        <View style={tw("flex flex-row")}>
          <View style={tw("mr-4 flex flex-col items-end justify-center")}>
            <Text style={tw("text-base font-semibold text-end")}>{name}</Text>
            <Text style={tw("text-base text-end")}>{brokerageName}</Text>
          </View>
          {bufferData ? (
            <Image
              style={tw(`h-14 max-w-32 object-contain ${roundImage ? "rounded-full" : ""}`)}
              src={bufferData}
            />
          ) : null}
        </View>
      </View>
    );

    return (
      <Document>
        <Page size="LETTER" style={tw("p-12 font-sans")}>
          <Text style={tw("text-4xl font-bold font-serif leading-snug mb-2")}>Your Property Report</Text>
          {address ? (
            <>
              <View style={tw("flex flex-row bg-gray-200 rounded")}>
                {showImage ? (
                  <Image
                    style={tw("h-48 w-48 rounded")}
                    src={`https://maps.googleapis.com/maps/api/streetview?key=AIzaSyCpLsRv0TaXWPX6j-a9Xw7834Hm2mG4xL0&size=600x600&location=${propertyAddress}&fov=100`}
                  />
                ) : null}

                <View
                  wrap
                  style={tw("flex-1 p-6 px-8 flex flex-wrap items-start justify-start gap-y-2 flex-row")}
                >
                  <div style={tw("mr-8")}>
                    <Text style={tw("text-lg font-bold")}>Property Address</Text>

                    <Text style={tw("flex-initial text-base font-medium")}>
                      {formatters.property.line1FormattedAddress(address)}
                    </Text>
                    <Text style={tw("flex-initial text-base font-medium")}>
                      {formatters.property.line2FormattedAddress(address)}
                    </Text>
                  </div>
                  <div style={tw("mr-8")}>
                    <Text style={tw("text-lg font-bold")}>Est. Property Value</Text>
                    <Text style={tw("flex-initial text-base font-medium")}>
                      {formatters.number.getCurrencyFromNumber(propertyValue, {
                        inputInDollars: true,
                      })}
                    </Text>
                  </div>
                  <div style={tw("mr-8")}>
                    <Text style={tw("text-lg font-bold")}>Assessed Value</Text>
                    <Text style={tw("flex-initial text-base font-medium")}>
                      {formatters.number.getCurrencyFromNumber(
                        _.sortBy(propertyReport?.assessments, "date")?.reverse()?.[0]?.value?.cents
                      )}
                    </Text>
                  </div>
                </View>
              </View>

              <View style={tw("flex flex-col bg-gray-200 rounded mt-4 p-6")}>
                <Text style={tw("text-xl font-black mb-1")}>Property Details</Text>
                <View style={tw("flex flex-row flex-wrap space-between")}>
                  <LabeledValue label="Property type" value={propertyReport?.houseStyle?.split(" ")?.[0]} />
                  <LabeledValue
                    label="Bedrooms"
                    value={propertyReport?.bedroomsTotal ?? propertyReport?.bedroomsTotalEst}
                  />
                  <LabeledValue
                    label="Bathrooms"
                    value={propertyReport?.bathroomsTotal ?? propertyReport?.bathroomsTotalEst}
                  />
                  <LabeledValue label="Year built" value={propertyReport?.yearBuiltActual} />
                  <LabeledValue
                    label="Condo fees"
                    value={
                      propertyReport?.condoFees?.cents || propertyReport?.condoFeesEst?.cents
                        ? formatters.number.getCurrencyFromNumber(
                            propertyReport?.condoFees?.cents || propertyReport?.condoFeesEst?.cents
                          )
                        : undefined
                    }
                  />
                  <LabeledValue
                    style={tw("mr-24")}
                    label="Floor space"
                    value={
                      propertyReport?.livingArea ?? propertyReport?.livingAreaEst
                        ? `${propertyReport?.livingArea ?? propertyReport?.livingAreaEst} sqft`
                        : undefined
                    }
                  />
                  <LabeledValue
                    style={tw("mr-24")}
                    label="Basement"
                    value={propertyReport?.basement ? `${propertyReport?.basement} sqft` : undefined}
                  />
                </View>
              </View>
              <View style={tw("flex flex-col bg-gray-200 rounded mt-4 p-6")}>
                <Text style={tw("text-xl font-black mb-1")}>Property Stats</Text>
                <View style={tw("flex flex-row flex-wrap")}>
                  <LabeledBox
                    label={`Avg ${
                      propertyReport?.cityStats?.year || ""
                    } value of a ${propertyReport?.cityStats?.assessmentClass?.toLowerCase()} in ${
                      propertyReport?.cityName
                    }`}
                    value={
                      propertyReport?.cityStats?.avgAssessedValue?.cents
                        ? formatters.number.getCurrencyWords(
                            propertyReport?.cityStats?.avgAssessedValue?.cents,
                            { short: true, digits: 1 }
                          )
                        : undefined
                    }
                  />
                  <LabeledBox
                    style={tw("ml-2")}
                    label={`Avg ${
                      (propertyReport?.cityStats?.year || 0) - 1
                    } value of a ${propertyReport?.cityStats?.assessmentClass?.toLowerCase()} in ${
                      propertyReport?.cityName
                    }`}
                    value={
                      propertyReport?.cityStats?.previousAvgAssessedValue?.cents
                        ? formatters.number.getCurrencyWords(
                            propertyReport?.cityStats?.previousAvgAssessedValue?.cents,
                            { short: true, digits: 1 }
                          )
                        : undefined
                    }
                  />
                  <LabeledBox
                    style={tw("ml-2")}
                    label={`Avg ${
                      propertyReport?.cityStats?.year || ""
                    } value of a ${propertyReport?.neighbourhoodAttributes?.assessmentClass?.toLowerCase()} in ${
                      propertyReport?.neighbourhoodName
                    }`}
                    value={
                      propertyReport?.neighbourhoodStats?.currentPredictedValue?.cents
                        ? formatters.number.getCurrencyWords(
                            propertyReport?.neighbourhoodStats?.currentPredictedValue?.cents,
                            { short: true, digits: 1 }
                          )
                        : undefined
                    }
                  />
                  <LabeledBox
                    style={tw("ml-2")}
                    label={`Avg ${
                      (propertyReport?.neighbourhoodStats?.year || 0) - 1
                    } value of a ${propertyReport?.neighbourhoodAttributes?.assessmentClass?.toLowerCase()} in ${
                      propertyReport?.neighbourhoodName
                    }`}
                    value={
                      propertyReport?.neighbourhoodStats?.previousPredictedValue?.cents
                        ? formatters.number.getCurrencyWords(
                            propertyReport?.neighbourhoodStats?.previousPredictedValue?.cents,
                            { short: true, digits: 1 }
                          )
                        : undefined
                    }
                  />
                </View>
              </View>
            </>
          ) : null}
          {!address ? (
            <Text style={tw("text-2xl font-semibold leading-snug mt-2")}>
              Enter a property address to get started
            </Text>
          ) : (
            <View style={tw("flex flex-col bg-gray-200 rounded mt-4 p-6")}>
              <Text style={tw("text-xl font-black mb-1")}>Mortgage Details</Text>
              <View style={tw("flex flex-row flex-wrap space-between")}>
                <LabeledValue
                  label="Principal"
                  value={formatters.number.getCurrencyFromNumber(principal, { inputInDollars: true })}
                />
                {mortgageType === "refinance" && principal ? (
                  <LabeledValue
                    label="Cash out"
                    value={formatters.number.getCurrencyFromNumber(
                      formatters.number.getNumberFromString(principal) -
                        formatters.number.getNumberFromString(existingMortgageBalance),
                      { inputInDollars: true }
                    )}
                  />
                ) : null}
                {rate ? (
                  <LabeledValue
                    label="Rate"
                    value={formatters.number.getPercentageFromDecimal(Number(rate) / 100)}
                  />
                ) : null}
                <LabeledValue
                  label="Mortgage type"
                  value={mortgageType === "purchase" ? "Purchase" : "Refinance"}
                />
                <LabeledValue
                  label="Payments"
                  value={
                    paymentType === "interestOnly"
                      ? "Interest only"
                      : paymentType === "reverse"
                      ? "No Payments"
                      : "Amortizing"
                  }
                />
                <LabeledValue
                  label={`${_.capitalize(paymentFrequency)} payments`}
                  value={
                    paymentType === "reverse"
                      ? "None"
                      : paymentType === "interestOnly"
                      ? formatters.number.getCurrencyFromNumber(interestOnlyPaymentForFrequency, {
                          inputInDollars: true,
                        })
                      : formatters.number.getCurrencyFromNumber(amortizationPaymentForFrequency, {
                          inputInDollars: true,
                        })
                  }
                />
              </View>
            </View>
          )}
          <Footer />
        </Page>
        <Page size="LETTER" style={tw("p-12 font-sans")}>
          <Text style={tw("text-2xl font-bold leading-snug")}>Could Fraction work for you?</Text>
          <Text style={tw("text-base mt-2")}>
            Fraction offers a mortgage that allows you to access the equity in your home without monthly
            payments for up to 5 year terms—all without any prepayment penalties.{" "}
            {cappedLoanAmount < numPrincipal ? (
              <>
                Since Fraction only lends up to a maximum of{" "}
                {formatters.number.getPercentageFromDecimal(minRate?.maximumLTV ?? 0.44, {
                  digits: 0,
                })}{" "}
                loan-to-value (LTV) with a {termLength}-year term,{" "}
                <Text style={tw("font-bold")}>
                  the example below is capped at{" "}
                  {formatters.number.getCurrencyFromNumber(cappedLoanAmount, {
                    inputInDollars: true,
                  })}
                </Text>
                .
              </>
            ) : (
              ""
            )}
          </Text>
          <View style={tw("flex flex-col bg-gray-200 rounded mt-4 p-6")}>
            <Text style={tw("text-xl font-black")}>Fraction details and requirements</Text>
            <Text style={tw("text-base mb-3")}>
              These are the basic qualifying criteria for whether an application might be accepted. Fraction
              offers different rates and maximum loan-to-values based on the the term length selected.
            </Text>
            <View style={tw("flex flex-row flex-wrap gap-2")}>
              <LabeledBox label="Min. credit score" value="640" />
              {minRate ? (
                <LabeledBox
                  label={`${formatters.number.getPercentageFromDecimal(minRate.maximumLTV, {
                    digits: 0,
                  })} Max. LTV`}
                  value={
                    propertyValue && minRate?.maximumLTV
                      ? formatters.number.getCurrencyWords(
                          formatters.number.getNumberFromString(propertyValue) * minRate.maximumLTV,
                          { inputInDollars: true, digits: 0 }
                        )
                      : undefined
                  }
                />
              ) : null}
              <LabeledBox label="Term length" value={`${termLength} yrs`} />
              <LabeledBox label="Property residence" value="Primary" />
              <LabeledBox label="Charge position" value="1st mtg" />
              <LabeledBox label="Max loan amount" value="$1.5mm" />
              <LabeledBox label="Ownership type" value="Freehold" />
              <LabeledBox label="Registration type" value="Personal" />
            </View>
          </View>
          <View style={tw("mt-4")}>
            <Text style={tw("text-2xl font-black leading-snug")}>Cost calculator</Text>
            <Text style={tw("text-base mb-3 mt-1")}>
              If you originally borrowed{" "}
              <Text style={tw("font-bold")}>
                {formatters.number.getCurrencyFromNumber(cappedLoanAmount, {
                  inputInDollars: true,
                })}
              </Text>{" "}
              from Fraction with a{" "}
              {formatters.number.getCurrencyFromNumber(propertyValue, { inputInDollars: true })} house, in{" "}
              <Text style={tw("font-bold")}>{minRate?.termLength || 5}</Text> years time, your house will be
              worth around{" "}
              <Text style={tw("font-bold")}>
                {formatters.number.getCurrencyFromNumber(rows[rows.length - 1].homeValueAtYear, {
                  inputInDollars: true,
                })}
              </Text>{" "}
              based on a yearly appreciation of {appreciation}%. You would then owe{" "}
              <Text style={tw("font-bold")}>
                {formatters.number.getCurrencyFromNumber(rows[rows.length - 1].totalLoanBalance, {
                  inputInDollars: true,
                })}
              </Text>{" "}
              and own{" "}
              <Text style={tw("font-bold")}>
                {formatters.number.getCurrencyFromNumber(
                  Math.max(rows[rows.length - 1].homeValueAtYear - rows[rows.length - 1].totalLoanBalance, 0),
                  {
                    inputInDollars: true,
                  }
                )}
              </Text>{" "}
              of your home.
            </Text>
            <View style={tw("flex flex-col text-center text-sm")}>
              <View style={tw("flex flex-row bg-gray-200")}>
                <View style={tw("flex-1 p-2")}>
                  <Text style={tw("font-bold")}>Year</Text>
                </View>
                <View style={tw("flex-1 p-2")}>
                  <Text style={tw("font-bold")}>Total Interest</Text>
                </View>
                <View style={tw("flex-1 p-2")}>
                  <Text style={tw("font-bold")}>Loan Balance</Text>
                </View>
                <View style={tw("flex-1 p-2")}>
                  <Text style={tw("font-bold")}>Home Value</Text>
                </View>
                <View style={tw("flex-1 p-2")}>
                  <Text style={tw("font-bold")}>Your Equity</Text>
                </View>
                <View style={tw("flex-1 p-2")}>
                  <Text style={tw("font-bold")}>APR</Text>
                </View>
              </View>
              {rows.map((row: any, i: number) => (
                <View key={row.year} style={tw(`flex flex-row ${i % 2 === 0 ? "" : "bg-gray-200"}`)}>
                  <View style={tw("flex-1 p-1")}>
                    <Text>{row.year}</Text>
                  </View>
                  <View style={tw("flex-1 p-1")}>
                    <Text>
                      {formatters.number.getCurrencyFromNumber(row.totalAccruedInterest, {
                        inputInDollars: true,
                      })}
                    </Text>
                  </View>
                  <View style={tw("flex-1 p-1")}>
                    <Text>
                      {formatters.number.getCurrencyFromNumber(row.totalLoanBalance, {
                        inputInDollars: true,
                      })}
                    </Text>
                  </View>
                  <View style={tw("flex-1 p-1")}>
                    <Text>
                      {formatters.number.getCurrencyFromNumber(row.homeValueAtYear, {
                        inputInDollars: true,
                      })}
                    </Text>
                  </View>
                  <View style={tw("flex-1 p-1")}>
                    <Text>
                      {formatters.number.getCurrencyFromNumber(row?.homeValueAtYear - row?.totalLoanBalance, {
                        inputInDollars: true,
                      })}
                    </Text>
                  </View>
                  <View style={tw("flex-1 p-1")}>
                    <Text>{formatters.number.getPercentageFromDecimal(row.apr)}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>

          <Footer />
        </Page>
      </Document>
    );
  },
  _.isEqual
);

const LabeledBox = ({
  label,
  value,
  style,
}: { label?: string; value?: string | number | null; style?: Style | Style[] }) => {
  if (!value) {
    return null;
  }
  return (
    <View
      style={[
        tw(
          "p-2 h-32 w-40 border rounded-xl border-1 border-black flex flex-col items-center justify-center py-4"
        ),
        style,
      ]}
    >
      <Text style={tw("text-3xl leading-normal text-center font-black")}>{value}</Text>
      <Text style={tw("text-sm text-center font-semibold")}>{label}</Text>
    </View>
  );
};

const LabeledValue = ({
  label,
  value,
  style,
}: { label?: string; value?: string | number | null; style?: Style | Style[] }) => {
  if (!value) {
    return null;
  }
  return (
    <View style={[tw("flex basis-1/3 flex-row items-center mr-20 mb-2"), style]}>
      <Text style={tw("flex-1 flex-initial text-base font-bold mr-2 truncate")}>{label}:</Text>
      <Text style={tw("flex-1 flex-initial text-base font-medium")}>{value}</Text>
    </View>
  );
};
