import { SerializedStyles } from "@emotion/react";
import { ReactNode } from "react";
import { ModalAction } from "src/components/Modal";
import { BoldText, Button, MediumText } from "src/components/v1";
import { ChevronLeft } from "src/icons";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

const styles = createStyles({
  container: {
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px 32px",
    overflowX: "hidden",
  },
  secondaryContainer: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 0,
  },
  contentContainer: {
    padding: 32,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    width: "100%",
  },
  textContainer: {
    marginBottom: 32,
  },
  buttonRow: {
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.LIGHT_BACKGROUND,
    padding: 32,
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 32,
    borderRadius: "0 0 8px 8px",
  },
  oneActionButtonRow: {
    justifyContent: "flex-end",
  },
  header: {
    marginTop: 24,
    fontSize: 18,
    color: colors.TEXT,
    textAlign: "center",
    lineHeight: "160%",
  },
  secondaryHeader: {
    color: colors.TEXT,
    fontSize: 18,
  },
  illustratedParagraph: {
    textAlign: "center",
    fontSize: 14,
    color: colors.palette.GREY_500,
    lineHeight: "160%",
  },
  illustratedBodyContainer: {
    marginBottom: 32,
    marginTop: 8,
  },
  inputParagraph: {
    fontSize: 14,
    color: colors.palette.GREY_500,
    lineHeight: "160%",
    marginTop: 16,
  },
  button: {
    padding: "16px 32px",
  },
  secondaryButton: {
    padding: "16px 32px",
    marginTop: 4,
  },
  secondaryButtonText: {
    color: colors.palette.GREEN,
  },
  backText: {
    fontSize: 14,
  },
  chevron: {
    width: 16,
    marginRight: 4,
  },
  backButton: {
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 0,
  },
  singleButton: {
    width: "100%",
  },
  footerContainer: {
    marginTop: 0,
    width: "100%",
  },
  noActionsFooter: {
    marginTop: 48,
  },
  stackedFooter: {
    padding: 32,
    paddingTop: 16,
    paddingBottom: 16,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
  },
  stackedPrimaryButton: {
    width: "100%",
  },
  stackedSecondaryButton: {
    marginTop: 8,
  },
  stackedSpacer: {
    margin: 8,
  },
});

export interface IllustratedModalContentProps {
  image: ReactNode;
  actions: ModalAction[];
  header: string;
  text?: string | string[] | ReactNode | ReactNode[];
  children?: ReactNode;
  style?: SerializedStyles;
}

export const IllustratedModalContent = ({
  header,
  text,
  image,
  actions,
  children,
  style,
}: IllustratedModalContentProps) => (
  <div css={[styles.container, style]}>
    {image}
    <BoldText style={styles.header}>{header}</BoldText>
    <div css={styles.illustratedBodyContainer}>
      {(Array.isArray(text) ? text : [text]).map((paragraph, index) =>
        typeof paragraph === "string" ? (
          <MediumText style={styles.illustratedParagraph} key={index}>
            {paragraph}
          </MediumText>
        ) : (
          paragraph
        )
      )}
      {children}
    </div>
    {actions.map((action, index) => (
      <Button
        key={`button-${index}`}
        style={index === 0 ? styles.button : styles.secondaryButton}
        textCss={index === 0 ? undefined : styles.secondaryButtonText}
        type={action.type}
        onClick={action.action}
      >
        {action.text}
      </Button>
    ))}
  </div>
);

type onSubmit = (() => void) | ((event: React.ChangeEvent<HTMLFormElement>) => void);

export interface InputModalFormProps {
  header: string;
  children: ReactNode[] | ReactNode;
  footer?: ReactNode;
  paragraph?: string;
  onSubmit?: onSubmit;
  marginBottom?: number;
}

export const InputModalForm = ({
  children,
  header,
  paragraph,
  footer,
  onSubmit,
  marginBottom = 48,
}: InputModalFormProps) => (
  <form onSubmit={onSubmit}>
    <div css={[styles.container, styles.secondaryContainer, { marginBottom: footer ? 0 : marginBottom }]}>
      <div css={[styles.contentContainer]}>
        <div css={styles.textContainer}>
          <BoldText style={styles.secondaryHeader}>{header}</BoldText>
          {paragraph ? <MediumText style={styles.inputParagraph}>{paragraph}</MediumText> : null}
        </div>
        {children}
      </div>
      {footer}
    </div>
  </form>
);

export interface InputModalFooterProps {
  nextDisabled?: boolean;
  actions?: ModalAction[];
  footerStyle?: SerializedStyles;
  isLoading?: boolean;
}

export const InputModalFooter = ({
  nextDisabled,
  actions,
  footerStyle,
  isLoading,
}: InputModalFooterProps) => {
  const secondaryButton = actions?.length ? (
    <Button style={styles.backButton} onClick={actions[0].action} type={actions[0].type || "ghost"}>
      <ChevronLeft css={styles.chevron} color={colors.ICON} />
      <p>{actions[0].text}</p>
    </Button>
  ) : null;

  return (
    <div css={[styles.footerContainer, !actions && styles.noActionsFooter]}>
      {actions?.length ? (
        <div css={[styles.buttonRow, footerStyle]}>
          {actions[0].secondary
            ? secondaryButton
            : actions.length === 1 && (
                <Button
                  style={styles.singleButton}
                  type={actions[0].type || "primary"}
                  disabled={nextDisabled}
                  onClick={actions[0].action}
                  submit={actions[0]?.submit}
                  loading={isLoading}
                >
                  {actions[0].text}
                </Button>
              )}
          {actions.length >= 2 && (
            <>
              {secondaryButton}
              <Button
                disabled={nextDisabled}
                onClick={actions[1].action}
                type={actions[1].type || "primary"}
                submit={actions[1]?.submit}
                loading={isLoading}
              >
                {actions[1].text}
              </Button>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

// Vertically stacked full width buttons
export const StackedInputModalFooter = ({
  nextDisabled,
  actions,
  footerStyle,
  isLoading,
}: InputModalFooterProps) =>
  !actions?.length ? null : (
    <div css={[styles.stackedFooter, footerStyle]}>
      <Button
        style={styles.stackedPrimaryButton}
        type={actions[0].type || "primary"}
        onClick={actions[0].action}
        loading={isLoading}
        disabled={nextDisabled}
      >
        {actions[0].text}
      </Button>
      {actions.length >= 2 && !!actions[1].action ? (
        <Button
          style={styles.stackedSecondaryButton}
          type={actions[1].type || "ghost"}
          onClick={actions[1].action}
        >
          {actions[1].text}
        </Button>
      ) : (
        <div css={styles.stackedSpacer} />
      )}
    </div>
  );

export type InputModalContentProps = Omit<InputModalFormProps, "footer"> & InputModalFooterProps;

export const InputModalContent = ({
  nextDisabled,
  children,
  header,
  actions,
  paragraph,
  footerStyle,
  isLoading,
  onSubmit,
}: InputModalContentProps) => (
  <InputModalForm
    header={header}
    paragraph={paragraph}
    onSubmit={onSubmit}
    footer={
      <InputModalFooter
        nextDisabled={nextDisabled}
        actions={actions}
        footerStyle={footerStyle}
        isLoading={isLoading}
      />
    }
  >
    {children}
  </InputModalForm>
);
