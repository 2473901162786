import {
  ApplicationTaskType,
  UI_APPLICATION_STATUS_ORDER,
  enums,
  getNextApplicationStatus,
  types,
} from "@fraction/shared";
import { useState } from "react";
import fraction, { ChecklistApp } from "src/api/fraction";
import { useAuth } from "src/auth";
import { APPLICATION_KEY, useApplicationAuthed } from "src/hooks/useApplication";
import { useCachedQuery } from "src/hooks/useCache";
import { useMutation } from "src/lib";
import { selectChecklistApplication } from "src/selectors";

export function useChecklistForNextAppStage({
  id,
  fetchEnabled,
}: {
  id?: string;
  fetchEnabled?: boolean;
}) {
  const { data: app, isLoading } = useApplicationAuthed({
    id,
  });
  const status = app?.status ? getNextApplicationStatus(app.status, UI_APPLICATION_STATUS_ORDER) : undefined;
  const q = useChecklist({
    id,
    status: status || undefined,
    fetchEnabled,
  });
  return {
    ...q,
    isLoading: q.isLoading || isLoading,
  };
}

export function useChecklist({
  id: appId,
  status: dealStatus,
  fetchEnabled,
  initialData,
}: {
  id?: string;
  status?: enums.ApplicationStatus;
  fetchEnabled?: boolean;
  initialData?: types.ChecklistResult[];
}) {
  const { token } = useAuth();
  const [pendingTask, setPendingTask] = useState<
    { taskType: ApplicationTaskType; channel: string } | undefined
  >();

  const checklistQ = useCachedQuery<types.ChecklistResult[] | null, ChecklistApp>({
    behaviour: "fetch-only",
    deserialize: selectChecklistApplication,
    initialRefetch: true,
    initialData,
    cacheKey: [...APPLICATION_KEY, appId],
    queryKey: [...APPLICATION_KEY, "checklist", appId],
    enabled: !!token && !!appId && !!dealStatus && fetchEnabled !== false,
    queryFn: async () => {
      if (!dealStatus) {
        return null;
      }
      return (await fraction.getAppChecklist(appId!, dealStatus)) || null;
    },
    select: (data: types.ChecklistResult[] | ChecklistApp | null | undefined) => {
      return (
        (!data || !dealStatus ? null : "checklists" in data ? data.checklists?.[dealStatus] : data) || null
      );
    },
  });

  const initiateTask = useMutation({
    mutationFn: async (args: Parameters<typeof fraction.initiateTask>[0]) => {
      setPendingTask({ taskType: args.taskType, channel: args.channels[0] });
      await fraction.initiateTask(args);
      await checklistQ.refetch();
    },
    onSettled: () => {
      setPendingTask(undefined);
    },
  });

  return {
    ...checklistQ,
    initiateTask: initiateTask.mutateAsync,
    pendingTask,
    isPending: checklistQ.isLoading || initiateTask.isPending,
  };
}
