import * as React from "react";
import { Dispatch, ReactNode, Ref, SetStateAction, useCallback } from "react";
import { FormFlowPage, Touchable } from "src/components";
import FlowLayoutItem, { FlowLayoutItemContainer } from "src/components/FlowLayoutItem";
import { BoldText } from "src/components/v1";
import { ItemControl } from "src/hooks/useSubpages";
import { Plus2 } from "src/icons";
import { createStyles } from "src/styles";
import { v4 as uuid } from "uuid";

import { colors } from "@fraction/shared";

export interface FlowLayoutItemsOverviewProps<T extends object, EditContext> {
  onNext: () => void;
  onBack: () => void;
  onChange: Dispatch<SetStateAction<ItemControl<T> | undefined>>;
  nextDisabled?: boolean;
  nextLoading?: boolean;
  headerFormatter: (val: T) => string;
  subheaderFormatter?: (val: T) => string;
  descriptionFormatter: (val: T) => string;
  exampleHeader: string;
  exampleSubheader: string;
  exampleDescription: string;
  newItemTemplate: T;
  editContext?: EditContext;
  itemControl?: ItemControl<T & { id?: string }>;
  title: string;
  description: string;
  addItemButtonText: string;
  focusRef?: Ref<HTMLDivElement>;
  secondary?: boolean;
  // If it is "simple", then we don't do the __lastCommand stuff, we just modify the items directly
  simple?: boolean;
  scrollElementId?: string;
  skipButtonText?: string;
  children?: ReactNode;
}

function useFlowLayoutItems<T extends object, EditContext>(
  setItems: Dispatch<SetStateAction<ItemControl<T, EditContext> | undefined>>,
  prev: ItemControl<T, EditContext> | undefined,
  { newItemTemplate, editContext, simple }: { newItemTemplate: T; editContext: EditContext; simple?: boolean }
) {
  const handleAddItem = useCallback(() => {
    setItems({
      ...prev,
      __editing: prev?.items?.length || 0,
      __editContext: editContext,
      __existing: undefined,
      __target: undefined,
      __lastCommand: "add",
      // any new items should get an id as well
      items: [...(prev?.items || []), { id: uuid(), ...newItemTemplate }],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setItems, newItemTemplate, prev]);

  const handleDeleteItem = useCallback(
    (index: number) => {
      const clone = [...(prev?.items || [])];
      if (simple) {
        clone.splice(index, 1);
      }
      setItems({
        items: clone,
        __existing: undefined,
        __editing:
          prev?.__editing !== undefined && prev.__editing >= index ? prev.__editing - 1 : prev?.__editing,
        __target: index,
        __editContext: editContext,
        __lastCommand: "delete",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setItems, prev]
  );

  const handleEditItem = useCallback(
    (index: number) => {
      setItems({
        ...prev,
        items: prev?.items || [],
        __existing: prev?.items?.[index],
        __target: undefined,
        __editing: index,
        __editContext: editContext,
        __lastCommand: "edit",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setItems, prev]
  );

  return {
    handleAddItem,
    handleDeleteItem,
    handleEditItem,
  };
}

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 24,
  },
  plusButtonContainer: {
    borderRadius: 8,
    padding: 16,
    backgroundColor: colors.palette.GREEN,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  plusButtonContainerSecondary: {
    backgroundColor: colors.palette.YELLOW,
  },
  buttonText: {
    fontSize: 14,
    marginLeft: 16,
    textAlign: "left",
  },
  addContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  addWrapper: {
    "&:hover": {
      backgroundColor: colors.palette.GREEN_100,
    },
    paddingLeft: 50,
    paddingRight: 40,
  },
  addWrapperSecondary: {
    "&:hover": {
      backgroundColor: colors.palette.YELLOW_100,
    },
  },
  item: {
    marginRight: 10,
    marginBottom: 10,
  },
});

const FlowLayoutItemsOverview = <T extends object, EditContext>({
  title,
  description,
  onNext,
  onBack,
  onChange,
  nextDisabled,
  nextLoading,
  itemControl,
  newItemTemplate,
  editContext,
  headerFormatter,
  subheaderFormatter,
  descriptionFormatter,
  exampleHeader,
  exampleSubheader,
  exampleDescription,
  addItemButtonText,
  focusRef,
  secondary,
  simple,
  scrollElementId,
  skipButtonText = "Skip",
  children,
  ...props
}: FlowLayoutItemsOverviewProps<T, EditContext>) => {
  const { handleAddItem, handleDeleteItem, handleEditItem } = useFlowLayoutItems(onChange, itemControl, {
    newItemTemplate,
    editContext,
    simple,
  });

  const handleNext = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e?.preventDefault();
      onNext();
    },
    [onNext]
  );

  const hasAddedItems = itemControl?.items?.length;

  return (
    <FormFlowPage
      buttonText={hasAddedItems ? "Next" : skipButtonText}
      title={title}
      description={description}
      onBack={onBack}
      onNext={handleNext}
      nextDisabled={nextDisabled}
      nextLoading={nextLoading}
      scrollElementId={scrollElementId}
      overflowTextFallback={hasAddedItems ? "Next" : "Skip"}
      ref={focusRef}
      {...props}
    >
      <div css={styles.container}>
        {!itemControl?.items?.length && (
          <FlowLayoutItem
            secondary={secondary}
            key="empty-example"
            subheader={exampleSubheader}
            header={exampleHeader}
            description={exampleDescription}
            style={styles.item}
          />
        )}
        {itemControl?.items?.map((item, index) =>
          // If we are editing an item that does not exist before (i.e. creating a new item)
          // just show the "example" text until we are done
          index === itemControl?.__editing && !itemControl?.__existing ? (
            <FlowLayoutItem
              secondary={secondary}
              key="example"
              subheader={exampleSubheader}
              header={exampleHeader}
              description={exampleDescription}
              style={styles.item}
            />
          ) : (
            <FlowLayoutItem
              key={item?.id}
              secondary={secondary}
              subheader={subheaderFormatter?.(item) || ""}
              header={headerFormatter(item)}
              description={descriptionFormatter(item)}
              onClickDelete={handleDeleteItem}
              onClickEdit={handleEditItem}
              index={index}
              style={styles.item}
            />
          )
        )}
        <Touchable onClick={handleAddItem}>
          <FlowLayoutItemContainer
            secondary={secondary}
            outline
            style={[styles.addWrapper, secondary && styles.addWrapperSecondary]}
          >
            <div css={styles.addContainer}>
              <div css={[styles.plusButtonContainer, secondary && styles.plusButtonContainerSecondary]}>
                <Plus2 height={8} width={8} color="white" />
              </div>
              <BoldText style={styles.buttonText}>{addItemButtonText}</BoldText>
            </div>
          </FlowLayoutItemContainer>
        </Touchable>
      </div>
      {children}
    </FormFlowPage>
  );
};

export default React.memo(FlowLayoutItemsOverview);
