import { CalendarDays, FolderOpenDot } from "lucide-react";
import { RouterNavLink } from "src/components";
import { useApplicationsQuery } from "src/hooks/useApplication";

export const NavItems = ({ onClick }: { onClick?: () => any }) => {
  const { isLoading, data: closedApps } = useApplicationsQuery({
    accountType: "conveyancer",
    status: "closed",
  });

  return (
    <>
      <RouterNavLink onClick={onClick} loading={isLoading} icon={FolderOpenDot} to="/">
        Active deals
      </RouterNavLink>
      {isLoading || closedApps?.length ? (
        <RouterNavLink onClick={onClick} loading={isLoading} icon={CalendarDays} to="/closed">
          Closed deals
        </RouterNavLink>
      ) : null}
    </>
  );
};
