import { SyntheticEvent, memo, useEffect, useState } from "react";
import { Checkbox } from "src/components";
import TextLink from "src/components/TextLink";
import { LightText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface AppFlowFirstScreenAgreementProps {
  onChange: (allChecked: boolean) => void;
}

const styles = createStyles({
  checkbox: {
    marginBottom: "1em",
  },
  label: {
    fontSize: 14,
    textAlign: "left",
    color: colors.BLACK_TEXT,
    letterSpacing: "0.01em",
    lineHeight: "145%",
  },
});

const cancelBubble = (evt: SyntheticEvent) => {
  evt.stopPropagation();
};

const PrivacyPolicy = () => (
  <LightText style={styles.label}>
    By checking this box, I hereby certify that I have read and agree to Fraction’s{" "}
    <TextLink underlined href="https://www.fraction.com/usa/privacy-policy" onClick={cancelBubble}>
      Privacy Policy
    </TextLink>{" "}
  </LightText>
);

const ElectronicCommunicationsPolicy = () => (
  <LightText style={styles.label}>
    By checking this box, I hereby certify that I have read and agree to Fraction’s{" "}
    <TextLink
      underlined
      href="https://www.fraction.com/usa/electronic-communications-policy"
      onClick={cancelBubble}
    >
      Electronic Communications Policy
    </TextLink>{" "}
    and consent to receive all legally required notices, disclosures and other communications from Fraction
    electronically and not on paper.
  </LightText>
);

const AppFlowFirstScreenAgreement = ({ onChange }: AppFlowFirstScreenAgreementProps) => {
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
  const [ecpChecked, setEcpChecked] = useState(false);

  useEffect(() => {
    onChange(privacyPolicyChecked && ecpChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privacyPolicyChecked, ecpChecked]);

  return (
    <>
      <Checkbox checked={privacyPolicyChecked} onChange={setPrivacyPolicyChecked} style={styles.checkbox}>
        <PrivacyPolicy />
      </Checkbox>
      <Checkbox checked={ecpChecked} onChange={setEcpChecked} style={styles.checkbox}>
        <ElectronicCommunicationsPolicy />
      </Checkbox>
    </>
  );
};

export default memo(AppFlowFirstScreenAgreement);
