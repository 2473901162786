import cookie from "cookie";
import _ from "lodash";
import * as qs from "qs";
import { useEffect } from "react";
import { Logger } from "src/log";
import { saveCookie } from "src/utilities/tracking";

const LOGGER = new Logger("useCookieTracker");

export default function useCookieTracker() {
  useEffect(() => {
    LOGGER.log(`Loaded useCookieTracker with window.location: ${JSON.stringify(window.location)}`);
    const existing = cookie.parse(document.cookie);
    const urlParams = existing._url_params ? JSON.parse(existing._url_params) : {};
    const newUrlParams = _.omit(qs.parse(window.location.search.slice(1)), "token");
    LOGGER.log("Grabbed new params", {
      existing,
      urlParams,
      newUrlParams,
    });
    for (const [key, value] of Object.entries(newUrlParams)) {
      urlParams[key] = value;
    }
    if (urlParams) {
      LOGGER.log("Saving _url_params:", urlParams);
      saveCookie("_url_params", urlParams);
    }
    if (!existing.initial_href) {
      LOGGER.log("Saving initial_href:", window.location.href);
      saveCookie("initial_href", window.location.href);
    }
    if (!existing.initial_referrer && document.referrer) {
      LOGGER.log("Saving initial_referrer:", document.referrer);
      saveCookie("initial_referrer", document.referrer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);
}
