import { SerializedStyles } from "@emotion/react";
import { ReactElement, ReactNode, cloneElement, useRef } from "react";
import * as React from "react";
import { StickyButtonContainer } from "src/components";
import { Button } from "src/components/v1";
import { useMediaFallback, useMobileHeightAdjustment } from "src/hooks";
import { createStyles } from "src/styles";
import { BlockFocus } from "src/utilities/react";
import { v4 as uuid } from "uuid";

export const styles = createStyles({
  page: {
    maxWidth: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    // This fixes the issue in Safari where the height doesn't expand
    // when the children are taller than the min-height
    flex: "0 0 auto",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    maxWidth: 620,
    "@media(max-width: 610px)": {
      maxWidth: "100%",
    },
  },
  centeredForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 400,
    "@media(max-width: 610px)": {
      maxWidth: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 620,
    "@media(max-width: 610px)": {
      maxWidth: "100%",
    },
  },
  stickyContainer: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  centeredInnerStickyContainer: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  centeredButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  centeredButton: {
    width: "100%",
    maxWidth: 205,
  },
  nextButton: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
});

export interface FormFlowContainerProps extends Omit<React.HTMLAttributes<HTMLElement>, "style"> {
  style?: SerializedStyles | Array<SerializedStyles | undefined>;
  formStyle?: SerializedStyles;
  buttonContainerStyle?: SerializedStyles;
  centered?: boolean;
  stickyAboveButton?: ReactNode;
  buttonText?: string;
  overflowTextFallback?: string;
  nextDisabled?: boolean;
  nextLoading?: boolean;
  onNext: (() => void) | ((event: React.ChangeEvent<HTMLFormElement>) => void);
  onBack?: () => void;
  scrollElementId?: string;
  header?: ReactNode;
  blockFocus?: BlockFocus;
  isStickyDesktopFooter?: boolean;
  button?: ReactElement<any>;
}

const FormFlowContainer = React.forwardRef<HTMLDivElement, FormFlowContainerProps>(
  (
    {
      buttonText = "Next",
      scrollElementId,
      centered,
      style,
      formStyle,
      buttonContainerStyle,
      nextDisabled,
      onNext,
      onBack,
      children,
      header,
      overflowTextFallback,
      isStickyDesktopFooter,
      nextLoading,
      button,
      stickyAboveButton,
    }: FormFlowContainerProps,
    ref
  ) => {
    const id = useRef(uuid()).current;
    const minHeight = useMobileHeightAdjustment();

    const text = useMediaFallback(850, buttonText, overflowTextFallback || buttonText);

    return (
      <div ref={ref} css={[styles.page, { minHeight }, style]}>
        <form id={id} css={[styles.form, centered && styles.centeredForm, formStyle]} onSubmit={onNext}>
          {header}
          {children}
          <StickyButtonContainer
            scrollElement={`#${scrollElementId}`}
            boundaryElement={`#${id}`}
            isStickyDesktopFooter={isStickyDesktopFooter}
          >
            <div css={styles.stickyContainer}>
              <div css={styles.buttonContainer}>{stickyAboveButton}</div>
              <div css={styles.centeredInnerStickyContainer}>
                <div
                  css={[
                    centered ? styles.centeredButtonContainer : styles.buttonContainer,
                    buttonContainerStyle,
                  ]}
                >
                  {onBack ? (
                    <Button align="left" type="ghost" narrow onClick={onBack}>
                      &#8592; Back
                    </Button>
                  ) : (
                    <div />
                  )}
                  {button ? (
                    cloneElement(button, {
                      disabled: nextDisabled || nextLoading,
                      style: centered ? styles.centeredButton : undefined,
                      narrow: true,
                      submit: true,
                      loading: nextLoading,
                      textCss: styles.nextButton,
                    })
                  ) : (
                    <Button
                      disabled={nextDisabled || nextLoading}
                      style={centered ? styles.centeredButton : undefined}
                      narrow
                      submit
                      loading={nextLoading}
                      textCss={styles.nextButton}
                    >
                      {text}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </StickyButtonContainer>
        </form>
      </div>
    );
  }
);

export default FormFlowContainer;
