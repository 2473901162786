import retry from "@fullstax/p-retry";
import { lazy } from "react";

const PortfolioMap = lazy(() => retry(() => import("../../components/PortfolioMap")));

export function PortfolioMapScreen() {
  return (
    <div className="h-full w-full">
      <PortfolioMap />
    </div>
  );
}
