import {
  SyntheticApplicationStatus,
  UI_APPLICATION_STATUS_ORDER,
  enums,
  formatters,
  getNextApplicationStatus,
  isStatusAfterStatus,
  selectors,
} from "@fraction/shared";
import { formatDistanceToNowStrict } from "date-fns";
import _ from "lodash";
import { Check } from "lucide-react";
import { ReactNode, forwardRef } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { getCheckName } from "src/apps/LOS/utils";
import { RawAppStatusBadge } from "src/components/AppStatusBadge";
import Tooltip from "src/components/Tooltip";
import { Badge } from "src/components/ui/badge";
import { useApplicationLocal } from "src/hooks/useApplication";
import { cn } from "src/utilities/shadcnUtils";
import { Bookmark } from "./Bookmark";

export interface PipelineDealCardProps {
  id: string;
  style?: any;
  statusOverride?: enums.ApplicationStatus;
  syntheticStatusOverride?: SyntheticApplicationStatus;
  setShowModal?: (opts: { id: string; status: enums.ApplicationStatus }) => void;
  children?: ReactNode;
  className?: string;
  showStatus?: boolean;
}

export const PipelineDealCard = forwardRef<HTMLDivElement, PipelineDealCardProps>(
  ({ id, statusOverride, syntheticStatusOverride, setShowModal, className, showStatus, ...props }, ref) => {
    const { data: app, isLoading } = useApplicationLocal({ id });

    if (isLoading) {
      return <Skeleton width="100%" className="h-32" />;
    }
    if (!app) {
      return null;
    }

    const status = statusOverride || app?.status;
    const overridenSyntheticStatus =
      syntheticStatusOverride || app?.syntheticStatus || statusOverride || app?.status;
    const nextStatus = statusOverride
      ? statusOverride
      : status
      ? getNextApplicationStatus(status, UI_APPLICATION_STATUS_ORDER)
      : undefined;
    const nextStatusChecklist = nextStatus ? app?.checklists?.[nextStatus] : undefined;

    const closeDate =
      app.status === enums.ApplicationStatus.FUNDED
        ? app?.closeDate
        : app?.advanceDate || app?.estimatedCloseDate;

    return (
      <Link to={`/app/${id}`}>
        <div
          ref={ref}
          className={cn("bg-white p-3 px-4 rounded-md border-gray-300 border relative", className)}
          {...props}
        >
          <button
            className="float-right"
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              if (nextStatus) {
                setShowModal?.({ id, status: nextStatus });
              }
            }}
          >
            {showStatus ? (
              <RawAppStatusBadge status={overridenSyntheticStatus} />
            ) : nextStatusChecklist?.length && nextStatusChecklist?.filter((check) => !check.ok)?.length ? (
              <Tooltip
                text={nextStatusChecklist
                  ?.filter((check) => !check.ok)
                  .map((check) => _.startCase(getCheckName(check)))
                  .join(", ")}
              >
                <Badge variant={statusOverride ? "destructive" : "secondary"} className="rounded-xl flex">
                  {nextStatusChecklist?.filter((check) => !check.ok)?.length}
                </Badge>
              </Tooltip>
            ) : (
              <Badge variant="success" className="rounded-xl float-right">
                <Check className="w-4 h-4 text-white stroke-2" />
              </Badge>
            )}
          </button>

          <p className="font-bold">{formatters.application.applicantNames(app)}</p>

          <p>{formatters.property.shortFormattedAddress(app.property)}</p>
          <div className="flex flex-row justify-between items-center">
            <p className="text-base">
              {formatters.number.getCurrencyFromNumber(selectors.application.selectApplicationAmount(app), {
                digits: 0,
              })}
            </p>
          </div>

          {app.status &&
          closeDate &&
          isStatusAfterStatus(app.status, enums.ApplicationStatus.CONVEYANCER_ENGAGED, true) ? (
            <Badge
              className={cn(
                "h-5 rounded-full text-xs bg-gray-400 text-black",
                closeDate < new Date() && "bg-red-350"
              )}
            >
              {app.status === enums.ApplicationStatus.FUNDED
                ? "Closed:"
                : !app.advanceDate
                ? "Est. Closing"
                : " Closing:"}{" "}
              {formatters.date.formatDate(closeDate)} (
              {app.status === enums.ApplicationStatus.FUNDED
                ? formatDistanceToNowStrict(closeDate, { addSuffix: true })
                : formatters.date.formatDistanceToNowStrictCalendarDays(closeDate, { addSuffix: true })}
              )
            </Badge>
          ) : null}

          <hr className="w-full border border-gray-300 my-2" />
          <div className="gap-1 flex-wrap flex flex-row justify-between">
            <div className="gap-1 flex-wrap flex flex-row w-[80%]">
              {app.brokers?.map((broker) => (
                <Badge key={broker.id} className="rounded-xl">
                  {formatters.user.userName(broker.user)}
                </Badge>
              ))}
            </div>
            {app.property?.administrativeArea ? (
              <Badge className="h-5 rounded-full text-xs bg-gray-400">
                {selectors.property.convertToISOAdminArea(app.property?.administrativeArea)}
              </Badge>
            ) : null}
          </div>

          {props.children ? (
            <>
              <hr className="w-full border border-gray-300 my-2" />
              {props.children}
            </>
          ) : null}
          {app?.dealPurpose === enums.DealPurpose.PURCHASE ? (
            <Bookmark title="Deal is a purchase" className="absolute top-[-4px] left-2 text-red w-5" />
          ) : null}
        </div>
      </Link>
    );
  }
);
