import { ApplicationOverview } from "src/apps/PreFundedDashboard/components/ApplicationOverview";
import config from "src/apps/PreFundedDashboard/config";
import { DashboardPage } from "src/components";
import { DocumentChecklist } from "src/components/DocumentChecklist";
import { FundingProgress } from "src/components/FundingProgress";
import { TaskChecklist } from "src/components/TaskChecklist";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { useApplicationsQuery } from "src/hooks/useApplication";

export const PrefundedDashboardOverview = () => {
  const { data: apps, isLoading } = useApplicationsQuery({ accountType: "applicant", status: "active" });
  const app = apps?.[0];

  useDocumentTitle("Loan overview | Fraction");

  return (
    <DashboardPage
      className="gap-2"
      heading={config.pages.overview.heading}
      subHeading={config.pages.overview.subheading}
    >
      <FundingProgress rejectReason={app?.declineReason} status={app?.status} />
      <ApplicationOverview loading={isLoading} app={app || undefined} />
      <DocumentChecklist className="max-w-3xl" applicationId={app?.id} />
      <TaskChecklist applicationId={app?.id} />
    </DashboardPage>
  );
};
