import fraction from "src/api/fraction";

import {
  InvalidTokenError,
  UserAuthenticationError,
  entities,
  plainToInstance,
  utilities,
} from "@fraction/shared";

export const STORAGE = {
  getToken: () => {
    const token = window.localStorage.getItem("token");
    if (token) {
      return JSON.parse(token);
    }
  },
  setToken: (token: string) => window.localStorage.setItem("token", JSON.stringify(token)),
  clearToken: () => window.localStorage.removeItem("token"),
  getUser: () => {
    const user = window.localStorage.getItem("user");
    if (user) {
      return plainToInstance(entities.User, JSON.parse(user) as object);
    }
  },
  setUser: (user: entities.UserT) => window.localStorage.setItem("user", JSON.stringify(user)),
  clearUser: () => window.localStorage.removeItem("user"),
};

export function handleToken(token: string) {
  if (!token) {
    throw new InvalidTokenError("No token provided");
  }
  try {
    const user = utilities.auth.decodeToken(token) as entities.User;
    STORAGE.setToken(token);
    fraction.setBearerToken(token);
    return user;
  } catch (err) {
    throw new InvalidTokenError("Unable to decode");
  }
}

export async function loadUser(token: string) {
  if (!token) {
    return null;
  }

  try {
    const user = await fraction.getUser(token);
    fraction.setBearerToken(token);
    return user;
  } catch (err) {
    // failed to fetch user with the provided token so clear from storage
    // Note: In the future we may want to include a retry mechanism in the case of a network error
    STORAGE.clearToken();
    throw err;
  }
}

export async function loginFromToken(token: string) {
  handleToken(token);
  // login endpoint does not return the full User entity, so after successful login, immediately fetch the user.
  const user = await fraction.getUser(token);

  if (!user) {
    throw new UserAuthenticationError("Unable to login");
  }
  return user;
}

export async function login(data: entities.User) {
  const token = await fraction.login(data);
  return { user: await loginFromToken(token), token };
}

export async function logout() {
  STORAGE.clearToken();
  fraction.clearBearerToken();
}
