import { colors } from "@fraction/shared";
import _ from "lodash";
import { Loader, User2Icon } from "lucide-react";
import { useAuth } from "src/auth";
import { AccountDropdownButton, MonoButton } from "src/components";
import { SemiBoldText } from "src/components/v1";
import { useAccountViewPreference } from "src/hooks/useAccountViewPreference";
import { Logger } from "src/log";
import { createStyles } from "src/styles";
import Dropdown from "../Dropdown";

const logger = new Logger("components.AccountDropdown");

const styles = createStyles(({ spacing, colors, fontSize }) => ({
  dropDownOverride: {
    transform: "translate(-26px, 10px)",
  },
  dropdownButton: {
    marginLeft: "auto",
  },
  dropdownContent: {
    minWidth: 320,
  },
  dropdownHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: `${spacing[2]}px ${spacing[3]}px`,
    borderBottom: `1px solid ${colors.darkgrey200}`,
  },
  dropdownHeaderText: {
    lineHeight: "27px",
  },
  dropdownSubheaderText: {
    fontSize: fontSize.sm,
  },
  dropdownFooter: {
    padding: spacing[3],
  },
}));

const selectInitials = (firstName?: string, lastName?: string) => {
  if (!firstName || !lastName) {
    logger.warn("Missing user's first or last name");
    return <User2Icon color={colors.palette.GREEN_800} />;
  }
  return firstName[0].toUpperCase() + lastName[0].toUpperCase();
};

export const AccountDropdown = () => {
  const { logout, isLoggingOut, user } = useAuth();
  const { choices, preference, setPreference, isLoading } = useAccountViewPreference();

  const firstName = user?.firstName;
  const lastName = user?.lastName;

  return (
    <Dropdown
      trigger={<AccountDropdownButton initials={selectInitials(firstName, lastName)} />}
      align="right"
      style={styles.dropDownOverride}
      triggerStyle={styles.dropdownButton}
    >
      <div className="max-w-xl" css={styles.dropdownContent}>
        {firstName ? (
          <div css={styles.dropdownHeader}>
            <SemiBoldText style={styles.dropdownHeaderText}>Hello, {firstName}</SemiBoldText>
            {isLoading ? <Loader height={20} className="ml-2 text-gray-600 animate-spin" /> : null}
          </div>
        ) : null}
        {choices?.length > 1 ? (
          <div className="flex flex-row flex-wrap gap-x-2 gap-y-1 mx-6 mb-2">
            {choices
              .filter((choice) => choice !== preference)
              .map((choice) => (
                <MonoButton
                  fullWidth
                  key={choice}
                  onClick={() => setPreference(choice)}
                  loading={isLoggingOut}
                  variant="outlined"
                  size="small"
                >
                  View as {choice}
                </MonoButton>
              ))}
          </div>
        ) : null}
        <div className="mx-6 mb-3">
          <MonoButton size="small" fullWidth onClick={logout} loading={isLoggingOut} variant="standard">
            Log out
          </MonoButton>
        </div>
      </div>
    </Dropdown>
  );
};
