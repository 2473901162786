import { SerializedStyles } from "@emotion/react";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "src/auth";
import { Header } from "src/components";
import { Button } from "src/components/v1";
import { Logo } from "src/images";
import { createStyles } from "src/styles";

import { colors as colorsImport } from "@fraction/shared";

const styles = createStyles(({ colors, spacing }) => ({
  helpText: {
    color: colors.grey500,
    fontSize: 16,
    paddingLeft: 8,
    "@media(max-width: 600px)": {
      display: "none",
    },
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  needHelpContainer: {
    display: "flex",
    alignItems: "center",
  },
  actions: {
    display: "flex",
  },
  logoutButton: {
    marginLeft: 24,
  },

  //
  // New version styling
  //
  container: {
    minHeight: spacing[8],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoBox: {
    width: 290,
    borderStyle: "solid",
    borderRightWidth: [0, 0, 1],
    borderBottomWidth: [1, 1, 0],
    borderColor: colors.darkgrey200,
    paddingLeft: [spacing[3], 44],
    minHeight: spacing[8],
    display: "flex",
    alignItems: "center",
  },
  mainBar: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    alignItems: "center",
    minHeight: spacing[8],
    borderBottom: "solid 1px",
    borderColor: colors.darkgrey200,
  },
}));

/**
 * @deprecated to be removed - use the default export instead
 */
export const OldDashboardHeader = () => {
  const { logout, isLoggingOut, isAuthenticated } = useAuth();

  return (
    <Header>
      <div css={styles.headerContainer}>
        <Link to="/">
          <Logo width={120} height={27} color={colorsImport.palette.GREEN} />
        </Link>
        <div css={styles.actions}>
          {isAuthenticated && (
            <Button
              type="inversePrimary"
              narrow
              style={styles.logoutButton}
              // @ts-ignore
              onClick={logout}
              loading={isLoggingOut}
            >
              Logout
            </Button>
          )}
        </div>
      </div>
    </Header>
  );
};

const DashboardHeader = ({
  children,
  logoBoxStyle,
}: {
  children: ReactNode;
  logoBoxStyle?: SerializedStyles;
}) => {
  return (
    <div css={styles.container}>
      <div css={[styles.logoBox, logoBoxStyle]}>
        <Link to="/">
          <Logo width={118} height={22} color={colorsImport.palette.GREEN} />
        </Link>
      </div>
      <div css={styles.mainBar}>{children}</div>
    </div>
  );
};

export default React.memo(DashboardHeader);
