import { SerializedStyles } from "@emotion/react";
import { useElementScroll } from "framer-motion";
import { RefObject, createRef, useCallback, useMemo, useState } from "react";
import { FlowNavigationBar } from "src/components";
import { useWindowSize } from "src/hooks";
import { WrappedPage } from "src/hooks/useFlowLayoutState";
import useQueueAfterScroll from "src/hooks/useQueueAfterScroll";
import { focusWhenPossible } from "src/utilities/react";

import useFlowNavScroll, { NavSection } from "./useFlowNavScroll";

export interface ElementFlowNavigationBarWrapperProps {
  scrollElement: RefObject<HTMLElement>;
  wrappedPages: WrappedPage[];
  navSections: NavSection[];
  isMobile?: boolean;
  handleClickNavItem?: (idx?: number) => void;
  style?: SerializedStyles;
}

const ElementFlowNavigationBarWrapper = ({
  scrollElement,
  wrappedPages,
  navSections,
  handleClickNavItem: handleClickNavItemProp,
  ...props
}: ElementFlowNavigationBarWrapperProps) => {
  const [queuedHeaderScrollRef, setQueuedHeaderScrollRef] = useState<RefObject<HTMLDivElement> | null>(null);

  const { scrollY } = useElementScroll(scrollElement);
  const { height } = useWindowSize();

  const pageRefs: RefObject<HTMLDivElement>[] = useMemo(
    () => wrappedPages.map(({ focusRef }) => focusRef),
    [wrappedPages]
  );

  const headerRefs: RefObject<HTMLDivElement>[] = useMemo(
    () => navSections.map(() => createRef()),
    [navSections]
  );

  const scrollToHeader = useCallback(
    (navIdx: number) => {
      if (headerRefs[navIdx]) {
        setQueuedHeaderScrollRef(headerRefs[navIdx]);
      }
    },
    [headerRefs]
  );

  useQueueAfterScroll({
    scrollElement,
    enabled: queuedHeaderScrollRef !== null,
    callback: () => {
      if (queuedHeaderScrollRef !== null) {
        focusWhenPossible(queuedHeaderScrollRef);
        setQueuedHeaderScrollRef(null);
      }
    },
  });

  const { progress, activeSection, backtrackSection, handleClickNavItem } = useFlowNavScroll({
    pageRefs,
    navSections,
    scrollY,
    scrollElement,
    pageHeight: height,
    onIntersection: scrollToHeader,
  });

  const wrappedNavSections = useMemo(
    () =>
      navSections.map((navItem, idx) => ({
        ...navItem,
        headerRef: headerRefs[idx],
        onClick: (navIdx: number) => {
          handleClickNavItem(navIdx);
          handleClickNavItemProp?.(navIdx);
        },
      })),
    [navSections, handleClickNavItem, handleClickNavItemProp, headerRefs]
  );

  return (
    <FlowNavigationBar
      activeStep={activeSection}
      backtrackStep={backtrackSection}
      navSections={wrappedNavSections}
      progress={progress}
      {...props}
    />
  );
};

export default ElementFlowNavigationBarWrapper;
