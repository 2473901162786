import { format } from "date-fns";
import { TransactionHistoryRecord } from "src/apps/PostFundedDashboard/queries";

import { calculators, formatters, utilities } from "@fraction/shared";

export const selectTransactionForViewing = ({
  type,
  amount,
  date,
  status,
  receivingAccount,
  direction,
}: TransactionHistoryRecord) => ({
  type: {
    status,
    type,
  },
  amount: formatters.number.getCurrencyFromNumber(
    utilities.transaction.getSignedAmountFromTransactionDirection({ amount, direction }),
    { explicitSign: true }
  ),
  receivingAccount,
  date: getDate({ date: new Date(date), type }),
});

const getDate = ({ type, date }: Pick<TransactionHistoryRecord, "date" | "type">) => {
  let formatStr = "";
  if (calculators.transaction.TYPES_INTEREST.includes(type)) {
    formatStr = "MMMM y";
  } else {
    formatStr = "MMMM d, y";
  }

  return format(date, formatStr);
};
