import { BrowserRouter as Router } from "react-router-dom";
import Routes from "src/routes/Routes";
import GlobalStyles from "src/styles/GlobalStyles";

const App = () => (
  <Router>
    <GlobalStyles />
    <Routes />
  </Router>
);

export default App;
