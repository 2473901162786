import { CountrySelect } from "src/apps/PortfolioDashboard/components/Filter/CountrySelect";
import { LoanStatusSelect } from "src/apps/PortfolioDashboard/components/Filter/LoanStatusSelect";
import { PortfolioSelect } from "src/apps/PortfolioDashboard/components/Filter/PortfolioSelect";
import { ProductTypeSelect } from "src/apps/PortfolioDashboard/components/Filter/ProductTypeSelect";
import { TermLengthSelect } from "src/apps/PortfolioDashboard/components/Filter/TermLengthSelect";
import usePortfolioDashStore from "src/apps/PortfolioDashboard/store";
import { Button } from "src/components/ui/button";
import { DateRange } from "src/components/ui/date-range";
import { Label } from "src/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";

import { enums } from "@fraction/shared";
import { ListFilter } from "lucide-react";
import { cn } from "src/utilities/shadcnUtils";

export interface Filter {
  label: string;
  value?: string;
}

export function FilterPopover({
  className,
}: {
  className?: string;
}) {
  const {
    portfolioFilters,
    startDateFilter,
    endDateFilter,
    startLoanActivityDateFilter,
    endLoanActivityDateFilter,
    countryFilter,
    termLengthFilter,
    productTypeFilter,
    loanStatusFilter,
    setStartDateFilter,
    setEndDateFilter,
    setStartLoanActivityDateFilter,
    setEndLoanActivityDateFilter,
    setPortfolioFilters,
    setCountryFilter,
    setTermLengthFilter,
    setProductTypeFilter,
    setLoanStatusFilter,
  } = usePortfolioDashStore();

  const count = [
    portfolioFilters,
    startDateFilter,
    endDateFilter,
    startLoanActivityDateFilter,
    endLoanActivityDateFilter,
    countryFilter,
    termLengthFilter,
    productTypeFilter,
    loanStatusFilter !== enums.LoanStatus.ACTIVE ? loanStatusFilter : null,
  ].filter(Boolean).length;

  return (
    <div className={cn("flex flex-row items-center", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="ghost" className="p-2 h-fit relative">
            <ListFilter className="h-5 w-5 text-gray-800" />
            {count ? (
              <div className="absolute top-0.5 right-0.5 bg-green-100 h-3.5 w-3.5 rounded-full flex items-center justify-center">
                <span className="text-[0.6rem] text-center text-gray-800 font-semibold">{count}</span>
              </div>
            ) : null}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-100 max-w-[100vw]">
          <div className="grid gap-4">
            <div className="space-y-2">
              <h4 className="font-medium leading-none">Filters</h4>
              <p className="text-sm text-muted-foreground">Filter the loans to view.</p>
            </div>
            <div className="grid gap-2">
              <div className="grid grid-cols-3 items-center gap-4">
                <Label title="Filter by the portfolio the loan is a part of" htmlFor="portfolio">
                  Portfolio(s)
                </Label>
                <PortfolioSelect values={portfolioFilters} onValueChange={setPortfolioFilters} />
              </div>
              <div className="grid grid-cols-3 items-center gap-4">
                <Label title="Filter loans by country" htmlFor="portfolio">
                  Country
                </Label>
                <CountrySelect value={countryFilter} onValueChange={setCountryFilter} />
              </div>
              <div className="grid grid-cols-3 items-center gap-4">
                <Label title="Filter by loans that have been funded in this date range." htmlFor="dates">
                  Funding Dates
                </Label>
                <DateRange
                  className="col-span-2 h-8"
                  setDateTo={setEndDateFilter}
                  setDateFrom={setStartDateFilter}
                  dateTo={endDateFilter}
                  dateFrom={startDateFilter}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-4">
                <Label
                  title="Filter by loans that have either been funded or paid off in this date range."
                  htmlFor="dates"
                >
                  Loan Activity Dates
                </Label>
                <DateRange
                  className="col-span-2 h-8"
                  setDateTo={setEndLoanActivityDateFilter}
                  setDateFrom={setStartLoanActivityDateFilter}
                  dateTo={endLoanActivityDateFilter}
                  dateFrom={startLoanActivityDateFilter}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-4">
                <Label title="Filter by type of loan" htmlFor="portfolio">
                  Product Type
                </Label>
                <ProductTypeSelect value={productTypeFilter} onValueChange={setProductTypeFilter} />
              </div>
              <div className="grid grid-cols-3 items-center gap-4">
                <Label title="Filter by term length" htmlFor="portfolio">
                  Term Length
                </Label>
                <TermLengthSelect value={termLengthFilter} onValueChange={setTermLengthFilter} />
              </div>
              <div className="grid grid-cols-3 items-center gap-4">
                <Label title="Filter by active or paid out loans" htmlFor="portfolio">
                  Loan Status
                </Label>
                <LoanStatusSelect value={loanStatusFilter} onValueChange={setLoanStatusFilter} />
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
