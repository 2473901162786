import { ReactNode } from "react";
import StickyEl from "react-sticky-el";
import { useVisualViewport, useWindowSize } from "src/hooks";
import getDeviceType from "src/utilities/interface/getDeviceType";

/**
 * Only be sticky in mobile views, if virtual keyboard is closed, or if isStickyDesktopFooter prop is passed.
 */
const Sticky = ({
  children,
  style,
  isStickyDesktopFooter,
  ...props
}: StickyEl.Props & { isStickyDesktopFooter?: boolean; children?: ReactNode }) => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { computer, tablet } = getDeviceType(windowWidth);
  const { height: visualViewportHeight } = useVisualViewport();

  /**
   * There is no api for directly querying whether a virtual keyboard is open,
   * however making the assumption that if the visual viewport is less than 80% of
   * the total window height it is most likely open.
   */
  const isVirtualKeyboardOpen = visualViewportHeight / windowHeight < 0.8;

  if (isVirtualKeyboardOpen || (!isStickyDesktopFooter && (computer || tablet))) {
    return <div style={style}>{children}</div>;
  }
  return (
    // @ts-ignore
    <StickyEl style={style} {...props}>
      {children}
    </StickyEl>
  );
};

export default Sticky;
