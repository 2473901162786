import { RefObject } from "react";
import { ElementFlowNavigationBarWrapper, XButton } from "src/components";
import { useWindowSize } from "src/hooks";
import { WrappedPage } from "src/hooks/useFlowLayoutState";
import { Toggle } from "src/hooks/useToggle";
import { createStyles } from "src/styles";

import { NavSection } from "../FlowNavigationBar/useFlowNavScroll";

export interface FlowLayoutNavProps {
  scrollElement: RefObject<HTMLElement>;
  wrappedPages: WrappedPage[];
  navSections?: NavSection[];
  navToggle: Toggle;
  onClose?: () => void;
}

const styles = createStyles({
  navPosition: {
    position: "fixed",
    left: "clamp(10px, 5vw, 90px)",
    minHeight: "calc(100% - 64px)", // full height - header padding
  },
  navContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "90vh",
  },
  scrollFade: {
    position: "relative",
    "&::before": {
      zIndex: 1,
      content: '" "',
      position: "absolute",
      top: 0,
      width: "100%",
      height: "50px",
      background: "linear-gradient(white, rgba(255,255,255,0))",
    },
    "&::after": {
      zIndex: 1,
      content: '" "',
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: "50px",
      background: "linear-gradient(rgba(255,255,255,0), white)",
    },
  },
  navBar: {
    overflowX: "hidden",
    overflowY: "auto",
    paddingTop: 100,
    paddingBottom: 100,
    width: 150,
    scrollbarWidth: "none",
  },
  closeButtonPosition: {
    position: "fixed",
    top: 60,
    right: 120,
  },
  xWrapper: {
    zIndex: 1,
    boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "50%",
  },
});

const MOBILE_STYLES = createStyles({
  closeButtonPosition: {
    position: "fixed",
    top: 32,
    right: 32,
  },
});

const MobileFlowLayoutNav = ({ onClose }: FlowLayoutNavProps) =>
  onClose ? (
    <div css={[MOBILE_STYLES.closeButtonPosition, styles.xWrapper]}>
      <XButton onClick={onClose} />
    </div>
  ) : null;

const DesktopFlowLayoutNav = ({ scrollElement, onClose, wrappedPages, navSections }: FlowLayoutNavProps) => (
  <div css={styles.navPosition}>
    {onClose ? (
      <div css={[styles.closeButtonPosition, styles.xWrapper]}>
        <XButton onClick={onClose} />
      </div>
    ) : null}
    {navSections ? (
      <div css={[styles.navContainer, styles.scrollFade]}>
        <ElementFlowNavigationBarWrapper
          scrollElement={scrollElement}
          wrappedPages={wrappedPages}
          navSections={navSections}
          style={styles.navBar}
        />
      </div>
    ) : null}
  </div>
);

const FlowLayoutNav = (props: FlowLayoutNavProps) => {
  const { width } = useWindowSize();
  return width >= 1100 ? <DesktopFlowLayoutNav {...props} /> : <MobileFlowLayoutNav {...props} />;
};

export default FlowLayoutNav;
