import { datadogLogs } from "@datadog/browser-logs";
import { captureException, captureMessage } from "src/api/sentry";
import config from "src/config";

import { BaseLogger, LogLevel } from "@fraction/shared";

const DATADOG_ENABLED_STAGES = ["dev", "prod"];
const DATADOG_ENABLED = DATADOG_ENABLED_STAGES.includes(config.stage);

if (DATADOG_ENABLED) {
  datadogLogs.init({
    clientToken: config.datadogClientToken,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sampleRate: 100,
    env: config.stage,
  });
}

export class Logger extends BaseLogger {
  private datadogEnabled: boolean;

  public constructor(tag: string, level: LogLevel = config.logLevel, group?: string) {
    super(tag, level, group);
    this.datadogEnabled = DATADOG_ENABLED;
  }

  protected logAction(message_: any, ...args: any[]): void {
    if (this.datadogEnabled) {
      const message = this.formatMessage(message_, ...args);
      datadogLogs.logger.info(message);
    } else {
      super.log(message_, ...args);
    }
  }

  public log(message_: any, ...args: any[]): void {
    if (this.level > LogLevel.LOG) {
      // if we aren't logging it to the console, we should at least try to log it to datadog
      this.logAction(message_, ...args);
    } else {
      super.log(message_, ...args);
    }
  }

  protected warnAction(message_: string, ...args: any[]): void {
    const message = this.formatMessage(message_, ...args);
    captureMessage(message);
    if (this.datadogEnabled) {
      datadogLogs.logger.warn(message, {});
    }
  }

  public warn(message_: any, ...args: any[]): void {
    if (this.level > LogLevel.WARN) {
      // if we aren't logging it to the console, we should at least try to log it to datadog
      this.warnAction(message_, ...args);
    } else {
      super.warn(message_, ...args);
    }
  }

  protected exceptionAction(err: Error, message_: string, ...args: any[]): void {
    captureException(err);
    const message = this.formatMessage(message_, ...args);
    if (this.datadogEnabled) {
      datadogLogs.logger.error(message, { error: err });
    }
  }

  public exception(err: Error, message_: any, ...args: any[]): void {
    if (this.level > LogLevel.EXCEPTION) {
      // if we aren't logging it to the console, we should at least try to log it to datadog
      this.exceptionAction(err, message_, ...args);
    } else {
      super.warn(message_, ...args);
    }
  }

  private formatMessage(message: any, ...args: any[]) {
    const prefix = this.formatPrefix.call(this);
    return `${prefix}: ${message} ${args.map(maybeStringify).join(", ") || ""}`;
  }
}

function maybeStringify(message: object | string) {
  const parsed = JSON.stringify(message);
  return parsed || message;
}

/**
 * Standalone log function. Used in src/analytics/index.ts
 */
export function log(message: string, context?: object) {
  if (config.logLevel > LogLevel.LOG) {
    // if we don't want to log to console, we might still want to push to DD
    if (DATADOG_ENABLED) {
      datadogLogs.logger.info(message, context);
    }
  } else {
    // eslint-disable-next-line no-console
    console.log(message);
  }
}
