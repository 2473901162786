import { Text } from "src/components";
import { createStyles } from "src/styles";

const styles = createStyles((tokens) => ({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: tokens.colors.red,
    opacity: 0.9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 5,
    padding: 50,
  },
  errorText: {
    color: tokens.colors.white,
    fontSize: 20,
    textAlign: "center",
  },
}));

const FullScreenErrorMessage = ({ message }: { message?: string }) => {
  if (!message) {
    return null;
  }

  return (
    <div css={styles.container}>
      <Text weight="bold" style={styles.errorText}>
        {message}
      </Text>
    </div>
  );
};

export default FullScreenErrorMessage;
