import { memo } from "react";
import { DropdownSelector } from "src/components";
import { DropdownSelectorProps } from "src/components/DropdownSelector";

import { enums, formatters } from "@fraction/shared";

const { FREQUENCY_MAP } = formatters.enums;

const FREQUENCY_OPTIONS = [
  enums.Frequency.MONTHLY,
  enums.Frequency.WEEKLY,
  enums.Frequency.QUARTERLY,
  enums.Frequency.ANNUAL,
];

export interface FrequencyDropdownProps extends Omit<DropdownSelectorProps<any>, "options"> {
  onChange: (frequency?: enums.Frequency) => void;
}

const FrequencyDropdown = (props: FrequencyDropdownProps) => (
  <DropdownSelector
    defaultValue={{
      value: enums.Frequency.MONTHLY,
      label: FREQUENCY_MAP[enums.Frequency.MONTHLY],
    }}
    {...props}
    options={FREQUENCY_OPTIONS.map((type) => ({
      value: type,
      label: FREQUENCY_MAP[type],
    }))}
  />
);

export default memo(FrequencyDropdown);
