import { enums } from "@fraction/shared";
import create from "zustand";

export interface PortfolioDashStore {
  // empty in this case means not filtering any - default to all
  portfolioFilters?: enums.Portfolio[];
  startDateFilter?: Date;
  endDateFilter?: Date;
  endLoanActivityDateFilter?: Date;
  startLoanActivityDateFilter?: Date;
  countryFilter?: enums.Country;
  productTypeFilter?: enums.ProductType;
  loanStatusFilter?: enums.LoanStatus;
  termLengthFilter?: number;
  selectedApp?: string;
  setSelectedApp: (selectedApp?: string) => void;
  setStartDateFilter: (date?: Date) => void;
  setEndDateFilter: (date?: Date) => void;
  setStartLoanActivityDateFilter: (date?: Date) => void;
  setEndLoanActivityDateFilter: (date?: Date) => void;
  setCountryFilter: (country?: enums.Country) => void;
  setPortfolioFilters: (portfolios?: enums.Portfolio[]) => void;
  setProductTypeFilter: (productTypeFilter?: enums.ProductType) => void;
  setLoanStatusFilter: (loanStatusFilter?: enums.LoanStatus) => void;
  setTermLengthFilter: (termLengthFilter?: number) => void;
  navDrawerOpen: boolean;
  openNavDrawer: () => void;
  closeNavDrawer: () => void;
  toggleNavDrawer: () => void;
}

// put anything that should be considered "global" state for the dashboard in here
const usePortfolioDashStore = create<PortfolioDashStore>((set) => ({
  portfolioFilters: undefined,
  startDateFilter: undefined,
  endDateFilter: undefined,
  endLoanActivityDateFilter: undefined,
  startLoanActivityDateFilter: undefined,
  countryFilter: undefined,
  productTypeFilter: undefined,
  loanStatusFilter: enums.LoanStatus.ACTIVE,
  termLengthFilter: undefined,
  selectedApp: undefined,
  navDrawerOpen: false,
  setSelectedApp: (selectedApp?: string) => set({ selectedApp }),
  setPortfolioFilters: (portfolioFilters?: enums.Portfolio[]) => set({ portfolioFilters }),
  setCountryFilter: (countryFilter?: enums.Country) => set({ countryFilter }),
  setProductTypeFilter: (productTypeFilter?: enums.ProductType) => set({ productTypeFilter }),
  setLoanStatusFilter: (loanStatusFilter?: enums.LoanStatus) => set({ loanStatusFilter }),
  setTermLengthFilter: (termLengthFilter?: number) => set({ termLengthFilter }),
  setEndDateFilter: (endDateFilter?: Date) => set({ endDateFilter }),
  setStartDateFilter: (startDateFilter?: Date) => set({ startDateFilter }),
  setEndLoanActivityDateFilter: (endLoanActivityDateFilter?: Date) => set({ endLoanActivityDateFilter }),
  setStartLoanActivityDateFilter: (startLoanActivityDateFilter?: Date) =>
    set({ startLoanActivityDateFilter }),
  openNavDrawer: () => set({ navDrawerOpen: true }),
  closeNavDrawer: () => set({ navDrawerOpen: false }),
  toggleNavDrawer: () => set(({ navDrawerOpen }) => ({ navDrawerOpen: !navDrawerOpen })),
}));

export default usePortfolioDashStore;
