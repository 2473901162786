import { CircleSpinner } from "src/components";
import EmptyPage from "src/components/EmptyPage";
import { createStyles } from "src/styles";

const styles = createStyles({
  emptyPage: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const FullPageLoading = () => (
  <EmptyPage css={styles.emptyPage}>
    <CircleSpinner />
  </EmptyPage>
);

export default FullPageLoading;
