import { useState } from "react";
import { Label } from "src/components/ui/label";
import { Switch } from "src/components/ui/switch";
import { useCachedState } from "src/hooks/useCache";
import { cn } from "src/utilities/shadcnUtils";

export default function useToggler({
  id,
  label,
  defaultValue = false,
  direction = "right",
  cacheKey,
}: {
  id: string;
  label: string;
  defaultValue: boolean;
  direction?: "left" | "right";
  cacheKey?: string;
}) {
  const [on, setOn] = (cacheKey ? useCachedState : useState)<boolean>(defaultValue, cacheKey!);

  return {
    on,
    setOn,
    Toggle: ({ className }: { className?: string }) => (
      <div className={cn("flex items-center space-x-2", className)}>
        {direction === "left" ? (
          <Label className="cursor-pointer" htmlFor={id}>
            {label}
          </Label>
        ) : null}
        <Switch checked={on} onCheckedChange={setOn} id={id} />
        {direction === "right" ? (
          <Label className="cursor-pointer" htmlFor={id}>
            {label}
          </Label>
        ) : null}
      </div>
    ),
  };
}
