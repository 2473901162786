import { ReactNode } from "react";
import { Text } from "src/components";
import { Style, createStyles } from "src/styles";

const styles = createStyles(({ borderRadius, spacing, colors }) => ({
  outerContainer: {
    display: "inline-block",
  },
  innerContainer: {
    width: "100%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: `${spacing[1]}px ${spacing[1.5]}px ${spacing[0.75]}px ${spacing[1.5]}px`,
    borderRadius: borderRadius[0.75],
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: spacing[0.25],
    transform: "translateX(-4px)",
  },
  sm: {
    padding: `${spacing[1]}px ${spacing[1.5]}px ${spacing[1]}px ${spacing[1.5]}px`,
  },
  md: {
    padding: `${spacing[1]}px ${spacing[1.5]}px ${spacing[0.75]}px ${spacing[1.5]}px`,
  },
  base: {
    backgroundColor: colors.darkgrey200,
    color: colors.grey700,
  },
  green: {
    backgroundColor: colors.green300,
    color: colors.green700,
  },
  red: {
    backgroundColor: colors.red300,
    color: colors.red700,
  },
  yellow: {
    backgroundColor: colors.amber300,
    color: colors.amber700,
  },
  noSplit: {
    whiteSpace: "nowrap",
    "@media(max-width: 500px)": {
      whiteSpace: "normal",
    },
  },
}));

const Badge = ({
  children,
  variant = "base",
  style,
  icon,
  size = "md",
  className,
}: {
  icon?: ReactNode;
  children: ReactNode;
  variant?: "base" | "green" | "red" | "yellow";
  style?: Style;
  size?: "md" | "sm";
  className?: string;
}) => (
  <div className={className} css={styles.outerContainer}>
    <div css={[styles.innerContainer, styles[variant], styles[size], style]}>
      {icon ? <div css={styles.icon}>{icon}</div> : null}
      <Text style={[styles[variant], styles.noSplit]} weight="medium" size={size === "md" ? "xs" : "2xs"}>
        {children}
      </Text>
    </div>
  </div>
);

export default Badge;
