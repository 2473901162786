import { Global, css } from "@emotion/react";
import { memo } from "react";

const NO_MARGIN = css`
  body {
    margin: 0;
  }
`;

const GlobalStyles = () => <Global styles={NO_MARGIN} />;

export default memo(GlobalStyles);
