import { LucideIcon } from "lucide-react";
import * as React from "react";
import { SVGProps, forwardRef } from "react";
import { Skeleton, Text, Touchable } from "src/components";
import { createStyles } from "src/styles";
import { cn } from "src/utilities/shadcnUtils";

export interface NavItemProps {
  children: string;
  icon?: React.FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }> | LucideIcon;
  onClick?: () => void;
  active?: boolean;
  loading?: boolean;
  iconClassName?: string;
}

const styles = createStyles(({ spacing, colors, borderRadius }) => ({
  container: {
    padding: spacing[2],
    borderRadius: borderRadius[1],
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  icon: {
    marginRight: 20,
    color: colors.grey500,
  },
  text: {
    color: colors.grey500,
  },
  active: {
    color: colors.green600,
  },
  bgActive: {
    backgroundColor: colors.green100,
  },
}));

const NavItem = forwardRef<HTMLButtonElement, NavItemProps>(
  ({ icon: Icon, onClick, children, active, loading, iconClassName }: NavItemProps, ref) =>
    loading ? (
      <Skeleton height={58} />
    ) : (
      <Touchable
        ref={ref}
        className={cn(active ? "hover:bg-green-200 text-left" : "hover:bg-gray-300 text-left")}
        onClick={onClick}
        css={[styles.container, active && styles.bgActive]}
      >
        {Icon && (
          <Icon
            width={24}
            height={24}
            css={[styles.icon, active && styles.active]}
            className={iconClassName}
          />
        )}
        <Text size="sm" weight="medium" style={[styles.text, active && styles.active]}>
          {children}
        </Text>
      </Touchable>
    )
);

export default NavItem;
