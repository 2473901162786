import { colors, formatters } from "@fraction/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { BigButton, TextInput } from "src/components";
import { Variant } from "src/components/BigButton";
import { InputModalFooter, InputModalForm } from "src/components/InnerModals";
import { TextInputEvent } from "src/components/TextInput";
import { BoldText, MediumText } from "src/components/v1";
import { createStyles } from "src/styles";
import { z } from "zod";

const styles = createStyles({
  totalAmountButton: {
    marginBottom: 16,
  },
  buttonTextContainer: {
    display: "flex",
    justifyContent: "space-between",
  },

  buttonText: {
    color: colors.palette.GREY_700,
  },
});

const CustomPaymentAmountScreen = ({
  paragraph,
  onBack,
  onContinue,
  formValues,
  handleTextChange,
  schema,
  overdueBalance,
  paymentAmount,
  recurring,
}: {
  paragraph: string;
  onBack: () => void;
  onContinue: () => void;
  formValues: Record<string, string>;
  handleTextChange?: (value: string, e: TextInputEvent) => void;
  schema: z.ZodObject<any>;
  overdueBalance?: number;
  paymentAmount?: number;
  recurring?: boolean;
}) => {
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "onSubmit",
  });
  const amt = recurring ? paymentAmount : overdueBalance;
  const label = recurring ? "Required payment" : "Outstanding balance";

  const handleClickOverdueBalance = useCallback(() => {
    if (!amt) {
      return;
    }
    setValue("amount", amt);
    handleTextChange?.((amt / 100).toString(), {
      // @ts-ignore
      target: { name: "amount", value: (amt / 100).toString() },
    });
    onContinue?.();
  }, [recurring, amt, setValue, onContinue, handleTextChange]);

  return (
    <InputModalForm
      onSubmit={handleSubmit(onContinue)}
      header={`ENTER ${recurring ? "RECURRING " : ""}PREPAYMENT AMOUNT`}
      paragraph={paragraph}
      footer={
        <InputModalFooter
          actions={[
            {
              text: "Back",
              action: onBack,
            },
            {
              text: "Next",
              action: handleSubmit(onContinue),
            },
          ]}
        />
      }
    >
      {overdueBalance ? (
        <BigButton
          variant={Variant.GREEN}
          style={styles.totalAmountButton}
          onClick={handleClickOverdueBalance}
        >
          <div css={styles.buttonTextContainer}>
            <MediumText style={styles.buttonText}>{label}</MediumText>
            <BoldText style={styles.buttonText}>{formatters.number.getCurrencyFromNumber(amt)}</BoldText>
          </div>
        </BigButton>
      ) : null}
      <TextInput
        label={overdueBalance ? "Other amount" : "Amount"}
        error={errors?.amount?.message}
        ref={register}
        name="amount"
        inputMode="numeric"
        defaultValue={formValues.amount}
        onChange={handleTextChange}
        maskOptions={{
          rightAlign: false,
          alias: "currency",
          prefix: "$ ",
          jitMasking: false,
          digits: 2,
        }}
      />
    </InputModalForm>
  );
};
export default CustomPaymentAmountScreen;
