import * as React from "react";

import { sleep } from "@fraction/shared";
import { Logger } from "src/log";

const logger = new Logger("utilities");

export function recursiveReactChildrenMap(
  children: React.ReactElement[],
  fn: (...args: any[]) => React.ReactElement
): React.ReactElement[] {
  return React.Children.map(children, (child: React.ReactElement & { props: { children: any } }) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if (child.props?.children) {
      child = React.cloneElement(child, {
        children: recursiveReactChildrenMap(child.props.children, fn),
      });
    }

    return fn(child);
  });
}

export type BlockFocus = "start" | "center" | "end" | "nearest";

export const focus = (
  nodes: { focus: React.RefObject<any>; scroll?: React.RefObject<any> } | React.RefObject<any>,
  block: BlockFocus = "center"
): boolean => {
  if (!("focus" in nodes)) {
    nodes = { focus: nodes, scroll: nodes };
  }
  if (!nodes.focus?.current?.scrollIntoView) {
    return false;
  }

  const scrollTarget = nodes.scroll?.current ? nodes.scroll : nodes.focus;
  scrollTarget.current?.scrollIntoView({
    behavior: "smooth",
    block,
    inline: "center",
  });
  setTimeout(() => {
    if ("focus" in nodes) {
      nodes.focus?.current?.focus?.({ preventScroll: true });
    }
  }, 500);
  return true;
};

export const focusWithParentScroller = (
  nodes: { focus: React.RefObject<any>; scroll?: React.RefObject<any> } | React.RefObject<any>,
  parent: React.RefObject<any>,
  block: BlockFocus = "center"
): boolean => {
  if (!("focus" in nodes)) {
    nodes = { focus: nodes, scroll: nodes };
  }

  if (!nodes.focus?.current?.scrollIntoView) {
    logger.log("focusWithParentScroller: nodes.focus.current.scrollIntoView is not defined");
    return false;
  }
  let scrollTimeout: any;

  // a little bit after we've started scrolling, focus the element
  const scrollListener = () => {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      if ("focus" in nodes && nodes.focus) {
        logger.log("focusWithParentScroller: scrollListener: focusing on element");
        nodes?.focus.current?.focus?.({ preventScroll: true });
        parent?.current?.removeEventListener("scroll", scrollListener);
      }
    }, 100);
  };
  parent?.current?.addEventListener("scroll", scrollListener);

  const scrollTarget = nodes.scroll?.current ? nodes.scroll : nodes.focus;
  const isVisible = scrollTarget?.current && document.body.contains(scrollTarget.current);

  if (isVisible) {
    logger.log("focusWithParentScroller: scrollTarget is defined. Running scrollIntoView");
    scrollTarget.current?.scrollIntoView({
      block,
      inline: "center",
    });
  } else {
    logger.log("focusWithParentScroller: scrollTarget is not defined");
    return false;
  }

  return true;
};

export const focusWhenPossible = async (
  nodes: { focus: React.RefObject<any>; scroll?: React.RefObject<any> } | React.RefObject<any>,
  block: BlockFocus = "center"
) => {
  let focused = focus(nodes, block);
  while (!focused) {
    await sleep(100);
    focused = focus(nodes, block);
  }
};

export const focusWhenPossibleWithParentScroller = async (
  nodes: { focus: React.RefObject<any>; scroll?: React.RefObject<any> } | React.RefObject<any>,
  parent: React.RefObject<any>,
  block: BlockFocus = "center"
) => {
  // seem to need this delay to have more reliable focusing
  await sleep(10);

  let focused = focusWithParentScroller(nodes, parent, block);
  let tries = 0;
  while (!focused && tries < 3) {
    logger.log("focusWithParentScroller not able to focus. Trying again.");
    await sleep(100);
    focused = focusWithParentScroller(nodes, parent, block);
    tries++;
  }
};

// Used when we want to trigger an onchange event on an input that's been programatically edited.
// Borrowed from: https://hustle.bizongo.in/simulate-react-on-change-on-controlled-components-baa336920e04
export const editInputValueAndForceOnChangeEvent = (
  input: string,
  ref: React.RefObject<HTMLInputElement>
) => {
  if (!ref.current) {
    return;
  }
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value");
  if (!nativeInputValueSetter || !nativeInputValueSetter.set) {
    return;
  }
  nativeInputValueSetter.set.call(ref.current, input);
  const inputEvent = new Event("input", { bubbles: true });
  ref.current.dispatchEvent(inputEvent);
};
