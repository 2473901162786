import { Loader, MessageCircleQuestion } from "lucide-react";
import { BdmContactCard } from "src/components/BdmContactCard";
import { MortgageSpecialistContactCard } from "src/components/MortgageSpecialistContactCard";
import NavItem from "src/components/NavItem";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { useFractionContacts } from "src/hooks/useFractionContacts";

export const SupportPopup = () => {
  const { isLoading, data } = useFractionContacts();

  if (!isLoading && !data?.contacts?.length) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <NavItem
          iconClassName={isLoading ? "animate-spin" : undefined}
          icon={isLoading ? Loader : MessageCircleQuestion}
        >
          Support
        </NavItem>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <BdmContactCard className="w-full" />
        <div className="h-[1px] w-full bg-green-200" />
        <MortgageSpecialistContactCard className="w-full" />
      </PopoverContent>
    </Popover>
  );
};
