import { Saved, colors, entities } from "@fraction/shared";
import _ from "lodash";
import { FolderIcon } from "lucide-react";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { BasicTable, PrimaryButton, Skeleton, Text, Touchable } from "src/components";
import { FileTreeNode, constructFileTreeSorted } from "src/components/FileTable/fileTree";
import { ChevronDown, Download } from "src/icons";
import { useMutation } from "src/lib";
import { Style, createStyles } from "src/styles";

const styles = createStyles(({ spacing, colors }) => ({
  touchable: {
    width: "100%",
  },
  tableHead: {
    borderBottom: `solid 1px ${colors.darkgrey200}`,
    paddingBottom: spacing[1.5],
    height: spacing[4],
  },
  tableHeadText: {
    color: colors.darkgrey700,
  },
  row: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 16px",
    "&:hover": {
      backgroundColor: colors.darkgrey100,
    },
  },
  cell: {
    paddingTop: spacing[2],
    paddingBottom: spacing[2.5],
  },
  actionsCell: {
    paddingLeft: spacing[2],
    paddingRight: spacing[2],
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
  },
  cellSkeleton: {
    minWidth: 120,
    width: "90%",
  },
  actionCellSkeleton: {
    width: 170,
  },
  headerCell: {
    display: "flex",
  },
  nestedCell: {
    width: "100%",
  },
  expansion: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 40,
  },
  nextedRow: {
    width: "100%",
    display: "flex",
  },
  textCellStyle: { width: "100%" },
  expansionLine: {
    position: "absolute",
    top: -18,
    bottom: 18,
    left: 34,
    width: 1,
    backgroundColor: colors.darkgrey400,
  },
  iconContainer: { display: "flex", flexDirection: "row" },
}));

const Row = ({ children, style }: { children: ReactNode; style?: Style }) => (
  <tr css={[styles.row, style]}>{children}</tr>
);

const Cell = ({
  children,
  isLoading,
  style,
}: {
  children?: ReactNode;
  isLoading?: boolean;
  style?: Style;
}) => (
  <div css={[styles.cell, style]}>
    {isLoading ? <Skeleton height={24} style={styles.cellSkeleton} /> : children}
  </div>
);

export const FileRow = ({
  text,
  subtext,
  isLoading,
  onClick,
  href,
  buttonSize = "medium",
}: {
  text: string;
  subtext?: string;
  isLoading: boolean;
  onClick: (href?: string) => void;
  href?: string;
  buttonSize?: "small" | "medium" | "standard" | "large";
}) => {
  const handleClick = useCallback(() => {
    onClick?.(href);
  }, [onClick, href]);

  return (
    <div className="hover:bg-gray-200 flex flex-row justify-between items-center p-2 rounded even:bg-gray-100 my-1">
      <div>
        <div>
          <p className="font-bold">{text || "N/A"}</p>
        </div>
        {subtext ? <p className="text-gray text-sm">{subtext}</p> : null}
      </div>
      <PrimaryButton size={buttonSize} startIcon={<Download />} loading={isLoading} onClick={handleClick}>
        Download
      </PrimaryButton>
    </div>
  );
};

const isS3Document = (node: any): node is Saved<entities.S3Document> =>
  typeof node === "object" && "s3Key" in node && "id" in node && "s3Bucket" in node;

const DocumentRow = ({
  document,
  onDownload,
  nameOverride,
}: {
  document: Saved<entities.S3Document>;
  onDownload: (id: string) => Promise<void>;
  nameOverride?: string;
}) => {
  const download = useMutation({
    mutationFn: (fn: any) => onDownload(document.id),
  });

  const splitKey = document.s3Key.split("/");
  return (
    <FileRow
      text={nameOverride || document.name || splitKey[splitKey.length - 1]}
      isLoading={download.isPending}
      onClick={download.mutate}
    />
  );
};

export const FileTree = ({
  documents,
  onDownload,
  combineOneFileDirectories = false,
  nameFormatter,
}: {
  documents: Saved<entities.S3Document>[];
  onDownload: (id: string) => Promise<void>;
  combineOneFileDirectories?: boolean;
  nameFormatter?: (name: string, context: FileTreeNode) => string;
}) => {
  const tree = useMemo(() => constructFileTreeSorted(documents), [documents]);
  return (
    <InnerFileTree
      nameFormatter={nameFormatter}
      combineOneFileDirectories={combineOneFileDirectories}
      root={tree}
      onDownload={onDownload}
    />
  );
};

const ExpandableDirectory = ({
  dirKey,
  node,
  onDownload,
  combineOneFileDirectories,
  nameFormatter,
}: {
  dirKey: string;
  node: FileTreeNode;
  onDownload: (id: string) => Promise<void>;
  combineOneFileDirectories?: boolean;
  nameFormatter?: (name: string, context: FileTreeNode) => string;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleExpand = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  // is a directory
  return (
    <div key={dirKey}>
      <Touchable css={styles.touchable} onClick={handleExpand}>
        <Row style={styles.nextedRow}>
          <Cell style={styles.nestedCell}>
            <div css={styles.iconContainer}>
              <FolderIcon color={colors.palette.GREY_900} className="mr-4" />
              <Text weight="bold">{nameFormatter ? nameFormatter(dirKey, node) : dirKey || "N/A"}</Text>
            </div>
          </Cell>
          <Cell style={styles.actionsCell}>
            <ChevronDown />
          </Cell>
        </Row>
      </Touchable>
      <div css={[styles.expansion, { position: "relative" }]}>
        {expanded && (
          <>
            <div css={styles.expansionLine} />
            <InnerFileTree
              combineOneFileDirectories={combineOneFileDirectories}
              root={node}
              onDownload={onDownload}
              nameFormatter={nameFormatter}
            />
          </>
        )}
      </div>
    </div>
  );
};

const InnerFileTree = ({
  root,
  onDownload,
  combineOneFileDirectories = false,
  nameFormatter,
}: {
  root: FileTreeNode;
  onDownload: (id: string) => Promise<void>;
  combineOneFileDirectories?: boolean;
  nameFormatter?: (name: string, context: FileTreeNode) => string;
}) => {
  const sorted = useMemo(() => _.sortBy(Object.entries(root), 0), [root]).reverse();
  return (
    <>
      {sorted.map(([key, node]) => {
        const keys = Object.keys(node);
        // @ts-ignore
        const isOneFileDirectory = keys.length === 1 && isS3Document(node[keys[0]]);

        if (isS3Document(node) || (isOneFileDirectory && combineOneFileDirectories)) {
          return (
            <div key={key} className="w-full">
              <DocumentRow
                key={key}
                // @ts-ignore
                document={isS3Document(node) ? node : node[keys[0]]}
                onDownload={onDownload}
                nameOverride={isS3Document(node) ? node.name : `${key}/${node[keys[0]].name}`}
              />
            </div>
          );
        }

        return (
          <ExpandableDirectory
            combineOneFileDirectories={combineOneFileDirectories}
            key={key}
            node={node}
            dirKey={key}
            onDownload={onDownload}
            nameFormatter={nameFormatter}
          />
        );
      })}
    </>
  );
};

const FileTable = ({
  isLoading,
  children,
  tableHeadText,
  className,
}: {
  isLoading: boolean;
  children: ReactNode;
  tableHeadText: string;
  className?: string;
}) => {
  return (
    <BasicTable className={className} overflow>
      <thead css={styles.tableHead}>
        <tr>
          <th css={styles.headerCell}>
            <Text size="xs" style={styles.tableHeadText}>
              {tableHeadText}
            </Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {isLoading &&
          [1, 2, 3].map((_, index) => (
            <Row key={`loadingRow-${index}`}>
              <Cell isLoading />
              <Cell isLoading style={styles.actionCellSkeleton} />
            </Row>
          ))}
        {!isLoading && children}
      </tbody>
    </BasicTable>
  );
};

export default FileTable;
