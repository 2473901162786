import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import "./setupWorkers";
import "./utilities/initialSetup";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
