import { differenceInCalendarMonths, differenceInCalendarYears, subYears } from "date-fns";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import * as React from "react";
import finance from "src/api/finance";
import { Skeleton, Toggle, Touchable } from "src/components";
import { BoldText, HeaderText, LightText } from "src/components/v1";
import { useWindowSize } from "src/hooks";
import { createStyles } from "src/styles";
import getDeviceType from "src/utilities/interface/getDeviceType";

import { calculateCompoundInterest, clamp, colors, entities } from "@fraction/shared";

import DatedChart from "../DatedChart";

export enum InterestType {
  COMPOUND = "compound",
  SIMPLE = "simple",
}

const MIN_APR = 0.0444;
const MAX_APR = 0.1157;

export interface HVIChartProps {
  data: Array<entities.DateValue>;
  initialShowLoan?: boolean;
  location?: string;
  loading?: boolean;
  years?: number;
  minAPR?: number;
  maxAPR?: number;
}

interface Payload {
  date: number;
  loanValue: number;
  propertyValue: number;
  textAnchor: "middle" | "start" | "end";
}

const styles = createStyles({
  fractionRate: {
    fill: colors.palette.YELLOW,
  },
  propertyGrowthRate: {
    fill: colors.palette.GREEN,
  },
  date: {
    fill: colors.palette.GREY_800,
  },
  text: {
    fontFamily: "Montserrat",
    whiteSpace: "pre",
    fontSize: 15,
    fontWeight: 600,
    textAnchor: "middle",
    "@media(max-width: 600px)": {
      fontSize: 12,
    },
  },
  zip: {
    color: colors.palette.GREEN,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    right: 0,
    left: 32,
    position: "absolute",
    top: 10,
    zIndex: 1,
    "@media(max-width: 600px)": {
      position: "relative",
      top: "unset",
      left: "unset",
      right: "unset",
      justifyContent: "flex-start",
      paddingLeft: 0,
      marginBottom: 12,
    },
  },
  toggleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 0,
    "@media(max-width: 600px)": {
      marginTop: 28,
    },
  },
  title: {
    fontSize: 28,
    color: colors.BLACK_TEXT,
    "@media(max-width: 600px)": {
      fontSize: 18,
    },
  },
  titlePosition: {
    position: "absolute",
    top: 10,
    left: 0,
  },
  toggleText: {
    fontSize: 14,
    marginRight: 14,
    color: colors.BLACK_TEXT,
    textAlign: "right",
    "@media(max-width: 600px)": {
      textAlign: "left",
      maxWidth: "100%",
    },
    maxWidth: "50%",
  },
  loadingToggleTextContainer: {
    marginRight: 14,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-end",
    maxWidth: "50%",
    "@media(max-width: 600px)": {
      maxWidth: "100%",
    },
  },
  loadingToggleText: {
    marginRight: 5,
  },
  container: {
    position: "relative",
    height: 500,
    width: "100%",
    "@media(max-width: 600px)": {
      height: 225,
    },
  },
});

const TEXT_SPACING = "1.5em";

const formatDate = (yearsAgo: Date) => (date: number, { textAnchor = "middle" }: Payload) => {
  const diff = Math.abs(differenceInCalendarYears(date, yearsAgo));
  let t = `Over ${diff} years`;
  if (diff === 0) {
    t = "First year";
  }
  if (diff === 1) {
    t = "Over 1 year";
  }
  return <text css={[styles.text, styles.date, { textAnchor }]}>{t}</text>;
};

const formatValueTooltip = (value: number, { loanValue, propertyValue, textAnchor = "middle" }: Payload) => (
  <text css={[styles.text, { textAnchor }]} overflow="visible">
    <tspan x="0">
      Your equity:{" "}
      <tspan css={styles.propertyGrowthRate}>{`${Math.round(
        ((propertyValue - loanValue) / propertyValue) * 100
      )}%`}</tspan>
    </tspan>
    <tspan x="0" dy={TEXT_SPACING}>
      Your loan:{" "}
      <tspan css={styles.fractionRate}>{`${Math.round((loanValue / propertyValue) * 100)}%`}</tspan>
    </tspan>
  </text>
);

const formatRateTooltip = (
  value: number,
  { date, textAnchor = "middle" }: Payload,
  startValue: number,
  yearsAgo: Date,
  minAPR: number = MIN_APR,
  maxAPR: number = MAX_APR
) => {
  const diff = new Date(date).getFullYear() - yearsAgo.getFullYear();

  const propertyGrowth =
    diff === 0 || !startValue
      ? 0
      : finance.CAGR(startValue, value, new Date(date).getFullYear() - yearsAgo.getFullYear());

  const annualPropertyGrowth = diff === 0 || !startValue ? "no change" : `${propertyGrowth}%`;
  const annualFractionRate =
    diff === 0 || !startValue
      ? `${(minAPR * 100).toFixed(2)}%`
      : `${clamp(propertyGrowth, minAPR * 100, maxAPR * 100).toFixed(2)}%`;

  return (
    <text css={[styles.text, { textAnchor }]} overflow="visible">
      <tspan x="0">
        Annual property growth: <tspan css={styles.propertyGrowthRate}>{annualPropertyGrowth}</tspan>
      </tspan>
      <tspan x="0" dy={TEXT_SPACING}>
        Annual Fraction rate: <tspan css={styles.fractionRate}>{annualFractionRate}</tspan>
      </tspan>
    </text>
  );
};

const Title = ({ showLoan, location }: { showLoan?: boolean; location?: string }) => {
  if (showLoan) {
    return (
      <HeaderText style={styles.title}>
        Loan vs. equity comparison for {"  "}
        <span css={styles.zip}>{location || ""}</span>
      </HeaderText>
    );
  }
  return (
    <HeaderText style={styles.title}>
      Growth vs. rate comparison for {"  "}
      <span css={styles.zip}>{location || ""}</span>
    </HeaderText>
  );
};

const EquityToggle = ({
  showLoan,
  setShowLoan,
  loading,
}: {
  showLoan: boolean;
  setShowLoan: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
}) => {
  const handleChange = useCallback(() => {
    setShowLoan((prev) => !prev);
  }, [setShowLoan]);

  return (
    <Touchable disabled={loading} onClick={handleChange} css={styles.toggleWrapper}>
      {!loading && (
        <LightText style={styles.toggleText} paragraph>
          See what accessing <BoldText>33%</BoldText> of your home equity looks like for you
        </LightText>
      )}
      {loading && (
        <div css={styles.loadingToggleTextContainer}>
          <Skeleton style={styles.loadingToggleText} width={100} height={16} />
          <Skeleton style={styles.loadingToggleText} width={100} height={16} />
          <Skeleton style={styles.loadingToggleText} width={100} height={16} />
          <Skeleton style={styles.loadingToggleText} width={100} height={16} />
        </div>
      )}
      <Toggle loading={loading} value={showLoan} />
    </Touchable>
  );
};

const HVIChart = ({
  data,
  initialShowLoan,
  location,
  loading,
  years = 10,
  maxAPR = MAX_APR,
  minAPR = MIN_APR,
}: HVIChartProps) => {
  const [showLoan, setShowLoan] = useState(initialShowLoan || false);
  const { width: windowWidth } = useWindowSize();
  const { phone } = getDeviceType(windowWidth);

  const yearsAgo = subYears(new Date(), years);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dateFormatter = useCallback(formatDate(yearsAgo), [yearsAgo]);

  // dates in ascending order
  const sorted = useMemo(() => data.sort((a, b) => ((a.date || 0) > (b.date || 0) ? 1 : -1)), [data]);

  const yearsIndex = useMemo(
    () => sorted.findIndex(({ date }) => (date || 0) >= yearsAgo),
    [sorted, yearsAgo]
  );

  const yearsRaw = useMemo(
    () =>
      sorted.slice(yearsIndex).map(({ date, value }: any) => ({
        value: value / 100, // convert to dollars
        date,
      })),
    [sorted, yearsIndex]
  );

  const startDate = yearsRaw[0]?.date;
  const startValue = yearsRaw[0]?.value;
  const startLoanValue = startValue * 0.33;

  const yearsData: Array<{ propertyValue: number; date: number }> = useMemo(
    () =>
      yearsRaw.map(({ date, value }: any) => {
        const yearDiff = differenceInCalendarMonths(date, startDate) / 12;
        const loanRate = finance.CAGR(startValue, value, yearDiff) / 100;
        const loanValue = calculateCompoundInterest(
          startLoanValue,
          clamp(loanRate, minAPR, maxAPR),
          yearDiff
        );

        return {
          loanValue: showLoan ? loanValue : undefined,
          propertyValue: value,
          date: date.getTime(),
        };
      }),
    [yearsRaw, startDate, startValue, startLoanValue, showLoan, minAPR, maxAPR]
  );

  const formatHomeValue = React.useCallback(
    (value: number, payload: Payload) =>
      formatRateTooltip(value, payload, startValue, yearsAgo, minAPR, maxAPR),
    [startValue, yearsAgo, minAPR, maxAPR]
  );

  return (
    <div css={styles.container}>
      <div css={styles.titleWrapper}>
        <Title showLoan={showLoan} location={location} />
        {!phone && <EquityToggle loading={loading} showLoan={showLoan} setShowLoan={setShowLoan} />}
      </div>
      <DatedChart
        area
        data={yearsData}
        colors={{ propertyValue: colors.palette.GREEN, loanValue: colors.palette.YELLOW }}
        yAxisKey="propertyValue"
        yTicks={false}
        formatters={{ propertyValue: showLoan ? formatValueTooltip : formatHomeValue, date: dateFormatter }}
        topPadding={phone ? 0 : 95}
        loading={loading}
      />
      {phone && <EquityToggle loading={loading} showLoan={showLoan} setShowLoan={setShowLoan} />}
    </div>
  );
};

export default React.memo(HVIChart);
