import retry from "@fullstax/p-retry";
import { lazy } from "react";
import { Outlet, Route } from "react-router-dom";
import { PortfolioDashboardHeader } from "src/apps/PortfolioDashboard/components/Header";
import { PropertyInfoDrawer } from "src/apps/PortfolioDashboard/components/PropertyInfoDrawer";
import { PortfolioAnalyticsScreen } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics";
import { PortfolioDataScreen } from "src/apps/PortfolioDashboard/screens/PortfolioData";
import PortfolioLoanTape from "src/apps/PortfolioDashboard/screens/PortfolioLoanTape";
import { PortfolioMapScreen } from "src/apps/PortfolioDashboard/screens/PortfolioMap";
import usePortfolioDashStore from "src/apps/PortfolioDashboard/store";
import { useAuth } from "src/auth";
import { DashboardShell, Heading, Loading, RequireAuth } from "src/components";
import EnhanceRoute from "src/components/root/routeHelpers/EnhanceRoute";

const PasswordProtectedFileDownload = lazy(() =>
  retry(() => import("src/apps/PortfolioDashboard/screens/PasswordProtectedFileDownload"))
);

const DoesNotHaveAPortfolio = () => {
  return (
    <div className="flex w-full h-full justify-center items-center pb-10">
      <Heading>Sorry, you do not have access to any mortgage portfolios</Heading>
    </div>
  );
};

const ServicingDashboardLayout = () => {
  const { user, isLoadingUser } = useAuth();
  const closeNavDrawer = usePortfolioDashStore((state) => state.closeNavDrawer);

  if (isLoadingUser) {
    return <Loading />;
  }

  return (
    <DashboardShell onClick={closeNavDrawer} header={<PortfolioDashboardHeader />}>
      {user?.servicingCustomer?.portfoliosManaging?.length ? <Outlet /> : <DoesNotHaveAPortfolio />}
      <PropertyInfoDrawer />
    </DashboardShell>
  );
};

export function PortfolioDashboardRoutes() {
  return (
    <>
      <Route path="/download-file" element={<PasswordProtectedFileDownload />} />
      <Route path="/portfolio/*" element={<RequireAuth />}>
        <Route element={<ServicingDashboardLayout />}>
          <Route
            path="*"
            element={
              <EnhanceRoute title="Servicing overview | Fraction">
                <PortfolioMapScreen />
              </EnhanceRoute>
            }
          />
          <Route
            path="analytics"
            element={
              <EnhanceRoute title="Portfolio Analytics | Fraction">
                <PortfolioAnalyticsScreen />
              </EnhanceRoute>
            }
          />
          <Route
            path="loan-tape"
            element={
              <EnhanceRoute title="Portfolio Loan Tape | Fraction">
                <PortfolioLoanTape />
              </EnhanceRoute>
            }
          />
          <Route
            path="data"
            element={
              <EnhanceRoute title="Portfolio Data | Fraction">
                <PortfolioDataScreen />
              </EnhanceRoute>
            }
          />
        </Route>
      </Route>
    </>
  );
}
