import { Link } from "react-router-dom";
import { TitleWithQr } from "src/apps/BrokerDashboard/components/TitleWithQr";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";

const BasicFileRow = ({ filename, description }: { filename: string; description: string }) => {
  return (
    <div className="max-w-[28rem] w-full flex flex-row items-center hover:bg-gray-100 justify-between border-gray-200 border-2 p-4 rounded">
      <div className="text-left mr-6">
        <p className="text-lg font-semibold">{filename}</p>
        <p className="text-gray text-sm">{description}</p>
      </div>
    </div>
  );
};

export const BrokerMarketing = () => {
  useDocumentTitle("Marketing Materials");

  return (
    <div className="p-6 flex flex-col gap-2">
      <TitleWithQr
        title="Marketing materials"
        description={
          <>
            We are just getting started with this page, but if you want to generate some documents to help
            your borrower out, give these a spin. If you have ideas for other things you'd like to add, send
            us an email at{" "}
            <a className="text-green" href="mailto:dev@fraction.com">
              dev@fraction.com
            </a>
            .
          </>
        }
      />
      <div className="flex flex-row flex-wrap gap-2">
        <Link to="/marketing/property-report">
          <BasicFileRow
            filename="Property Report"
            description="A customized report you can generate that can show your client how a Fraction Mortgage might work for their property and particular situation."
          />
        </Link>
      </div>
    </div>
  );
};
