import _ from "lodash";
import { Loader } from "lucide-react";
import { FocusEvent, useCallback } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Skeleton from "src/components/Skeleton";
import { InputProps } from "src/components/TextInput/Input";
import { useApplication } from "src/hooks/useApplication";
import { Logger } from "src/log";

const logger = new Logger("ModifiableKeyValue");

export const ModifiableTextArea = ({
  label,
  loading,
  appId,
  path,
}: {
  label: string;
  loading?: boolean;
  appId?: string;
  path: string;
} & InputProps) => {
  const {
    updateApplication,
    updateApplicationProperty,
    data: application,
    isPending,
  } = useApplication({
    id: appId,
  });

  const value = _.get(application, path);

  const handleBlur = useCallback(
    _.debounce((evt: FocusEvent<HTMLTextAreaElement>) => {
      const newValue: string = evt.target.value;
      if (path.startsWith("property.")) {
        logger.log(`Setting property ${path}: ${newValue}`);
        const withoutPropertyPrefix = path.replace("property.", "");
        updateApplicationProperty({ [withoutPropertyPrefix]: newValue });
        return;
      } else {
        logger.log(`Setting application ${path}: ${newValue}`);
        updateApplication({ [path]: newValue });
        return;
      }
    }, 1500),
    [value, path]
  );

  return (
    <div className="flex flex-col flex-wrap gap-1 p-3 rounded">
      <div className="flex flex-row gap-1 items-center">
        <p className="text-sm font-bold mr-2">{label}:</p>
        {isPending ? <Loader height={18} className="text-gray-600 animate-spin" /> : null}
      </div>
      {loading ? (
        <Skeleton width={160} />
      ) : (
        <TextareaAutosize
          onChange={handleBlur}
          defaultValue={value}
          className="outline-green focus:outline outline-offset-0 text-sm text-left text-ellipsis line-clamp-2 bg-gray-400 py-2 px-3 rounded-md w-full min-w-auto"
        />
      )}
    </div>
  );
};
