import * as React from "react";
import { SVGProps } from "react";
import { Touchable } from "src/components";
import { MediumText } from "src/components/v1";
import { ChevronDown, ChevronUp } from "src/icons";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

interface NavLinkProps {
  children: string;
  icon?: React.FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  onClick?: () => void;
  /* If expanded = true, show "up caret". If = false, show "down caret". */
  expanded?: boolean;
  active?: boolean;
  // If it's a subcategory
  nested?: boolean;
  hasNested?: boolean;
}

const styles = createStyles({
  container: {
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: 6,
    paddingRight: 14,
    backgroundColor: colors.LIGHT_BACKGROUND,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "4px 4px 13px 4px",
    width: "100%",
    minWidth: 231,
    height: 40,
    "&:hover": {
      backgroundColor: colors.HIGHLIGHTED,
    },
  },
  innerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  spacer: {
    width: 18,
  },
  text: {
    marginLeft: 12,
    fontSize: 13,
  },
  inactive: {
    color: colors.INACTIVE_TEXT,
    fontWeight: 500,
  },
  active: {
    color: colors.TEXT,
    fontWeight: 700,
  },
  nested: {
    color: colors.TEXT,
    fontWeight: 500,
  },
  activeNestedContainer: {
    backgroundColor: colors.DARK_BACKGROUND,
  },
  arrowSpacer: {
    width: 18,
  },
});

/**
 * @deprecated To be removed - prefer to use NavItem instead.
 */
const OldNavLink = ({ icon: Icon, onClick, expanded, nested, hasNested, active, children }: NavLinkProps) => (
  <Touchable onClick={onClick} css={[styles.container, active && nested && styles.activeNestedContainer]}>
    <div css={styles.innerContainer}>
      {Icon && <Icon color={active || expanded ? colors.TEXT : colors.ICON} width={18} height={18} />}
      {!Icon && <div css={styles.spacer} />}
      <MediumText
        style={[
          active ? styles.active : styles.inactive,
          expanded && styles.active,
          nested && styles.nested,
          styles.text,
        ]}
      >
        {children}
      </MediumText>
    </div>
    {hasNested && expanded === true && <ChevronUp color={active || expanded ? colors.TEXT : colors.ICON} />}
    {hasNested && !expanded && <ChevronDown color={active || expanded ? colors.TEXT : colors.ICON} />}
    {!hasNested && <div css={styles.arrowSpacer} />}
  </Touchable>
);

export default OldNavLink;
