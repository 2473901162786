import { differenceInDays, subYears } from "date-fns";
import { MotionValue, animate, motion, transform, useMotionValue } from "framer-motion";
import { ReactElement, ReactNode, useEffect, useMemo } from "react";
import * as React from "react";
import { Area, AreaChart, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { Coordinate } from "recharts/types/util/types";
import { Skeleton } from "src/components";
import { useDimensions, useEffectRetry } from "src/hooks";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface DatedLineChartProps<Datum extends Record<string, number>> {
  data?: Array<{ date: number } & Datum>;
  colors: Record<keyof Datum, string>;
  formatters?: Partial<Record<keyof Datum, (value: number, payload: any) => string | React.ReactNode>>;
  yAxisKey?: string;
  yTicks?: boolean;
  area?: boolean;
  title?: string | ReactElement | Array<string | ReactElement> | ReactNode;
  topPadding?: number;
  loading?: boolean;
  numXAxisTicks?: number;
}

type Transformers = Record<string, [number[], number[]]>;

interface CustomTooltipProps<TValue extends ValueType, TName extends NameType> {
  // Props provided explicitly by us
  formatters?: Partial<Record<string, (value: number, payload: any) => string | React.ReactNode>>;
  valueKey: string;
  domain: [number, number];
  transformers: Transformers;
  animatedX: MotionValue<number>;
  topPadding: number;

  // Props provided by recharts (manually defined as their typing is not great)
  payload?: Array<Payload<TValue, TName>> | null;
  points: Coordinate[];
  width: number;
}

const CHART_MARGIN = {
  top: 0,
  right: 0,
  left: -60,
  bottom: 0,
};

const CURSOR_TEXT_SPACING = 10;
const TEXT_LINE_HEIGHT = 20;
const ANIMATION_DURATION = 0.15;
const TEXT_HEIGHT = 18.3;
const DESKTOP_DOMAIN_RATIO = 2;
// For some reason, the padding is off by 2. This fixes it
const TOP_PADDING_OFFSET = 2;

const styles = createStyles({
  text: {
    fontFamily: "Montserrat",
    whiteSpace: "pre",
    fontSize: 15,
    fontWeight: 600,
  },
  tick: {
    fontSize: 12,
  },
  date: {
    fill: colors.palette.GREY_800,
  },
  tooltipContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateX(-50%)",
  },
  tooltipDate: {
    fontSize: 10,
    color: colors.palette.GREY_800,
    textAlign: "center",
  },
  tooltipValue: {
    fontSize: 12,
    color: colors.palette.BLACK,
    textAlign: "center",
  },
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  loader: {
    position: "absolute",
    bottom: 31,
    left: 1,
    width: "100%",
  },
  loadingPath: {
    transform: "scaleX(2)",
  },
});

const defaultDateFormatter = (date: number) => new Date(date).getFullYear();

const CustomTick = ({ changeAnchor, ...props }: any) => {
  const value = props?.payload?.value;
  if (!value) {
    return null;
  }
  const lastTick = props.index === props.visibleTicksCount - 1;
  let textAnchor = props.textAnchor;
  if (changeAnchor) {
    textAnchor = "middle";
    if (props.index === 0) {
      textAnchor = "start";
    } else if (lastTick) {
      textAnchor = "end";
    }
  }

  const x = (props.width / (props.visibleTicksCount - 1)) * props.index;
  const { verticalAnchor, visibleTicksCount, tickFormatter, ...textProps } = props;

  return (
    <text
      {...textProps}
      x={x}
      y={16 + props.y}
      fill={lastTick ? colors.palette.BLACK : colors.palette.GREY_800}
      css={[styles.text, styles.tick, { textAnchor }]}
    >
      {props.tickFormatter ? props.tickFormatter(value) : value}
    </text>
  );
};

const CustomCursor = ({
  formatters,
  valueKey,
  domain,
  transformers,
  animatedX,
  topPadding,
  payload,
  ...props
}: CustomTooltipProps<string, string>) => {
  const { x } = props.points[0];
  const { y } = props.points[1];
  const chartColors = useMemo(() => (payload ? payload.map(({ color }) => color!) : []), [payload]);

  const [yCoordinates, setYCoordinates] = React.useState(
    chartColors.reduce<Record<string, number>>((obj, color) => {
      obj[color] = transformers[color]?.[0]
        ? transform(x, transformers[color]?.[0], transformers[color]?.[1])
        : 150;
      return obj;
    }, {})
  );

  useEffect(() => {
    const controls = animate(animatedX, x, {
      duration: ANIMATION_DURATION,
      onUpdate: (v) => {
        const yc: Record<string, number> = {};
        for (const color of chartColors) {
          if (transformers[color]?.[0]) {
            yc[color] = transform(v, transformers[color]?.[0], transformers[color]?.[1]);
          }
        }
        setYCoordinates(yc);
      },
    });
    return controls.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x, chartColors]);

  const { height, width, observe } = useDimensions<SVGSVGElement>();

  const date = payload?.[0]?.payload?.date;
  const value = payload?.[0]?.payload?.[valueKey];

  let textAnchor: "middle" | "start" | "end" = "middle";
  if (x + width / 2 > props.width) {
    // we have gone off the right-hand side of the chart
    textAnchor = "end";
  } else if (x < width / 2) {
    // we have gone off the left-hand side of the chart
    textAnchor = "start";
  }

  const dateComponent = formatters?.date?.(date, {
    ...payload?.[0]?.payload,
    textAnchor,
  });
  const formatted = formatters?.[valueKey]?.(value, {
    ...payload?.[0]?.payload,
    textAnchor,
  });

  return (
    <motion.g width="100%" x={animatedX}>
      <svg overflow="visible" y={height + topPadding}>
        <svg ref={observe} overflow="visible" y={TEXT_HEIGHT - (height + CURSOR_TEXT_SPACING)}>
          {!dateComponent || typeof dateComponent === "string" ? (
            <text css={[styles.text, styles.date]}>{dateComponent || defaultDateFormatter(date)}</text>
          ) : (
            dateComponent
          )}
          <svg overflow="visible" y={TEXT_LINE_HEIGHT}>
            {!formatted || typeof formatted === "string" ? (
              <text css={styles.text}>{formatted}</text>
            ) : (
              formatted
            )}
          </svg>
        </svg>
        <line y1={0} y2={y - (height + topPadding)} stroke="black" strokeWidth="4" />
      </svg>
      {chartColors.map((color) => (
        <motion.circle
          key={color}
          r="10"
          cy={
            yCoordinates[color] ||
            (transformers?.[color]?.[0] &&
              // in case the transform hasn't happened yet (because its only on update), manually transform
              transform(x, transformers?.[color]?.[0], transformers?.[color]?.[1])) ||
            -100 // If there is no way to compute right away, just hide
          }
          fill={color}
        />
      ))}
    </motion.g>
  );
};

const computePointTransformers = <Datum extends Record<string, number>>(
  data: Array<{ date: number } & Datum>
): Transformers | false => {
  const paths: NodeListOf<SVGPathElement> | null = document.querySelectorAll("g.recharts-layer > path");
  if (!paths?.length) {
    return false;
  }
  // match them by their color stroke
  return Array.from(paths).reduce((obj, path) => {
    const pathLength = path.getTotalLength();
    const dataLength = data.length;
    const points = data.map((datum, index) => path.getPointAtLength((index / dataLength) * pathLength));
    const stroke = path.getAttribute("stroke");
    if (stroke) {
      obj[stroke] = [points.map(({ x }) => Number(x)), points.map(({ y }) => Number(y))];
    }
    return obj;
  }, {} as Record<string, [number[], number[]]>);
};

const TODAY = new Date().getTime();
const TEN_YEARS_AGO = subYears(new Date(), 10).getTime();
const DEFAULT_DATA = [{ date: TEN_YEARS_AGO }, { date: TODAY }];

/**
 * Assume that the data is sorted.
 */
const DatedChart = <Datum extends Record<string, number>>({
  data = DEFAULT_DATA as Array<{ date: number } & Datum>,
  colors: chartColors,
  formatters,
  yAxisKey,
  yTicks = true,
  area,
  topPadding = 0,
  loading,
  numXAxisTicks = 5,
}: DatedLineChartProps<Datum>) => {
  const [transformers, setTransformers] = React.useState<Record<string, [number[], number[]]> | undefined>();

  if (data.length === 0) {
    data = DEFAULT_DATA as Array<{ date: number } & Datum>;
  }

  const startDate = data[0].date;
  const endDate = data[data.length - 1].date;
  const tickInterval = Math.ceil((endDate - startDate) / (numXAxisTicks - 1));
  const xAxisTicks = [];
  for (let i = startDate; i < endDate; i += tickInterval) {
    xAxisTicks.push(i);
  }
  xAxisTicks.push(endDate);

  useEffectRetry(() => {
    const t = computePointTransformers(data);
    if (!t) {
      return false;
    }
    setTransformers(t);
  }, [setTransformers, data]);

  const nonDateKey = Object.entries(data[0]).filter(
    ([key, value]) => value !== undefined && key !== "date"
  )?.[0]?.[0];
  const valueKey = yAxisKey || nonDateKey;
  const startValue = data[0][valueKey];
  const endValue = data[data.length - 1][valueKey];
  const fallbackKey: string | undefined = Object.keys(colors).find((key) => key !== "date");

  const animatedX = useMotionValue(0);
  const maxValue = useMemo(() => Math.max(...data.map((datum) => datum[valueKey])), [data, valueKey]);
  const domain: [number, number] = [0, maxValue * DESKTOP_DOMAIN_RATIO];

  if (!fallbackKey) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Chart = area ? AreaChart : LineChart;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Data = area ? Area : Line;

  return (
    <div css={styles.container}>
      <ResponsiveContainer width="100%" height="100%">
        {/* @ts-ignore - they don't like the overflow prop even though it's cool */}
        <Chart overflow="visible" data={data} margin={CHART_MARGIN}>
          <YAxis
            domain={domain}
            stroke={colors.palette.GREY_900}
            strokeWidth={3}
            dataKey={yAxisKey || fallbackKey}
            ticks={!!yAxisKey && startValue && endValue ? [startValue, endValue] : undefined}
            // @ts-ignore - bad library types
            tickFormatter={yAxisKey ? formatters?.[yAxisKey] : undefined}
            tick={yTicks && !!yAxisKey && <CustomTick />}
            tickLine={false}
          />
          <XAxis
            interval={0}
            stroke={colors.palette.GREY_900}
            strokeWidth={3}
            tickLine={false}
            dataKey="date"
            tickFormatter={(unixTime) =>
              unixTime === endDate && differenceInDays(new Date(), new Date(endDate)) <= 90
                ? "Today"
                : new Date(unixTime).getFullYear().toString()
            }
            type="number"
            domain={["auto", "auto"]}
            scale="time"
            ticks={xAxisTicks}
            tick={<CustomTick changeAnchor />}
          />
          {!loading &&
            Object.entries(chartColors).map(
              ([dataKey, color]) =>
                data[0]?.[dataKey] && (
                  // @ts-ignore - bad library types
                  <Data
                    fill={area ? color : undefined}
                    fillOpacity={0.25}
                    key={dataKey}
                    isAnimationActive
                    animationDuration={300}
                    strokeWidth="5"
                    stroke={color}
                    dot={false}
                    scale="time"
                    type="monotone"
                    dataKey={dataKey}
                    activeDot={false}
                  />
                )
            )}
          {!!valueKey && transformers && (
            <Tooltip
              active
              isAnimationActive={false}
              cursor={
                // @ts-ignore: Some props are injected by recharts
                <CustomCursor
                  formatters={formatters}
                  valueKey={valueKey}
                  domain={domain}
                  transformers={transformers}
                  animatedX={animatedX}
                  topPadding={topPadding + TOP_PADDING_OFFSET}
                />
              }
              content={<div />}
              allowEscapeViewBox={{ x: true }}
              offset={0}
            />
          )}
        </Chart>
      </ResponsiveContainer>
      {loading && (
        <Skeleton style={styles.loader} height={231} width="100%">
          <path
            preserveAspectRatio="none"
            css={styles.loadingPath}
            d="M0 230.5V191L275 47.5L455 125.5L598 87.5L677 47.5L752 87.5L917.5 47.5H953L1063 0H1102.5V230.5H0Z"
          />
        </Skeleton>
      )}
    </div>
  );
};

export default React.memo(DatedChart);
