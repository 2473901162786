import { formatters } from "@fraction/shared";
import { parseISO } from "date-fns";
import _ from "lodash";
import { Loader } from "lucide-react";
import { FocusEvent, useCallback, useRef } from "react";
import Skeleton from "src/components/Skeleton";
import { Input, InputProps } from "src/components/TextInput/Input";
import { useApplication } from "src/hooks/useApplication";
import { Logger } from "src/log";

const logger = new Logger("ModifiableKeyValue");

export const ModifiableKeyValue = ({
  label,
  loading,
  appId,
  path,
  currency,
  percentage,
  integer,
  cents,
  type,
  formatValue,
  mutateValueBeforeUpdate,
}: {
  label: string;
  loading?: boolean;
  appId?: string;
  path: string;
  formatValue?: (val: any) => string;
  mutateValueBeforeUpdate?: (val: string) => any;
} & InputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const {
    updateApplication,
    updateApplicationProperty,
    data: application,
    isPending,
  } = useApplication({
    id: appId,
  });

  const value = _.get(application, path);

  const handleBlur = useCallback(
    _.debounce(
      (evt: FocusEvent<HTMLInputElement>) => {
        let newValue: string | number = evt.target.value;

        if (mutateValueBeforeUpdate) {
          newValue = mutateValueBeforeUpdate(newValue);
          if (ref.current) {
            ref.current.value = typeof newValue === "string" ? newValue : newValue.toString();
          }
        }

        if (type === "date" && typeof newValue === "string") {
          // we will receive a string in the local timezone, so we want to create a date object
          // with that string and then convert it to UTC
          newValue = parseISO(newValue).toISOString();
        }

        if (newValue === "") {
          if (path.startsWith("property.")) {
            logger.log(`Clearing property ${path}`);
            const withoutPropertyPrefix = path.replace("property.", "");
            updateApplicationProperty({ [withoutPropertyPrefix]: null });
            return;
          } else {
            logger.log(`Clearing application ${path}`);
            updateApplication({ [path]: null });
            return;
          }
        }

        if (currency || cents) {
          newValue = formatters.number.getCents(newValue);
        } else if (percentage) {
          newValue = formatters.number.getNumberFromString(newValue) / 100;
        } else if (integer) {
          newValue = Math.round(formatters.number.getNumberFromString(newValue));
        }

        if (newValue === value) {
          return;
        }

        if (path.startsWith("property.")) {
          logger.log(`Setting property ${path}: ${newValue}`);
          const withoutPropertyPrefix = path.replace("property.", "");
          updateApplicationProperty({ [withoutPropertyPrefix]: newValue });
          return;
        } else {
          logger.log(`Setting application ${path}: ${newValue}`);
          updateApplication({ [path]: newValue });
          return;
        }
        // dates are not debounced
      },
      type === "date" ? 0 : 1500
    ),
    [value, currency, cents, percentage, integer, path, mutateValueBeforeUpdate]
  );

  let fmtValue = value;
  if (currency || cents) {
    fmtValue = formatters.number.getCurrencyFromNumber(fmtValue);
  }
  if (formatValue && value) {
    fmtValue = formatValue(value);
  }

  return (
    <div className="flex flex-row flex-wrap gap-1 items-center justify-between p-3 rounded">
      <p className="text-sm font-bold mr-2 flex-1">{label}:</p>
      {loading ? (
        <Skeleton width={160} />
      ) : (
        <div className="flex flex-row max-w-[300px]">
          <div className="relative flex flex-row">
            <Input
              ref={ref}
              type={type}
              onChange={handleBlur}
              currency={currency}
              percentage={percentage}
              integer={integer}
              cents={cents}
              defaultValue={fmtValue}
              className="text-sm text-right text-ellipsis line-clamp-2 bg-gray-400 py-1 pr-2 pl-1 rounded-md w-[120px] min-w-auto"
            />
            {isPending ? (
              <div className="bg-gray-400 rounded absolute left-0.5 bottom-0 flex items-center justify-end h-full">
                <Loader height={18} className="text-gray-600 animate-spin" />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};
