import { enums } from "@fraction/shared";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { cn } from "src/utilities/shadcnUtils";

export function LoanStatusSelect({
  onValueChange,
  className,
  value,
}: {
  value?: enums.LoanStatus;
  onValueChange?: (value: enums.LoanStatus) => void;
  className?: string;
}) {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className={cn("col-span-2", className)}>
        <SelectValue placeholder="Select a loan status" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Product Type</SelectLabel>
          <SelectItem value={enums.LoanStatus.ACTIVE}>Active</SelectItem>
          <SelectItem value={enums.LoanStatus.PAID_OUT}>Paid out</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
