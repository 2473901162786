import * as React from "react";
import Sticky from "src/components/Sticky";

export interface StickyButtonContainerProps extends React.HTMLAttributes<HTMLElement> {
  boundaryElement: string;
  scrollElement?: string;
  isStickyDesktopFooter?: boolean;
}

const STICKY_STYLE = {
  width: "unset",
  backgroundColor: "white",
  zIndex: 1,
  boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.15)",
  left: 0,
  right: 0,
  paddingLeft: 32,
  paddingRight: 32,
  paddingBottom: 18,
  paddingTop: 18,
};

const DEFAULT_STYLE = {
  width: "100%",
  minWidth: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const WRAPPER_STYLE = { width: "100%" };

const StickyButtonContainer = ({
  children,
  style,
  boundaryElement,
  scrollElement,
  isStickyDesktopFooter,
}: StickyButtonContainerProps) => (
  <Sticky
    // @ts-ignore
    isIOSFixEnabled
    // @ts-ignore - added this functionality with a patch, so isn't in types
    wrapperStyle={WRAPPER_STYLE}
    // @ts-ignore - incorrect @types package
    dontUpdateHolderHeightWhenSticky
    boundaryElement={boundaryElement}
    scrollElement={scrollElement}
    // This offsets the paddingBottom so that it starts being sticky
    // & applies the padding all at the same time
    topOffset={-18}
    // The 80 here is approximately the height of the
    // button container. The reason for this is there
    // seems to be a visual glitch where it will show up at the top of the screen
    // for a second without this offset
    bottomOffset={-80}
    style={{ ...style, ...DEFAULT_STYLE }}
    stickyStyle={STICKY_STYLE}
    mode="bottom"
    isStickyDesktopFooter={isStickyDesktopFooter}
  >
    {children}
  </Sticky>
);

export default StickyButtonContainer;
