import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import { Skeleton } from "src/components";
import { LightText } from "src/components/v1";
import { createStyles } from "src/styles";

export interface LegendIconProps {
  label: string;
  color: string;
  style?: SerializedStyles;
  loading?: boolean;
}

export interface LegendProps {
  style?: SerializedStyles;
  values: LegendIconProps[];
  loading?: boolean;
}

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    maxWidth: 200,
    "&:not(:last-child)": {
      marginRight: 45,
      "@media(max-width: 600px)": {
        marginBottom: 15,
      },
    },
  },
  legendContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  icon: {
    height: 24,
    width: 24,
    borderRadius: "50%",
  },
  label: {
    marginLeft: 14,
    fontSize: 16,
  },
});

const LegendIcon = ({ label, color, style, loading }: LegendIconProps) =>
  loading ? (
    <div css={[styles.container, style]}>
      <Skeleton circle width="24px" height="24px" style={styles.icon} />
      <Skeleton width="100px" height="20px" style={styles.label} />
    </div>
  ) : (
    <div css={[styles.container, style]}>
      <div css={[styles.icon, { backgroundColor: color }]} />
      <LightText style={styles.label}>{label}</LightText>
    </div>
  );

const Legend = ({ style, values, loading }: LegendProps) => (
  <div css={[styles.legendContainer, style]}>
    {values.map((props) => (
      <LegendIcon key={props.label} loading={loading} {...props} />
    ))}
  </div>
);

export default memo(Legend);
