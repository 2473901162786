import { Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { AppFlowRoutes } from "src/apps/AppFlow/routes";
import { BrokerOrBorrowerRoutes } from "src/apps/BrokerOrBorrowerRoutes";
import { PortfolioDashboardRoutes } from "src/apps/PortfolioDashboard/routes";
import MaintenanceIfEnabled from "src/apps/shared/Maintenance";
import { SharedRoutes } from "src/apps/shared/routes";
import { Loading, RequireAuth } from "src/components";
import AppRoot from "src/components/root/AppRoot";
import MissingRouteRedirect from "src/components/root/routeHelpers/MissingRouteRedirect";
import { EmbedRoutes } from "src/embeds/routes";

const AppRouteLayout = () => {
  return (
    // @ts-ignore
    <AppRoot>
      <Outlet />
      <Tooltip id="tooltip" className="max-w-xl z-50" />
    </AppRoot>
  );
};

const AuthRouteLayout = () => <Outlet />;

const Router = () => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route element={<AppRouteLayout />}>
        {AppFlowRoutes()}
        <Route
          element={
            <MaintenanceIfEnabled flag="dashboard">
              <AuthRouteLayout />
            </MaintenanceIfEnabled>
          }
        >
          <Route path="/*" element={<RequireAuth />}>
            <Route path="*" element={<BrokerOrBorrowerRoutes />} />
          </Route>

          {SharedRoutes()}
          {PortfolioDashboardRoutes()}
        </Route>
        {EmbedRoutes()}
      </Route>
      <Route path="*" element={<MissingRouteRedirect />} />;
    </Routes>
  </Suspense>
);

export default Router;
