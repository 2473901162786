import { GanttChartSquare } from "lucide-react";

const PAGES = {
  overview: {
    heading: "Loan overview",
    subheading: "View your application details",
    to: "/",
  },
  incomeVerify: {
    heading: "Connect accounts",
    subheading: "Connect your bank account to verify your income",
    to: "/income-verify",
  },
};

const DASHBOARD_CONFIG = {
  sidebarLinks: [
    {
      icon: GanttChartSquare,
      text: PAGES.overview.heading,
      to: PAGES.overview.to,
    },
    // {
    //   icon: Landmark,
    //   text: PAGES.incomeVerify.heading,
    //   to: PAGES.incomeVerify.to,
    // },
    // {
    //   icon: FolderOpenDot,
    //   text: "Documents",
    //   to: "/documents",
    // },
  ],
  pages: PAGES,
};

export default DASHBOARD_CONFIG;
