import { useDroppable } from "@dnd-kit/core";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { enums, formatters, notUndefinedOrNull, selectors } from "@fraction/shared";
import _ from "lodash";
import { ChecklistApp } from "src/api/fraction";
import { PipelineDealCard } from "src/apps/LOS/components/PipelineDealCard";

export function SortableItem(props: {
  id: string;
  statusOverride?: string;
  setShowModal?: (opts: { id: string; status: enums.ApplicationStatus }) => void;
  showChecklist?: boolean;
}) {
  const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { showChecklist, ...rest } = props;

  return (
    <PipelineDealCard
      {...rest}
      statusOverride={
        props.statusOverride ||
        (props.id === active?.id ? active?.data?.current?.sortable?.containerId : undefined)
      }
      ref={setNodeRef}
      style={style}
      showStatus={!showChecklist}
      {...attributes}
      {...listeners}
    />
  );
}

export function PipelineStageContainer({
  id,
  items,
  setShowModal,
  showChecklist,
}: {
  id: string;
  items: ChecklistApp[];
  setShowModal?: (opts: { id: string; status: enums.ApplicationStatus }) => void;
  showChecklist?: boolean;
}) {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <div>
      <div className="px-4 mb-1 flex flex-row gap-x-2 justify-between items-center">
        <p title={_.startCase(id)} className="text-md font-bold text-ellipsis line-clamp-1">
          {_.startCase(id)}
        </p>
        <p className="bg-gray-400 text-black font-semibold rounded-full h-6 text-xs text-center w-8 flex items-center justify-center">
          {items.length}
        </p>
      </div>
      <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
        <div
          className="bg-gray-200 rounded-lg p-4 h-[60svh] w-80 gap-y-1 flex flex-col overflow-y-scroll"
          ref={setNodeRef}
        >
          {items.filter(notUndefinedOrNull).map((item) => (
            <SortableItem
              showChecklist={showChecklist}
              setShowModal={setShowModal}
              key={item?.id}
              id={item.id}
            />
          ))}
        </div>
      </SortableContext>
      <div className="px-4 mt-1 flex flex-col align-middle items-center">
        <p>
          <b>Total:</b>{" "}
          {formatters.number.getCurrencyFromNumber(
            _.sumBy(items, selectors.application.selectApplicationAmount),
            { digits: 0 }
          )}
        </p>
      </div>
    </div>
  );
}
