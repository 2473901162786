import { NavItems } from "src/apps/PreFundedDashboard/components/NavItems";
import { SideBar } from "src/components";

const PreFundedSidebar = () => {
  return (
    <SideBar divider>
      <NavItems />
    </SideBar>
  );
};

export default PreFundedSidebar;
