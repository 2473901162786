import { colors } from "@fraction/shared";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { AccountDropdown } from "src/components";
import { Breadcrumb, BreadcrumbList } from "src/components/ui/breadcrumb";
import { Logo } from "src/images";
import { createStyles } from "src/styles";
import { cn } from "src/utilities/shadcnUtils";

const styles = createStyles({
  logo: {
    width: 118,
    height: 20,
    "@media(max-width: 600px)": {
      width: 94.4,
      height: 16,
    },
  },
  container: {
    maxWidth: "100vw",
  },
});

const ConveyancerHeader = ({
  className,
  Breadcrumbs,
}: {
  Breadcrumbs?: ReactElement;
  className?: string;
}) => {
  return (
    <div
      css={styles.container}
      className={cn("flex items-center pl-6 pt-4 pb-4 pr-6 border-b-2 border-solid", className)}
    >
      <Link to="/">
        <Logo css={styles.logo} width={118} height={20} color={colors.palette.BLACK} />
      </Link>
      <div className="pt-1 ml-6 overflow-x-scroll">
        {Breadcrumbs ? (
          <Breadcrumb>
            <BreadcrumbList>{Breadcrumbs}</BreadcrumbList>
          </Breadcrumb>
        ) : null}
      </div>
      <div className="flex ml-auto">
        <AccountDropdown />
      </div>
    </div>
  );
};

export default ConveyancerHeader;
