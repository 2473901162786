import { SerializedStyles } from "@emotion/react";
import { ReactNode, memo } from "react";
import { Avatar, Touchable } from "src/components";
import { ChevronDown, ChevronUp } from "src/icons";
import { createStyles } from "src/styles";

import { DropdownTriggerProps } from "../Dropdown";

const styles = createStyles(({ spacing, colors, fontSize, theme }) => ({
  accountDropdownButton: {
    display: "flex",
    alignItems: "center",
  },
  chevron: {
    color: colors.grey500,
    marginLeft: spacing[0.5],
    minWidth: spacing[2],
    minHeight: spacing[2],
  },
}));

const AccountDropdownButton = ({
  onClick,
  open,
  initials,
  style,
}: DropdownTriggerProps & { initials: string | ReactNode; style?: SerializedStyles }) => {
  return (
    <Touchable css={[styles.accountDropdownButton, style]} onClick={onClick}>
      <Avatar active={open}>{initials}</Avatar>
      {open ? <ChevronUp css={styles.chevron} /> : <ChevronDown css={styles.chevron} />}
    </Touchable>
  );
};

export default memo(AccountDropdownButton);
