import { motion } from "framer-motion";
import { CSSProperties, Dispatch, memo, useCallback } from "react";
import { Skeleton, Touchable } from "src/components";
import { SLIDER_THUMB_SIZE } from "src/components/Slider";
import { TOUCHABLE_PADDING } from "src/components/Touchable";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface ToggleProps {
  value?: boolean;
  onChange?: Dispatch<boolean>;
  loading?: boolean;
}

const styles: Record<"background" | "thumb" | "thumbWrapper" | "container", CSSProperties> = {
  background: {
    marginTop: (SLIDER_THUMB_SIZE - 18) / 2,
    width: 42,
    height: 18,
    borderRadius: 28,
  },
  thumb: {
    backgroundColor: "white",
    height: SLIDER_THUMB_SIZE,
    width: SLIDER_THUMB_SIZE,
    borderRadius: "50%",
    borderWidth: 1,
    borderColor: colors.SLIDER_STANDARD,
    borderStyle: "solid",
    boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.15)",
  },
  thumbWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  container: {
    position: "relative",
  },
};

const CSS_STYLES = createStyles({
  ...styles,
  thumb: {
    height: SLIDER_THUMB_SIZE + 2,
    width: SLIDER_THUMB_SIZE + 2, // to make up for the lack of border
    borderRadius: "50%",
  },
  thumbWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

const TRANSITION = { duration: 0.3 };

const Div = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div {...props} />
);

const Toggle = ({ value, onChange, loading }: ToggleProps) => {
  const handlePress = useCallback(() => {
    onChange?.(!value);
  }, [onChange, value]);

  if (loading) {
    return (
      <div css={[CSS_STYLES.container, TOUCHABLE_PADDING]}>
        <Skeleton style={CSS_STYLES.background} height={18} width={42} />
        <div css={CSS_STYLES.thumbWrapper}>
          <Skeleton style={CSS_STYLES.thumb} />
        </div>
      </div>
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Container = onChange ? Touchable : Div;

  return (
    <Container style={styles.container} onClick={handlePress}>
      <motion.div
        transition={TRANSITION}
        animate={{ backgroundColor: value ? colors.palette.GREEN : colors.palette.GREY_200 }}
        style={styles.background}
      />
      <div style={styles.thumbWrapper}>
        <motion.div
          transition={TRANSITION}
          animate={{
            borderColor: value ? colors.palette.GREEN : colors.palette.GREY_200,
            x: value ? "100%" : "0%",
          }}
          style={styles.thumb}
        />
      </div>
    </Container>
  );
};

export default memo(Toggle);
