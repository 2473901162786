import retry from "@fullstax/p-retry";
import { lazy } from "react";
import { Route } from "react-router-dom";
import EnhanceRoute from "src/components/root/routeHelpers/EnhanceRoute";

const Login = lazy(() => retry(() => import("src/apps/shared/Login")));
const ForgotPassword = lazy(() => retry(() => import("src/apps/shared/ForgotPassword")));
const ResetPassword = lazy(() => retry(() => import("src/apps/shared/ResetPassword")));
const Register = lazy(() => retry(() => import("src/apps/shared/Register")));

export function SharedRoutes() {
  return (
    <>
      <Route
        path="/register"
        element={
          <EnhanceRoute title="Register your account | Fraction">
            <Register />
          </EnhanceRoute>
        }
      />
      <Route
        path="/login"
        element={
          <EnhanceRoute title="Login to your account | Fraction">
            <Login />
          </EnhanceRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <EnhanceRoute title="Forgot your password? | Fraction">
            <ForgotPassword />
          </EnhanceRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <EnhanceRoute title="Password reset | Fraction">
            <ResetPassword />
          </EnhanceRoute>
        }
      />
    </>
  );
}
