import {
  UI_APPLICATION_STATUS_ORDER,
  entities,
  formatters,
  getNextApplicationStatus,
} from "@fraction/shared";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Checklist } from "src/apps/LOS/components/Checklist";
import { useComms } from "src/apps/LOS/hooks/queries";
import { MailBlock } from "src/apps/LOS/pages/Mail";
import { ApplicationOverview } from "src/apps/PreFundedDashboard/components/ApplicationOverview";
import { useChecklist } from "src/apps/PreFundedDashboard/hooks/checklist";
import { Skeleton } from "src/components";
import { getCurrentStatusBlock } from "src/components/AppStatusBadge";
import { BackButton } from "src/components/BackButton";
import { ClassifierModal } from "src/components/ClassifierModal";
import { DocumentChecklist } from "src/components/DocumentChecklist";
import { FundingProgress } from "src/components/FundingProgress";
import { MailModal } from "src/components/MailModal";
import { TaskChecklist } from "src/components/TaskChecklist";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { useApplicationAuthed } from "src/hooks/useApplication";

export function AppDetails() {
  const [showComplexUpload, setShowComplexUpload] = useState(false);
  const [selectedComm, setSelectedComm] = useState<entities.Communication | undefined>(undefined);
  const { id } = useParams();
  const { data: app, isLoading, isFetching } = useApplicationAuthed({ id });
  const { data: comms, isLoading: commsLoading } = useComms({ appId: app?.id });

  const clearSelectedComm = useCallback(() => {
    setSelectedComm(undefined);
  }, []);

  const nextStatus = app?.status
    ? getNextApplicationStatus(app.status, UI_APPLICATION_STATUS_ORDER)
    : undefined;

  const { refetch: refetchChecklist } = useChecklist({
    id: app?.id,
    status: nextStatus || undefined,
    fetchEnabled: true,
  });

  const toggleComplexUpload = useCallback(() => {
    setShowComplexUpload((prev) => !prev);
  }, []);

  const closeComplexUpload = useCallback(() => {
    setShowComplexUpload(false);
  }, []);

  const title = app?.id
    ? `${formatters.application.applicantNames(app)} | ${
        app?.status ? getCurrentStatusBlock(app?.status)?.label : "Fraction"
      }`
    : "App details";

  useDocumentTitle(title);

  if (!isLoading && !app) {
    return <p>App not found</p>;
  }

  return (
    <div className="p-6">
      <BackButton className="mb-4" label={title} loading={isFetching} />
      <div className="lg:grid flex flex-row flex-wrap gap-2 lg:grid-cols-4 w-full">
        <ApplicationOverview
          className="lg:col-span-1 min-w-[200px] w-full max-w-full"
          loading={isLoading}
          app={app || undefined}
        />
        <DocumentChecklist
          className="lg:col-span-2 w-full"
          onClickComplexUpload={toggleComplexUpload}
          applicationId={app?.id}
        />
        <div className="gap-2 flex flex-col w-full">
          <FundingProgress
            rejectReason={app?.declineReason}
            loading={isLoading}
            status={app?.status}
            className="w-full max-w-full"
          />
          <TaskChecklist
            applicationId={app?.id}
            className="w-full max-w-full max-h-[517px] overflow-y-scroll"
          />
        </div>

        <div className="flex gap-2 flex-col col-span-1 w-full">
          {nextStatus && app ? (
            <Checklist
              className="border p-6 border-gray-400 rounded w-full max-h-[450px] overflow-y-scroll"
              app={app}
              status={nextStatus}
              header={<p className="text-2xl mb-1 font-bold">Checklist</p>}
            />
          ) : null}
        </div>
        <div className="flex gap-2 flex-col col-span-3 w-full">
          {commsLoading ? <Skeleton width="100%" height={400} /> : null}
          {comms?.length ? (
            <div className="border p-6 border-gray-400 rounded w-full max-h-[600px] overflow-y-scroll flex flex-col gap-4">
              <p className="text-2xl mb-1 font-bold">Comms</p>
              {comms?.map((mail) => (
                <MailBlock mail={mail} key={mail.id} />
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <ClassifierModal
        close={closeComplexUpload}
        open={showComplexUpload}
        onSuccessfulUpload={refetchChecklist}
        appId={app?.id}
      />
      <MailModal comm={selectedComm} close={clearSelectedComm} />
    </div>
  );
}
//
// const CommsOld = ({ commsLoading, comms, setSelectedComm }: any) => (
//   <div className="flex gap-2 flex-col col-span-3 w-full">
//     {commsLoading ? <Skeleton width="100%" height={400} /> : null}
//     {comms?.length ? (
//       <div className="border p-6 border-gray-400 rounded w-full max-h-[600px] overflow-y-scroll">
//         <p className="text-2xl mb-1 font-bold">Comms</p>
//         {comms?.map((mail) => (
//           <MailRow onClick={setSelectedComm} showRecipient mail={mail} key={mail.id} />
//         ))}
//       </div>
//     ) : null}
//   </div>
// );
