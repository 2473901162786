import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { UseQueryOptions, useMutation, useQuery } from "src/lib";

export function useFractionCache() {
  const queryClient = useQueryClient();
  const q = useQuery({
    queryKey: ["cache"],
    queryFn: async () => {
      if (!window.caches) {
        return null;
      }
      return window.caches.open("fraction-cache");
    },
  });

  const clear = useCallback(() => {
    if (!window.caches) {
      return null;
    }
    window.caches.delete("fraction-cache");
    queryClient.setQueryData(["cache"], null);
  }, []);

  return {
    ...q,
    clear,
  };
}

export interface PersistOptions<TData> {
  serialize?: (data: TData) => string;
  deserialize?: (data: any) => TData;
  initialRefetch?: boolean;
  placeholderData?: TData | (() => TData);
  behaviour?: "fetch-only" | "both";
  cacheKey?: readonly unknown[];
}

const serializeQueryKey = (queryKey: readonly unknown[]) => queryKey.join("_");

export function useCache<TData, TCachedData = TData>({
  cacheKey,
  queryKey = cacheKey,
  onSuccess,
  serialize = JSON.stringify,
  deserialize = (data) => data,
  initialRefetch = false,
  placeholderData,
  enabled,
  behaviour = "both",
}: {
  queryKey?: readonly unknown[];
  cacheKey: readonly unknown[];
  onSuccess?: (data: any, fromCache?: boolean) => void;
  enabled?: boolean;
} & PersistOptions<TCachedData>) {
  const queryClient = useQueryClient();
  const cacheQ = useFractionCache();
  const [initiatedRefetch, setInitiatedRefetch] = useState(initialRefetch);

  const mutate = useMutation({
    mutationFn: async (item: TData | ((data: TData) => TData)) => {
      if (!cacheQ?.data?.put) {
        return;
      }
      if (behaviour === "fetch-only") {
        return;
      }
      const prev = queryClient.getQueryData(["cacheItems", true, ...cacheKey]);
      // @ts-ignore
      const updateItem = (typeof item === "function" ? item(prev?.data) : item) as TCachedData;

      queryClient.setQueryData(["cacheItems", true, ...cacheKey], {
        data: updateItem,
        dataUpdatedAt: Date.now(),
      });
      await Promise.all([
        cacheQ.data.put(serializeQueryKey(cacheKey), new Response(serialize(updateItem))),
        await cacheQ.data.put(`_time_${serializeQueryKey(cacheKey)}`, new Response(Date.now().toString())),
      ]);
    },
  });

  const itemsQ = useQuery({
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    placeholderData: { data: placeholderData, dataUpdatedAt: undefined },
    enabled: !cacheQ?.isInitialLoading && enabled !== false,
    queryKey: ["cacheItems", !cacheQ.isInitialLoading, ...cacheKey],
    queryFn: async () => {
      if (cacheQ?.data === undefined) {
        return null;
      }
      const found = await cacheQ?.data?.match(serializeQueryKey(cacheKey));

      if (found !== undefined) {
        const foundTimeR = await cacheQ?.data?.match(`time-${serializeQueryKey(cacheKey)}`);
        const foundTime = await foundTimeR?.text();
        const json = deserialize(await found.json());
        if (json !== undefined) {
          const result = await queryClient.setQueryData(queryKey, (prev: any) => {
            return prev ? prev : json;
          });
          return { data: result, dataUpdatedAt: foundTime ? Number(foundTime) : undefined };
        }
      }
      return null;
    },
    onSuccess: async () => {
      if (initiatedRefetch && enabled !== false) {
        setInitiatedRefetch(false);
        await queryClient.refetchQueries({ queryKey: queryKey });
      }
    },
  });

  useQuery({
    enabled: !!itemsQ?.data?.data,
    queryKey: ["cacheOnSuccess", ...cacheKey],
    queryFn: () => itemsQ?.data?.data || null,
    onSuccess: (data) => {
      onSuccess?.(data, true);
    },
  });

  return {
    ...itemsQ,
    data: itemsQ?.data?.data as TData,
    dataUpdatedAt: itemsQ?.data?.dataUpdatedAt,
    isInitialLoading: itemsQ.isInitialLoading || cacheQ.isInitialLoading,
    isLoading: itemsQ.isLoading || cacheQ.isLoading,
    isFetching: itemsQ.isFetching || cacheQ.isFetching,
    addItem: mutate.mutateAsync,
    clearCache: cacheQ.clear,
    isPending: mutate.isPending,
  };
}

export function useCachedState<T>(
  defaultValue: T,
  key: string
): [T, Dispatch<SetStateAction<T>>, boolean, boolean] {
  const cache = useCache<T>({
    cacheKey: [key],
    serialize: JSON.stringify,
    deserialize: JSON.parse,
    placeholderData: defaultValue,
  });

  return [cache?.data, cache.addItem, cache.isLoading, cache.isFetching];
}

export function useCachedQuery<TQueryFnData = unknown, TCachedData = TQueryFnData, TData = TQueryFnData>(
  options: UseQueryOptions<TQueryFnData, any, TData> & PersistOptions<TCachedData>
) {
  const cache = useCache<TData, TCachedData>({
    cacheKey: options.cacheKey?.length ? options.cacheKey : options.queryKey,
    queryKey: options.queryKey,
    onSuccess: options.onSuccess,
    serialize: options.serialize,
    deserialize: options.deserialize,
    initialRefetch: options.initialRefetch,
    enabled: options.enabled,
    behaviour: options.behaviour,
  });

  const q = useQuery<TQueryFnData, any, TData>({
    ...options,
    enabled: options.enabled === false ? false : !cache.isInitialLoading,
    onSuccess: async (result) => {
      await options.onSuccess?.(result, true);
      await cache.addItem(result);
    },
  });

  const queryClient = useQueryClient();
  const localQ = useQuery<TQueryFnData, any, TData>({
    enabled: options.enabled === false ? false : !!options.cacheKey?.length,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: options.cacheKey || [],
    select: options.select,
    // @ts-ignore
    queryFn: async () => {
      return (await queryClient.getQueryData(options.cacheKey || [])) || null;
    },
  });

  let isLoading = cache.isFetching;
  if (!isLoading && !cache.isInitialLoading && !cache?.data) {
    isLoading = q.isLoading || localQ.isLoading;
  }

  return {
    ...q,
    data: (q.data as TData) || localQ.data,
    isCacheLoading: cache.isLoading,
    dataUpdatedAt: q.dataUpdatedAt || cache.dataUpdatedAt,
    isLoading,
    isFetching: q.isFetching || cache.isFetching,
  };
}

export function useCachedQueryClient<T>() {
  const queryClient = useQueryClient();
  const cache = useFractionCache();

  const oldSetQueryData = useRef(queryClient.setQueryData.bind(queryClient));
  // @ts-ignore
  queryClient.setQueryData = useCallback(
    (...args: Parameters<typeof queryClient.setQueryData>) => {
      return (async () => {
        const [queryKey, updater] = args;
        const result = await oldSetQueryData.current(queryKey, updater);
        await Promise.all([
          cache.data?.put(`_time_${serializeQueryKey(queryKey)}`, new Response(Date.now().toString())),
          cache.data?.put(serializeQueryKey(queryKey), new Response(JSON.stringify(result))),
        ]);
        return result;
      })();
    },
    [cache.data]
  );

  const oldClear = useRef(queryClient.clear.bind(queryClient));
  queryClient.clear = useCallback(() => {
    cache.clear();
    return oldClear.current();
  }, [cache.clear]);

  const oldResetQueries = useRef(queryClient.resetQueries.bind(queryClient));
  queryClient.resetQueries = useCallback(
    (...args: Parameters<typeof queryClient.resetQueries>) => {
      cache.clear();
      return oldResetQueries.current(...args);
    },
    [cache.clear]
  );

  const oldRemoveQueries = useRef(queryClient.removeQueries.bind(queryClient));
  queryClient.removeQueries = useCallback(
    (...args: Parameters<typeof queryClient.removeQueries>) => {
      cache.clear();
      return oldRemoveQueries.current(...args);
    },
    [cache.clear]
  );

  return queryClient;
}
