import retry from "@fullstax/p-retry";
import { lazy } from "react";
import { Route } from "react-router-dom";
import MaintenanceIfEnabled from "src/apps/shared/Maintenance";
import TrackRoute from "src/components/root/routeHelpers/TrackRoute";

const ApplicationFlow = lazy(() => retry(() => import("src/apps/AppFlow/screens/root")));

export function AppFlowRoutes() {
  return (
    <Route
      path="/application"
      element={
        <MaintenanceIfEnabled flag="applicationFlow">
          <TrackRoute title="Start your application | Fraction">
            <ApplicationFlow />
          </TrackRoute>
        </MaintenanceIfEnabled>
      }
    />
  );
}
