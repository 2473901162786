import { memo } from "react";
import NoDataScreen from "src/apps/PostFundedDashboard/components/NoDataScreen";
import { DashboardPage } from "src/components";
import FileTable from "src/components/FileTable";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { Document } from "src/images";
import { createStyles } from "src/styles";

import { DocumentRow } from "src/components/DocumentRow";
import { useDocumentsQuery, useLoanQuery } from "../../queries";

const styles = createStyles(() => ({
  noDataIllustration: {
    height: 100,
    width: 100,
  },
}));

const Documents = () => {
  useDocumentTitle("Documents | Fraction");

  const { data: loan } = useLoanQuery({
    refetchOnMount: false, // we only need the application id, so no need to refetch
  });
  const { data, isLoading } = useDocumentsQuery(loan?.application?.id || "");
  return (
    <DashboardPage heading="Documents" subHeading="View and download your loan documents">
      {!data?.length && !isLoading ? (
        <NoDataScreen text="It doesn't look like you have any documents yet.">
          <Document css={styles.noDataIllustration} />
        </NoDataScreen>
      ) : (
        <FileTable className="mt-6" isLoading={isLoading} tableHeadText="Document name">
          {data?.map((document) => (
            <DocumentRow key={document.id} document={document} />
          ))}
        </FileTable>
      )}
    </DashboardPage>
  );
};

export default memo(Documents);
