import { formatters } from "@fraction/shared";
import { ColumnDef } from "@tanstack/react-table";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import fraction from "src/api/fraction";
import usePortfolioDashStore from "src/apps/PortfolioDashboard/store";
import { ClipboardBadge } from "src/components/ClipboardBadge";
import VirtualTable from "src/components/Table/VirtualTable";
import { Badge } from "src/components/ui/badge";
import { Input } from "src/components/ui/input";
import { useQuery } from "src/lib";

const COLUMNS: ColumnDef<any>[] = [
  {
    header: "Address",
    accessorKey: "application.property",
    cell: (info: any) =>
      formatters.property.formattedAddress(info.getValue(), { shortAdmininistrativeArea: true }).slice(0, 60),
    size: 400,
  },
  {
    header: "Funded date",
    accessorKey: "startDate",
    cell: (info) => formatters.date.getFormattedDate(info.getValue() as string),
  },
  {
    header: "Balance",
    accessorKey: "postedBalance",
    cell: (info) => formatters.number.getCurrencyFromNumber(info.getValue() as string),
  },
  {
    header: "Draw #",
    accessorKey: "application.capitalCallPool",
    cell: (info) => {
      const value = info.getValue() as string;
      if (!value) {
        return null;
      }
      return <Badge className="rounded-full">{value?.toString()}</Badge>;
    },
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: (info) => <Badge className="rounded-full">{_.startCase(info.getValue() as string)}</Badge>,
  },
  {
    header: "Portfolio",
    accessorKey: "portfolio",
    cell: (info) => <Badge className="rounded-full">{_.startCase(info.getValue() as string)}</Badge>,
    size: 200,
  },
  {
    header: "Finance ID",
    accessorKey: "application.financeId",
    cell: (info) => (
      <ClipboardBadge variant="gray" className="rounded-full">
        {info.getValue() as string}
      </ClipboardBadge>
    ),
  },
  {
    header: "Simple ID",
    accessorKey: "application.eid",
    cell: (info) => (
      <ClipboardBadge variant="gray" className="rounded-full">
        {info.getValue() as string}
      </ClipboardBadge>
    ),
  },
  {
    header: "File ID",
    accessorKey: "application.crmId",
    cell: (info) => (
      <ClipboardBadge variant="gray" className="rounded-full">
        {info.getValue() as string}
      </ClipboardBadge>
    ),
  },
  {
    header: "App ID",
    accessorKey: "application.id",
    cell: (info) => (
      <ClipboardBadge variant="gray" className="rounded-full">
        {info.getValue() as string}
      </ClipboardBadge>
    ),
    size: 300,
  },
  {
    header: "Loan ID",
    accessorKey: "id",
    cell: (info) => (
      <ClipboardBadge variant="gray" className="rounded-full">
        {info.getValue() as string}
      </ClipboardBadge>
    ),
    size: 300,
  },
];

// @ts-ignore
const IdAccessors = COLUMNS.filter((x) => (x.header as string)?.slice(-2) === "ID").map((x) => x.accessorKey);

export const PortfolioDataScreen = () => {
  const [search, setSearch] = useState("");

  const openNavDrawer = usePortfolioDashStore((state) => state.openNavDrawer);
  const setSelectedApp = usePortfolioDashStore((state) => state.setSelectedApp);

  const handleClickRow = useCallback(
    (row: any) => {
      if (row?.original?.applicationId) {
        openNavDrawer();
        setSelectedApp(row?.original?.applicationId);
      }
    },
    [openNavDrawer, setSelectedApp]
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const {
    productTypeFilter,
    termLengthFilter,
    portfolioFilters,
    startDateFilter,
    endDateFilter,
    countryFilter,
    loanStatusFilter,
    endLoanActivityDateFilter,
    startLoanActivityDateFilter,
  } = usePortfolioDashStore();

  const portfolio = useQuery({
    queryKey: [
      "portfolioData",
      portfolioFilters,
      ...(startDateFilter && endDateFilter ? [startDateFilter, endDateFilter] : []),
      countryFilter,
      productTypeFilter,
      termLengthFilter,
      loanStatusFilter,
      endLoanActivityDateFilter,
      startLoanActivityDateFilter,
    ],
    queryFn: () => {
      return fraction.getPortfolioData({
        status: loanStatusFilter,
        country: countryFilter,
        portfolios: portfolioFilters?.length === 0 ? undefined : portfolioFilters,
        productType: productTypeFilter,
        termLength: termLengthFilter,
        startDate: startDateFilter,
        endDate: endDateFilter,
        endLoanActivityDate: endLoanActivityDateFilter,
        startLoanActivityDate: startLoanActivityDateFilter,
      });
    },
  });

  const filtered = useMemo(() => {
    if (!search) {
      return portfolio?.data;
    }

    return portfolio?.data?.filter((item) => {
      return IdAccessors?.some((accessor) => _.get(item, accessor) === search.trim());
    });
  }, [search, portfolio?.data?.length]);

  return (
    <div className="pb-4">
      <div className="pt-4 px-6 pb-2">
        <Input
          onChange={handleChange}
          value={search}
          placeholder="Search for a loan by an ID..."
          className="w-64"
        />
      </div>
      <VirtualTable
        onClickRow={handleClickRow}
        estimateRowHeight={54.5}
        rowClassName="text-sm h-10"
        tableContainerClassName="min-h-[90vh] items-start"
        columns={COLUMNS}
        data={filtered || []}
        loading={portfolio?.isLoading ? 20 : false}
      />
    </div>
  );
};
