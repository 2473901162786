import { useEffect, useState } from "react";

export default function useVisualViewport() {
  const [visualViewportSize, setVisualViewportSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!window.visualViewport) {
      return;
    }

    function handleResize() {
      if (!window.visualViewport) {
        return;
      }
      setVisualViewportSize({
        width: window.visualViewport.width,
        height: window.visualViewport.height,
      });
    }

    window.visualViewport.addEventListener("resize", handleResize);
    handleResize();

    return () => window.visualViewport?.removeEventListener("resize", handleResize);
  }, []);

  return visualViewportSize;
}
