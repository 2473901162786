import { ArrowLeft, Loader } from "lucide-react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { cn } from "src/utilities/shadcnUtils";

export function BackButton({
  label,
  className,
  loading,
  to = -1,
}: { label?: ReactNode; className?: string; loading?: boolean; to?: number | string }) {
  return (
    // @ts-ignore
    <Link className={cn("flex flex-row items-center gap-x-2", className)} to={to}>
      <ArrowLeft className="text-gray-900" />
      {typeof label === "string" ? <p className="text-md font-medium">{label}</p> : label}
      {loading ? <Loader height={19} className="text-gray-600 animate-spin mr-1" /> : null}
    </Link>
  );
}
